import { PropsWithChildren, useCallback } from 'react'
import {
  AppState,
  Auth0Context,
  Auth0Provider as Auth0ProviderBase,
  Auth0ProviderOptions,
} from '@auth0/auth0-react'
import { store } from '../../modules/store'
import { push } from 'connected-react-router'
import { Auth0ContextInterface } from '@auth0/auth0-react/src/auth0-context'
import { deferred } from './utils'

const Auth0Provider = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const handleRedirect = useCallback((appState?: AppState) => {
    const navigateTo =
      (appState && appState.returnTo) || window.location.pathname
    // navigate back to app
    store.dispatch(push(navigateTo))
  }, [])

  return (
    <Auth0ProviderBase onRedirectCallback={handleRedirect} {...props}>
      <Auth0Context.Consumer>
        {({ getAccessTokenSilently }: Auth0ContextInterface) => {
          // expose getAccessTokenSilently method reference, for usage in redux sagas
          deferred.resolve?.(getAccessTokenSilently)
          return children
        }}
      </Auth0Context.Consumer>
    </Auth0ProviderBase>
  )
}

export default Auth0Provider
