import { Item, Parameters } from 'pluggy-sdk'

import Notifications from 'react-notification-system-redux'
import { action } from 'typesafe-actions'

// Fetch Items
export const FETCH_ITEMS_REQUEST = '[Request] Fetch items'
export const FETCH_ITEMS_SUCCESS = '[Success] Fetch items'
export const FETCH_ITEMS_FAILURE = '[Failure] Fetch items'

export const fetchItemsRequest = () => action(FETCH_ITEMS_REQUEST, {})

export const fetchItemsSuccess = (
  items: Item[],
  total: number,
  totalExceptSandbox: number
) => action(FETCH_ITEMS_SUCCESS, { items, total, totalExceptSandbox })

export const fetchItemsFailure = (error: string) =>
  action(FETCH_ITEMS_FAILURE, { error })

export type FetchItemsRequestAction = ReturnType<typeof fetchItemsRequest>
export type FetchItemsSuccessAction = ReturnType<typeof fetchItemsSuccess>
export type FetchItemsFailureAction = ReturnType<typeof fetchItemsFailure>

// Fetch Item by id

export const FETCH_ITEM_REQUEST = '[Request] Fetch item'
export const FETCH_ITEM_SUCCESS = '[Success] Fetch item'
export const FETCH_ITEM_FAILURE = '[Failure] Fetch item'

export const fetchItemRequest = (id: string) =>
  action(FETCH_ITEM_REQUEST, { id })

export const fetchItemSuccess = (item: Item) =>
  action(FETCH_ITEM_SUCCESS, { item })

export const fetchItemFailure = (id: string, error: string) =>
  action(FETCH_ITEM_FAILURE, { id, error })

export type FetchItemRequestAction = ReturnType<typeof fetchItemRequest>
export type FetchItemSuccessAction = ReturnType<typeof fetchItemSuccess>
export type FetchItemFailureAction = ReturnType<typeof fetchItemFailure>

// Delete Item
export const DELETE_ITEM_REQUEST = '[Request] Delete item'
export const DELETE_ITEM_SUCCESS = '[Success] Delete item'
export const DELETE_ITEM_FAILURE = '[Failure] Delete item'

export const deleteItemRequest = (item: Item) =>
  action(DELETE_ITEM_REQUEST, { item })

export const deleteItemSuccess = (item: Item) =>
  action(DELETE_ITEM_SUCCESS, { item })

export const deleteItemFailure = (item: Item, error: string) =>
  action(DELETE_ITEM_FAILURE, { item, error })

export type DeleteItemRequestAction = ReturnType<typeof deleteItemRequest>
export type DeleteItemSuccessAction = ReturnType<typeof deleteItemSuccess>
export type DeleteItemFailureAction = ReturnType<typeof deleteItemFailure>

// Delete Items
export const DELETE_ITEMS_REQUEST = '[Request] Delete items'
export const DELETE_ITEMS_SUCCESS = '[Success] Delete items'
export const DELETE_ITEMS_FAILURE = '[Failure] Delete items'

export const deleteItemsRequest = (items: Item[]) =>
  action(DELETE_ITEMS_REQUEST, { items })

export const deleteItemsSuccess = (items: Item[]) =>
  action(DELETE_ITEMS_SUCCESS, { items })

export const deleteItemsFailure = (items: Item[], error: string) =>
  action(DELETE_ITEMS_FAILURE, { items, error })

export type DeleteItemsRequestAction = ReturnType<typeof deleteItemsRequest>
export type DeleteItemsSuccessAction = ReturnType<typeof deleteItemsSuccess>
export type DeleteItemsFailureAction = ReturnType<typeof deleteItemsFailure>

// Update Item
export const UPDATE_ITEM_REQUEST = '[Request] Update item'
export const UPDATE_ITEM_SUCCESS = '[Success] Update item'
export const UPDATE_ITEM_FAILURE = '[Failure] Update item'

export const updateItemRequest = (
  itemId: string,
  parameters?: Parameters,
  webhookUrl?: string
) => action(UPDATE_ITEM_REQUEST, { itemId, webhookUrl, parameters })

export const updateItemSuccess = (item: Item) =>
  action(UPDATE_ITEM_SUCCESS, { item })

export const updateItemFailure = (error: string) =>
  action(UPDATE_ITEM_FAILURE, { error })

export type UpdateItemRequestAction = ReturnType<typeof updateItemRequest>
export type UpdateItemSuccessAction = ReturnType<typeof updateItemSuccess>
export type UpdateItemFailureAction = ReturnType<typeof updateItemFailure>

// Poll Item
export const POLL_ITEM_START = '[Start] Polling item'
export const POLL_ITEM_STOP = '[Stop] Polling item'

export const startPollingItem = (itemId: string) =>
  action(POLL_ITEM_START, { itemId })

export const stopPollingItem = (itemId: string) =>
  action(POLL_ITEM_STOP, { itemId })

export type StartPollingItemAction = ReturnType<typeof startPollingItem>
export type StopPollingItemAction = ReturnType<typeof stopPollingItem>

export const sendNotificationAction = (notification: any) =>
  Notifications.show(notification)
