import { AnyAction } from 'redux'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { ItemState } from './reducer'
import { Item } from 'pluggy-sdk'

export const getState = (state: RootState): ItemState => state.item
export const getData = (state: RootState): ItemState['data'] =>
  getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState): string | null =>
  getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type
) => state.some((action: AnyAction) => action.type === type)

export const getItemsById = (
  state: RootState
): Record<Item['id'], Item | undefined> => getData(state).itemsById

export const getItemsTotal = (state: RootState): number | null =>
  getData(state).total

export const getItemsTotalExceptSandbox = (state: RootState): number | null => {
  const { totalExceptSandbox } = getData(state)
  if (totalExceptSandbox === undefined) {
    return null
  }
  return totalExceptSandbox
}
