import React from 'react'

import Icon from '../Icon'

import './DeleteIcon.css'

type Props = {
  onClick?: () => void
  className?: string
  disabled?: boolean
}

const DeleteIcon = ({ onClick, className, disabled }: Props) => (
  <Icon
    className={`DeleteIcon ${className || ''}`}
    onClick={onClick}
    disabled={disabled}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      id="Vector"
      d="M4.75 5.1V4.35C4.33579 4.35 4 4.68579 4 5.1H4.75ZM4.75 17H4C4 17.4142 4.33579 17.75 4.75 17.75V17ZM15.25 17V17.75C15.6642 17.75 16 17.4142 16 17H15.25ZM15.25 5.1H16C16 4.68579 15.6642 4.35 15.25 4.35V5.1ZM9.35 8.6C9.35 8.18579 9.01421 7.85 8.6 7.85C8.18579 7.85 7.85 8.18579 7.85 8.6H9.35ZM7.85 13.15C7.85 13.5642 8.18579 13.9 8.6 13.9C9.01421 13.9 9.35 13.5642 9.35 13.15H7.85ZM12.15 8.6C12.15 8.18579 11.8142 7.85 11.4 7.85C10.9858 7.85 10.65 8.18579 10.65 8.6H12.15ZM10.65 13.15C10.65 13.5642 10.9858 13.9 11.4 13.9C11.8142 13.9 12.15 13.5642 12.15 13.15H10.65ZM3 4.35C2.58579 4.35 2.25 4.68579 2.25 5.1C2.25 5.51421 2.58579 5.85 3 5.85V4.35ZM17 5.85C17.4142 5.85 17.75 5.51421 17.75 5.1C17.75 4.68579 17.4142 4.35 17 4.35V5.85ZM7.2 5.1L6.54233 4.73949C6.41496 4.97185 6.41965 5.25418 6.55468 5.48217C6.6897 5.71017 6.93502 5.85 7.2 5.85V5.1ZM8.35115 3V2.25C8.07725 2.25 7.82514 2.39931 7.69348 2.63949L8.35115 3ZM11.672 3L12.3327 2.64509C12.202 2.40179 11.9481 2.25 11.672 2.25V3ZM12.8 5.1V5.85C13.0638 5.85 13.3083 5.71136 13.4437 5.48491C13.5791 5.25847 13.5856 4.97752 13.4607 4.74509L12.8 5.1ZM4 5.1V17H5.5V5.1H4ZM4.75 17.75H15.25V16.25H4.75V17.75ZM16 17V5.1H14.5V17H16ZM15.25 4.35H4.75V5.85H15.25V4.35ZM7.85 8.6V13.15H9.35V8.6H7.85ZM10.65 8.6V13.15H12.15V8.6H10.65ZM3 5.85H17V4.35H3V5.85ZM7.85767 5.46051L9.00882 3.36051L7.69348 2.63949L6.54233 4.73949L7.85767 5.46051ZM8.35115 3.75H11.672V2.25H8.35115V3.75ZM11.0112 3.35491L12.1393 5.45491L13.4607 4.74509L12.3327 2.64509L11.0112 3.35491ZM12.8 4.35H7.2V5.85H12.8V4.35Z"
      fill="#FFFFFF"
    />
  </Icon>
)

export default React.memo(DeleteIcon)
