import type { AcquirerAnticipation, Item } from 'pluggy-sdk'

import { loadingReducer, LoadingState } from '../loading/reducer'
import { FETCH_ACQUIRER_ANTICIPATIONS_FAILURE, FETCH_ACQUIRER_ANTICIPATIONS_REQUEST, FETCH_ACQUIRER_ANTICIPATIONS_SUCCESS, FetchAcquirerAnticipationsFailureAction, FetchAcquirerAnticipationsRequestAction, FetchLAcquirerAnticipationsSuccessAction } from './actions'

export type AcquirerAnticipationState = {
  data: Record<Item['id'], AcquirerAnticipation[] | undefined>
  loading: Record<Item['id'], LoadingState | undefined>
  error: Record<Item['id'], string | null | undefined>
}

const INITIAL_STATE: AcquirerAnticipationState = {
  data: {},
  loading: {},
  error: {},
}

type AcquirerAnticipationReducer =
  | FetchAcquirerAnticipationsRequestAction
  | FetchAcquirerAnticipationsFailureAction
  | FetchLAcquirerAnticipationsSuccessAction

export function acquirerAnticipationReducer(state = INITIAL_STATE, action: AcquirerAnticipationReducer): AcquirerAnticipationState {
  switch (action.type) {
    case FETCH_ACQUIRER_ANTICIPATIONS_REQUEST: {
      const {
        payload: { itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
      }
    }
    case FETCH_ACQUIRER_ANTICIPATIONS_SUCCESS: {
      const {
        payload: { acquirerAnticipations, itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: null,
        },
        data: {
          ...state.data,
          [itemId]: acquirerAnticipations,
        },
      }
    }
    case FETCH_ACQUIRER_ANTICIPATIONS_FAILURE: {
      const {
        payload: { error, itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: error,
        },
      }
    }
    default:
      return state
  }
}
