import type { Benefit, Item } from 'pluggy-sdk'

import { BaseAPI, PageResponse } from './BaseAPI'

export class BenefitAPI extends BaseAPI {
  async fetchBenefits(itemId: Item['id']): Promise<Benefit[]> {
    const { results } = await this.request<PageResponse<Benefit>>(
      'get',
      `/benefits?itemId=${itemId}`
    )
    return results
  }
}
