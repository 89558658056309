import type { Item, Opportunity } from 'pluggy-sdk'

import {
  FETCH_OPPORTUNITIES_FAILURE,
  FETCH_OPPORTUNITIES_REQUEST,
  FETCH_OPPORTUNITIES_SUCCESS,
  FetchOpportunitiesFailureAction,
  FetchOpportunitiesRequestAction,
  FetchOpportunitiesSuccessAction,
} from './actions'
import { loadingReducer, LoadingState } from '../loading/reducer'

export type OpportunityState = {
  data: Record<Item['id'], Record<Opportunity['id'], Opportunity | undefined>>
  loading: Record<Item['id'], LoadingState | undefined>
  error: Record<Item['id'], string | null | undefined>
}

const INITIAL_STATE: OpportunityState = {
  data: {},
  loading: {},
  error: {},
}

type OpportunityReducer =
  | FetchOpportunitiesRequestAction
  | FetchOpportunitiesFailureAction
  | FetchOpportunitiesSuccessAction

export function opportunityReducer(
  state = INITIAL_STATE,
  action: OpportunityReducer
) {
  switch (action.type) {
    case FETCH_OPPORTUNITIES_REQUEST: {
      const {
        payload: { itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
      }
    }
    case FETCH_OPPORTUNITIES_SUCCESS: {
      const {
        payload: { opportunities, itemId },
      } = action

      // group opportunities by id
      const opportunitiesById: Record<string, Opportunity> = {}

      for (const opportunity of opportunities) {
        opportunitiesById[opportunity.id] = opportunity
      }

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: null,
        },
        data: {
          ...state.data,
          [itemId]: opportunitiesById,
        },
      }
    }
    case FETCH_OPPORTUNITIES_FAILURE: {
      const {
        payload: { error, itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: error,
        },
      }
    }
    default:
      return state
  }
}
