import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { DemoAPI } from '../../lib/api/demo'

import { getPluggyApiKey } from '../login/selectors'
import {
  GET_METABASE_IFRAME_INSIGHTS_REQUEST,
  getMetabaseIframeInsightsFailure,
  getMetabaseIframeInsightsSuccess,
} from './actions'
import { getAuth0AccessToken } from '../../components/Auth0Provider'

function* handleGetMetabaseIframeInsightsRequest() {
  let auth0AccessToken: string | undefined

  try {
    auth0AccessToken = yield call(getAuth0AccessToken)
  } catch (error) {
    error.message = `Could not retrieve Metabase Iframe Insights due to Auth0 error: ${error.message}`
    console.error(error)
    yield put(getMetabaseIframeInsightsFailure(error.message))
    return
  }

  if (!auth0AccessToken) {
    yield put(getMetabaseIframeInsightsFailure('Missing Auth0 access token'))
    return
  }

  const apiKey: string = yield select(getPluggyApiKey)
  if (!apiKey) {
    yield put(getMetabaseIframeInsightsFailure('Missing pluggy api-key'))
    return
  }

  try {
    const demoAPI = new DemoAPI(auth0AccessToken)
    const metabaseUrl: string = yield call(() =>
      demoAPI.fetchMetabaseUrl(apiKey)
    )
    console.log('fetched', metabaseUrl)
    yield put(getMetabaseIframeInsightsSuccess(metabaseUrl))
  } catch (error) {
    if (error instanceof Error) {
      yield put(getMetabaseIframeInsightsFailure(error.message))
    }
  }
}

export function* insightsSaga() {
  yield all([
    takeEvery(
      GET_METABASE_IFRAME_INSIGHTS_REQUEST,
      handleGetMetabaseIframeInsightsRequest
    ),
  ])
}
