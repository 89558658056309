import { takeEvery, put, call, select } from 'redux-saga/effects'

import type { Opportunity } from 'pluggy-sdk'

import { OpportunityAPI } from '../../lib/api/opportunity'
import { getPluggyApiKey } from '../login/selectors'
import {
  fetchOpportunitiesFailure,
  FetchOpportunitiesRequestAction,
  fetchOpportunitiesSuccess,
  FETCH_OPPORTUNITIES_REQUEST,
} from './actions'

function* handleFetchOpportunitiesRequest(
  action: FetchOpportunitiesRequestAction
) {
  const { itemId } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const opportunityAPI = new OpportunityAPI(apiKey)
  try {
    const opportunities: Opportunity[] = yield call(() =>
      opportunityAPI.fetchOpportunities(itemId)
    )

    yield put(fetchOpportunitiesSuccess(opportunities, itemId))
  } catch (error) {
    yield put(fetchOpportunitiesFailure(error.message, itemId))
  }
}

export function* opportunitySaga() {
  yield takeEvery(FETCH_OPPORTUNITIES_REQUEST, handleFetchOpportunitiesRequest)
}
