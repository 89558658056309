import { action } from 'typesafe-actions'

/**
 *  Request Pluggy Metabase Insights with API Key
 */
export const GET_METABASE_IFRAME_INSIGHTS_REQUEST =
  '[Request] Get Metabase Iframe Insights'
export const GET_METABASE_IFRAME_INSIGHTS_SUCCESS =
  '[Success] Get Metabase Iframe Insights'
export const GET_METABASE_IFRAME_INSIGHTS_FAILURE =
  '[Failure] Get Metabase Iframe Insights'

export const getMetabaseIframeInsightsRequest = () =>
  action(GET_METABASE_IFRAME_INSIGHTS_REQUEST)
export const getMetabaseIframeInsightsSuccess = (metabaseUrl: string) =>
  action(GET_METABASE_IFRAME_INSIGHTS_SUCCESS, { metabaseUrl })
export const getMetabaseIframeInsightsFailure = (error: string) =>
  action(GET_METABASE_IFRAME_INSIGHTS_FAILURE, { error })

export type GetMetabaseIframeInsightsRequestAction = ReturnType<
  typeof getMetabaseIframeInsightsRequest
>
export type GetMetabaseIframeInsightsSuccessAction = ReturnType<
  typeof getMetabaseIframeInsightsSuccess
>
export type GetMetabaseIframeInsightsFailureAction = ReturnType<
  typeof getMetabaseIframeInsightsFailure
>
