import {
  Connector,
  ExecutionErrorCode,
  ExecutionErrorResult,
  ExecutionStatus,
  Item,
} from 'pluggy-sdk'

const ONE_MINUTE_IN_SECONDS = 60

export enum CONNECTION_STEP {
  UNKNOWN = 0,
  LOGIN_IN_PROGRESS = 1,
  ACCOUNT_IN_PROGRESS = 3,
  CREDITCARD_IN_PROGRESS = 4,
  TRANSACTION_IN_PROGRESS = 5,
  INVESTMENTS_IN_PROGRESS = 6,
  OPPORTUNITIES_IN_PROGRESS = 7,
  IDENTITY_IN_PROGRESS = 8,
  FINISHED = 9,
}

export const connectionErrors: Extract<
  ExecutionStatus,
  | 'ERROR'
  | 'CREATE_ERROR'
  | 'SITE_NOT_AVAILABLE'
  | 'ACCOUNT_LOCKED'
  | 'ACCOUNT_NEEDS_ACTION'
  | 'CONNECTION_ERROR'
>[] = [
  'ERROR',
  'CREATE_ERROR',
  'SITE_NOT_AVAILABLE',
  'ACCOUNT_LOCKED',
  'ACCOUNT_NEEDS_ACTION',
  'CONNECTION_ERROR',
]

export function isExecutionError(
  step: CONNECTION_STEP | ExecutionStatus
): step is ExecutionErrorCode {
  return connectionErrors.some((executionError) => executionError === step)
}

function isItemError(itemStatus: string): boolean {
  return itemStatus === 'OUTDATED'
}

/**
 * Map an item.error.code {ExecutionErrorCode} to the corresponding ExecutionError.
 *
 * Note: This should not be needed, as the executionStatus should already be properly mapped,
 * but in some cases when the execution is too fast (ie. Pluggy Bank), this is failing.
 *  Ticket: https://pluggy.atlassian.net/browse/CAPI-327
 *
 * @param executionError
 */
function mapExecutionErrorCodeToExecutionError(
  executionError: ExecutionErrorResult
): ExecutionErrorCode | 'ERROR' | undefined {
  const { code } = executionError
  if (code === 'UNEXPECTED_ERROR') {
    return 'ERROR'
  }
  if (code === 'CONNECTION_ERROR') {
    return 'CONNECTION_ERROR'
  }
  if (code === 'SITE_NOT_AVAILABLE') {
    return 'SITE_NOT_AVAILABLE'
  }
  return undefined
}

/**
 * Determines the current UI state Step (or finish error result)
 * based on the item.executionStatus and item.status
 *
 * @param item
 */
export function mapExecutionStatusToStep(
  item: Item
): CONNECTION_STEP | ExecutionErrorCode | 'ERROR' {
  const {
    executionStatus: status,
    status: itemStatus,
    error: executionError,
  } = item

  if (executionError !== null) {
    const mappedExecutionError =
      mapExecutionErrorCodeToExecutionError(executionError)
    if (mappedExecutionError) {
      return mappedExecutionError
    }
  }
  if (isExecutionError(status)) {
    return status
  }
  if (isItemError(itemStatus)) {
    return 'ERROR'
  }
  if (status === 'LOGIN_IN_PROGRESS') {
    return CONNECTION_STEP.LOGIN_IN_PROGRESS
  }
  if (status === 'ACCOUNTS_IN_PROGRESS') {
    return CONNECTION_STEP.ACCOUNT_IN_PROGRESS
  }
  if (status === 'CREDITCARDS_IN_PROGRESS') {
    return CONNECTION_STEP.CREDITCARD_IN_PROGRESS
  }
  if (
    status === 'TRANSACTIONS_IN_PROGRESS' ||
    status === 'PAYMENT_DATA_IN_PROGRESS'
  ) {
    return CONNECTION_STEP.TRANSACTION_IN_PROGRESS
  }
  if (
    status === 'INVESTMENTS_IN_PROGRESS' ||
    status === 'INVESTMENTS_TRANSACTIONS_IN_PROGRESS'
  ) {
    return CONNECTION_STEP.INVESTMENTS_IN_PROGRESS
  }
  if (status === 'OPPORTUNITIES_IN_PROGRESS') {
    return CONNECTION_STEP.OPPORTUNITIES_IN_PROGRESS
  }
  if (status === 'IDENTITY_IN_PROGRESS') {
    return CONNECTION_STEP.IDENTITY_IN_PROGRESS
  }
  if (status === 'SUCCESS' || status === 'PARTIAL_SUCCESS') {
    return CONNECTION_STEP.FINISHED
  }

  return CONNECTION_STEP.UNKNOWN
}

// source: https://metabase.pluggy.dev/question/159-average-of-duration-by-connector
// TODO: get this data dinamically
// TODO: use ConnectorId enum
const timeByConnectorIdInSeconds: Record<string, number | undefined> = {
  0: 8.519184452184927,
  1: 9.053423529411765,
  2: 8.654057112375535,
  3: 31.669999999999998,
  4: 8.966179431072206,
  5: 29.40715902964959,
  201: 80.98535234109339,
  202: 113.50020448424455,
  203: 113.1053651505445,
  204: 8.503008565310493,
  205: 44.700235431235434,
  207: 19.753697025339694,
  208: 18.61972447628491,
  209: 55.426589033133126,
  210: 26.087447947650208,
  211: 4.983973350641418,
  212: 18.907206096212075,
  213: 14.856714831804279,
  214: 98.71912271259419,
  215: 111.99189847215374,
  216: 71.50084862692567,
  217: 95.75472900335946,
  218: 72.77271320309866,
  219: 77.42507775784749,
  220: 70.91558778625955,
  221: 79.89449500976349,
  222: 77.20131455793751,
  223: 159.70701796407187,
  224: 4.751620501635768,
  225: 1.6716583471991122,
  226: 8.12651958577217,
  227: 3.6,
}

export function getAverageConnectorTimeInMinutes(
  connectorId: Connector['id']
): number {
  // if there's no data for this connector, return a default value of one minute
  const connectorTimeInSeconds = timeByConnectorIdInSeconds[connectorId]

  // if the connector time is less than a minute, return a default value of one minute
  const connectorTimeInMinutes =
    connectorTimeInSeconds === undefined
      ? ONE_MINUTE_IN_SECONDS
      : Math.max(Math.round(connectorTimeInSeconds / 60), 1)

  return connectorTimeInMinutes
}
