import { ConnectTokenOptions } from 'pluggy-sdk'

import { BaseAPI } from './BaseAPI'

export type ConnectToken = {
  accessToken: string
}

export class ConnectTokenAPI extends BaseAPI {
  /**
   * Creates a Connect Token that can be used as API KEY to connect items from the Frontend
   *
   * @param {string} itemId - primary identifier of the Item
   * @param {ConnectTokenOptions} options - options object to create a connect token
   * @returns {string} Access token to connect items with restrict access
   *
   * @throws {AxiosError<ErrorResponse>} status 404 If specified Item by 'id' does not exist or is not accessible by the user,
   *                                     status 403 if user is unauthorized
   */
  async createConnectToken(
    itemId?: string,
    options?: ConnectTokenOptions
  ): Promise<ConnectToken> {
    return await this.request<ConnectToken>('POST', '/connect_token', {
      itemId,
      options,
    })
  }
}
