import { action } from 'typesafe-actions'

import type { AcquirerReceivable } from 'pluggy-sdk'

/// Fetch acquirer Receivables

export const FETCH_ACQUIRER_RECEIVABLES_REQUEST = '[Request] Fetch acquirer receivables'
export const FETCH_ACQUIRER_RECEIVABLES_SUCCESS = '[Success] Fetch acquirer receivables'
export const FETCH_ACQUIRER_RECEIVABLES_FAILURE = '[Failure] Fetch acquirer receivables'

export const fetchAcquirerReceivablesRequest = (itemId: string) =>
  action(FETCH_ACQUIRER_RECEIVABLES_REQUEST, { itemId })

export const fetchAcquirerReceivablesSuccess = (
  acquirerReceivables: AcquirerReceivable[],
  itemId: string
) => action(FETCH_ACQUIRER_RECEIVABLES_SUCCESS, { acquirerReceivables, itemId })

export const fetchAcquirerReceivablesFailure = (error: string, itemId: string) =>
  action(FETCH_ACQUIRER_RECEIVABLES_FAILURE, { error, itemId })

export type FetchAcquirerReceivablesRequestAction = ReturnType<
  typeof fetchAcquirerReceivablesRequest
>
export type FetchLAcquirerReceivablesSuccessAction = ReturnType<
  typeof fetchAcquirerReceivablesSuccess
>
export type FetchAcquirerReceivablesFailureAction = ReturnType<
  typeof fetchAcquirerReceivablesFailure
>
