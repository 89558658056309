import { AnyAction } from 'redux'
import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { GET_METABASE_IFRAME_INSIGHTS_REQUEST } from './actions'

export const getState = (state: RootState) => state.insights
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type
) => state.some((action: AnyAction) => action.type === type)

export const isMetabaseInsightsLoading = (state: RootState) =>
  isLoadingType(getLoading(state), GET_METABASE_IFRAME_INSIGHTS_REQUEST)
