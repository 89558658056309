import { loadingReducer } from '../loading/reducer'
import { LoadingState } from '../loading/types'
import {
  CREATE_PLUGGY_API_KEY_FAILURE,
  CREATE_PLUGGY_API_KEY_REQUEST,
  CREATE_PLUGGY_API_KEY_SUCCESS,
  CreatePluggyApiKeyFailureAction,
  CreatePluggyApiKeyRequestAction,
  CreatePluggyApiKeySuccessAction,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LogoutFailureAction,
  LogoutRequestAction,
  LogoutSuccessAction,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LoginFailureAction,
  LoginRequestAction,
  LoginSuccessAction,
} from './actions'
import { DemoUser } from './types'

export type LoginState = {
  data: {
    user: DemoUser | null
  }
  loading: LoadingState
  error: string | null
}

export const INITIAL_STATE: LoginState = {
  data: {
    user: null,
  },
  loading: [],
  error: null,
}

export type LoginReducerAction =
  | LogoutRequestAction
  | LogoutSuccessAction
  | LogoutFailureAction
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction
  | CreatePluggyApiKeyRequestAction
  | CreatePluggyApiKeySuccessAction
  | CreatePluggyApiKeyFailureAction

export function loginReducer(
  state: LoginState = INITIAL_STATE,
  action: LoginReducerAction
): LoginState {
  switch (action.type) {
    case LOGOUT_REQUEST:
    case LOGIN_REQUEST:
    case CREATE_PLUGGY_API_KEY_REQUEST: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    }
    case LOGOUT_SUCCESS:
      return INITIAL_STATE
    case LOGOUT_FAILURE:
    case LOGIN_FAILURE: {
      const {
        payload: { error },
      } = action

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: { user: null },
        error,
      }
    }
    case CREATE_PLUGGY_API_KEY_FAILURE: {
      const {
        payload: { error },
      } = action

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error,
      }
    }
    case LOGIN_SUCCESS: {
      const {
        payload: { user },
      } = action

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: { user },
        error: null,
      }
    }
    case CREATE_PLUGGY_API_KEY_SUCCESS: {
      const {
        payload: { apiKey: pluggyApiKey },
      } = action

      const {
        data: { user },
      } = state

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        data: {
          user: user
            ? {
                ...user,
                pluggyApiKey,
              }
            : null,
        },
        error: null,
      }
    }
    default: {
      return state
    }
  }
}
