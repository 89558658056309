import type { Account, InvestmentTransaction, PageResponse } from 'pluggy-sdk'

import { BaseAPI } from './BaseAPI'

const TRANSACTIONS_PAGE_SIZE = 500

// limit page requests, as a temporary solution until Pagination component
// is implemented in pluggy-ui
const MAX_PAGES = 5

export class InvestmentTransactionAPI extends BaseAPI {
  async fetchAll(
    investmentId: Account['id']
  ): Promise<InvestmentTransaction[]> {
    const { results: firstResults, totalPages } = await this.request<
      PageResponse<InvestmentTransaction>
    >(
      'get',
      `/investments/${investmentId}/transactions/?pageSize=${TRANSACTIONS_PAGE_SIZE}`
    )

    if (totalPages === 1) {
      return firstResults
    }

    const transactions: InvestmentTransaction[] = [...firstResults]

    // first page already fetched
    let page = 1

    // fetch remaining pages up to totalPages, limited by MAX_PAGES
    while (page < Math.min(totalPages, MAX_PAGES)) {
      page++
      const { results } = await this.request<
        PageResponse<InvestmentTransaction>
      >(
        'get',
        `/investments/${investmentId}/transactions/?pageSize=${TRANSACTIONS_PAGE_SIZE}&page=${page}`
      )
      transactions.push(...results)
    }

    return transactions
  }
}
