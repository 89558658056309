import { all, call, put, takeEvery } from 'redux-saga/effects'
import { ApplicationAPI } from '../../lib/api/application'

import {
  FETCH_APPLICATION_REQUEST,
  fetchApplicationFailure,
  FetchApplicationRequestAction,
  fetchApplicationSuccess,
} from './actions'

import { Application } from './types'
import { getAuth0AccessToken } from '../../components/Auth0Provider'
import { retrieveStoredApplicationId } from '../login/storage'
import { setApplicationTagsInSentryContext } from '../../lib/sentry'

function* handleFetchApplicationRequest(
  _action: FetchApplicationRequestAction
) {
  let auth0AccessToken: string | undefined

  try {
    auth0AccessToken = yield call(getAuth0AccessToken)
  } catch (error) {
    error.message = `Could not fetch application due to Auth0 error: ${error.message}`
    console.error(error)
    yield put(fetchApplicationFailure(error.message))
    return
  }

  if (!auth0AccessToken) {
    yield put(fetchApplicationFailure('Missing Auth0 access token'))
    return
  }

  const applicationAPI = new ApplicationAPI(auth0AccessToken)

  // get current applicationId (which was provided from URL)
  const applicationId = retrieveStoredApplicationId()

  try {
    const application: Application = yield call(() =>
      applicationAPI.fetchApplication(applicationId)
    )
    yield put(fetchApplicationSuccess(application))

    // add application data to Sentry context as tags
    setApplicationTagsInSentryContext(application)
  } catch (error) {
    yield put(fetchApplicationFailure(error.message))
  }
}

export function* applicationSaga() {
  yield all([
    takeEvery(FETCH_APPLICATION_REQUEST, handleFetchApplicationRequest),
  ])
}
