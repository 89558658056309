import React, { useCallback, useEffect, useState } from 'react'

import {
  AcquirerOperations,
  AccountWithTransactions,
  Alert,
  Button,
  Identity,
  IdentityComponent,
  Investment,
  Investments,
  Loan,
  Loans,
  Opportunities,
  Tag,
  Benefits,
} from '@pluggyai/ui'

import { Image } from 'semantic-ui-react'

import { Accounts } from '../Accounts/'
import { ItemProductsContainerJsonView } from './ItemProductsContainerJsonView'
import { ItemProductsContainerSkeleton } from './ItemProductsContainerSkeleton'
import { Props } from './ItemProductsContainer.types'
import { usePrevious } from '../../utils/hooks'

import { json2csv } from 'json-2-csv'
import './ItemProductsContainer.css'

const isAccountWithTransactions = (
  data: unknown
): data is AccountWithTransactions[] => {
  return (
    Array.isArray(data) &&
    data.length > 0 &&
    'transactions' in data[0] &&
    'marketingName' in data[0]
  )
}
const ItemProductsContainer = ({
  itemInvestments,
  itemIdentity,
  itemAccounts,
  itemOpportunities,
  itemLoans,
  itemBenefits,
  itemAcquirerSales,
  itemAcquirerReceivables,
  itemAcquirerAnticipations,
  isLoading,
  item,
  itemAccountError,
  itemIdentityError,
  itemInvestmentError,
  itemOpportunitiesError,
  itemLoansError,
  itemBenefitsError,
  itemAcquirerSalesError,
  itemAcquirerReceivablesError,
  itemAcquirerAnticipationsError,
  onFetchAccounts,
  onFetchIdentityByItemId,
  onFetchInvestments,
  onFetchOpportunities,
  onFetchLoans,
  onFetchBenefits,
  onFetchAcquirerSales,
  onFetchAcquirerReceivables,
  onFetchAcquirerAnticipations,
  isTransactionsLoading,
  isInvestmentTransactionsLoading,
  isAcquirerSalesLoading,
  isAcquirerReceivablesLoading,
  isAcquirerAnticipationsLoading,
}: Props) => {
  const [productIdOpen, setProductIdOpen] = useState<string>()

  const [isJsonItemView, setIsJsonItemView] = useState(false)

  const { id: itemId } = item

  const prevItemId = usePrevious(itemId)

  const itemHasSomeError = item.error

  const itemHasLoginError = itemHasSomeError && item.status === 'LOGIN_ERROR'

  const itemNeverConnected = item.lastUpdatedAt === null

  // in this case, the item has an error (not login error, we have another error to show there) and also it
  // was never connected (or finished the connection process)
  const itemOutdatedNeverConnected =
    itemHasSomeError && !itemHasLoginError && itemNeverConnected

  useEffect(() => {
    if (prevItemId === itemId || itemNeverConnected) {
      // itemId didn't change, or item has never connected -> just return
      return
    }
    // new item selected & has products data -> fetch products
    onFetchAccounts(itemId)
    onFetchInvestments(itemId)
    onFetchIdentityByItemId(itemId)
    onFetchOpportunities(itemId)
    onFetchLoans(itemId)
    onFetchBenefits(itemId)
    onFetchAcquirerSales(itemId)
    onFetchAcquirerReceivables(itemId)
    onFetchAcquirerAnticipations(itemId)
  }, [
    itemId,
    prevItemId,
    itemNeverConnected,
    onFetchAccounts,
    onFetchInvestments,
    onFetchIdentityByItemId,
    onFetchOpportunities,
    onFetchLoans,
    onFetchBenefits,
    onFetchAcquirerSales,
    onFetchAcquirerReceivables,
    onFetchAcquirerAnticipations,
  ])

  const handleProductClick = useCallback(
    (productId: string) => {
      if (productIdOpen === productId) {
        // clicking the same product, close it
        setProductIdOpen(undefined)
        return
      }
      setProductIdOpen(productId)
    },
    [productIdOpen]
  )

  const someProductError =
    itemAccountError ||
    itemInvestmentError ||
    itemIdentityError ||
    itemOpportunitiesError ||
    itemLoansError ||
    itemBenefitsError ||
    itemAcquirerSalesError ||
    itemAcquirerReceivablesError ||
    itemAcquirerAnticipationsError

  const allProductsError =
    itemAccountError &&
    itemIdentityError &&
    itemInvestmentError &&
    itemOpportunitiesError &&
    itemLoansError &&
    itemBenefitsError &&
    itemAcquirerSalesError &&
    itemAcquirerReceivablesError &&
    itemAcquirerAnticipationsError

  // only show alert when was a error fetching the products, if the item has
  // error itself we don't want to show an alert because we are not fetching the data
  const shouldShowErrorAlert =
    allProductsError && !itemHasSomeError && !isLoading
  const shouldShowPartialErrorAlert =
    someProductError && !itemHasSomeError && !isLoading && !allProductsError

    const shouldShowMaquininhasTitle = itemAcquirerSales.length > 0 

  const partialErrors: string[] = []

  if (itemAccountError) {
    partialErrors.push('Contas')
  }
  if (itemIdentityError) {
    partialErrors.push('Identidade')
  }
  if (itemInvestmentError) {
    partialErrors.push('Investimentos')
  }
  if (itemOpportunitiesError) {
    partialErrors.push('Oportunidades')
  }
  if (itemLoansError) {
    partialErrors.push('Empréstimos')
  }
  if (itemBenefitsError) {
    partialErrors.push('Benefícios')
  }
  if (itemAcquirerSalesError) {
    partialErrors.push('Vendas')
  }
  if (itemAcquirerReceivablesError) {
    partialErrors.push('Recebíveis')
  }
  if (itemAcquirerAnticipationsError) {
    partialErrors.push('Antecipações')
  }

  // we join the errors with an "e" between them
  const partialErrorsJoined = partialErrors.join(' e ')

  const handleViewChange = React.useCallback(() => {
    setIsJsonItemView((previous) => !previous)
  }, [])

  const downloadProductData = useCallback(
    (
      productData: Identity | AccountWithTransactions[] | Investment[] | Loan[],
      type: string
    ) => {
      function downloadCSV(productData_: any, name: string) {
        const data = json2csv(productData_, {
          emptyFieldValue: '-',
          wrapBooleans: true,
          expandArrayObjects: true,
          expandNestedObjects: true,
          unwindArrays: true,
        })

        const blob = new Blob([data], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `pluggy-${name}-data.csv`
        a.click()
      }
      if (isAccountWithTransactions(productData)) {
        const data = productData.map((account) => ({
          accountId: account.id,
          transactions: account.transactions,
        }))
        downloadCSV(data, type)
        return
      }

      downloadCSV(productData as any, type)
    },
    []
  )

  return (
    <div className={'ItemProductsContainer'}>
      <div className="item-products-header">
        <h3> Item dados </h3>
        <Button onClick={handleViewChange}>
          {isJsonItemView ? 'Ver Dados' : 'Ver JSON'}
        </Button>
      </div>

      {(shouldShowErrorAlert || shouldShowPartialErrorAlert) && (
        <Alert
          size={'medium'}
          type={'error'}
          message={
            shouldShowErrorAlert
              ? 'Estamos enfrentando problemas ao trazer dados. Tente atualizar e se o problema persistir não hesite em contatar-nos.'
              : `Desculpe, estamos enfrentando problemas para trazer os dados de ${partialErrorsJoined}. Por favor, tente atualizar o item e se o problema persistir entre em contato conosco.`
          }
        />
      )}
      {isLoading ? (
        <ItemProductsContainerSkeleton />
      ) : isJsonItemView ? (
        <ItemProductsContainerJsonView
          item={item}
          identity={itemIdentity}
          accounts={itemAccounts}
          investments={itemInvestments}
          opportunities={itemOpportunities}
          loans={itemLoans}
          benefits={itemBenefits}
          acquirerSales={itemAcquirerSales}
        />
      ) : (itemHasLoginError && itemNeverConnected) ||
        itemOutdatedNeverConnected ? (
        // Item had an error, and no data yet -> show error
        <div className={'error-container'}>
          <p>
            É necessário atualizar as credenciais para trazer os dados desta
            conta.
          </p>
          {itemHasLoginError ? (
            <Image src={'/assets/errors/item-error.png'} />
          ) : (
            <Image src={'/assets/errors/item-outdated.png'} />
          )}
        </div>
      ) : (
        <>
          {itemIdentity && (
            <div className={'product'}>
              <div className={'title-container'}>
                <h3>Identidade</h3>
                <Button
                  onClick={() => downloadProductData(itemIdentity, 'identity')}
                  size="small"
                  link
                >
                  Exportar
                </Button>
              </div>
              <IdentityComponent
                identity={itemIdentity}
                onIdentityClick={handleProductClick}
                isOpen={productIdOpen === itemIdentity.id}
              />
            </div>
          )}
          {itemAccounts.length > 0 && (
            <div className={'product'}>
              <div className={'title-container'}>
                <h3>Detalhes das contas</h3>
                <Button
                  size="small"
                  onClick={() => downloadProductData(itemAccounts, 'accounts')}
                  link
                >
                  Exportar
                </Button>
              </div>
              <Accounts
                accountIdSelected={productIdOpen}
                onAccountClick={handleProductClick}
                accountsWithTransactions={itemAccounts}
                isTransactionsLoading={isTransactionsLoading}
              />
            </div>
          )}
          {itemInvestments.length > 0 && (
            <div className={'product'}>
              <div className={'title-container'}>
                <h3>Investimento</h3>
                <Button
                  size="small"
                  onClick={() =>
                    downloadProductData(itemInvestments, 'investments')
                  }
                  link
                >
                  Exportar
                </Button>
              </div>
              <Investments
                investments={itemInvestments}
                productIdSelected={productIdOpen}
                onInvestmentClick={handleProductClick}
                investmentTransactionsLoading={isInvestmentTransactionsLoading}
              />
            </div>
          )}
          {itemLoans.length > 0 && (
            <div className={'product'}>
              <div className={'title-container'}>
                <h3>Empréstimos</h3>
                <Button
                  size="small"
                  onClick={() => downloadProductData(itemLoans, 'loans')}
                  link
                >
                  Exportar
                </Button>
              </div>
              <Loans itemLoans={itemLoans} onLoanClick={handleProductClick} />
            </div>
          )}
          {itemOpportunities.length > 0 && (
            <div className={'product'}>
              <h3>
                Oportunidades <Tag text={'Beta'} type={'info'} />
              </h3>
              <Opportunities
                itemOpportunities={itemOpportunities}
                onOpportunityClick={handleProductClick}
              />
            </div>
          )}
          {itemBenefits.length > 0 && (
            <div className={'product'}>
              <div className={'title-container'}>
                <h3>Benefícios</h3>
              </div>
              <Benefits
                benefitIdSelected={productIdOpen}
                benefits={itemBenefits}
                onBenefitClick={handleProductClick}
              />
            </div>
          )}
          {shouldShowMaquininhasTitle && <h4>Maquininhas</h4>}
          {itemAcquirerSales.length > 0 && (
            <div className={'product'}>
              <AcquirerOperations
                acquirerOperations={itemAcquirerSales}
                operationTypeSelected={productIdOpen}
                acquirerOperationsType='sales'
                isLoading={isAcquirerSalesLoading}
                onAcquirerContainerClick={handleProductClick}
                acquirerOperationsTitle='Vendas'
              />
            </div>
          )}
          {itemAcquirerReceivables.length > 0 && (
            <div className={'product'}>
              <AcquirerOperations
                acquirerOperations={itemAcquirerReceivables}
                operationTypeSelected={productIdOpen}
                acquirerOperationsType='receivables'
                isLoading={isAcquirerReceivablesLoading}
                onAcquirerContainerClick={handleProductClick}
                acquirerOperationsTitle='Recebíveis'
              />
            </div>
          )}
          {itemAcquirerAnticipations.length > 0 && (
            <div className={'product'}>
              <AcquirerOperations
                acquirerOperations={itemAcquirerAnticipations}
                operationTypeSelected={productIdOpen}
                acquirerOperationsType='anticipations'
                isLoading={isAcquirerAnticipationsLoading}
                onAcquirerContainerClick={handleProductClick}
                acquirerOperationsTitle='Antecipações'
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(ItemProductsContainer)
