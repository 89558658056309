import React from 'react'

export function parseLoginErrorI18nKey(
  errorKeyOrMessage: string
): string | React.ReactNode {
  const errorI18nKeysMap: Record<string, string | React.ReactNode> = {
    'error.client.network_error':
      'Houve um problema na conexão com o servidor. Aguarde alguns instantes e tente novamente.',
    'error.server.unexpected_error':
      'Ocorreu um problema ao autenticar com a Pluggy. Aguarde alguns instantes e tente novamente.',
    'error.server.malformed_response':
      'Ocorreu um problema de comunicação com o servidor. Aguarde alguns instantes e tente novamente.',
    'error.login.unexpected_error':
      'Falha na autenticação. Por favor, tente fazer login novamente.',
    'error.login.missing_session':
      'Sessão expirada ou não encontrada. Por favor, tente fazer login novamente.',
    'error.application.not_found':
      'Parece que você não tem acesso. Tente entrar com outro e-mail ou entre em contato com o administrador.',
    'error.application.invalid_id': 'O application_id é inválido ou incorreto.',
    'error.application.not_enabled':
      'Seu aplicativo não está habilitado. Entre em contato com seu administrador para pedir-lhe para habilitá-lo.',
    'error.application.custom_url_not_found': `O URL do aplicativo '${document.location.origin}' não foi encontrado.`,
    'error.user.not_registered': (
      <span>
        Parece que você não tem autorização para entrar. Interessado?{' '}
        <a
          href={
            'https://api.whatsapp.com/send?phone=5511950779235&text=Ol%C3%A1!%20Gostaria%20de%20acessar%20o%20Pluggy%20para%20test%C3%A1-lo.'
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Contate-nos.
        </a>
      </span>
    ),
  }

  if (Object.keys(errorI18nKeysMap).includes(errorKeyOrMessage)) {
    return errorI18nKeysMap[errorKeyOrMessage]
  }

  return errorKeyOrMessage
}
