import axios, { AxiosError } from 'axios'
import { Application } from '../../modules/application/types'
import { captureException } from '@sentry/react'
import axiosBetterStacktrace from 'axios-better-stacktrace'

export class ApplicationAPI {
  /**
   *
   * @param auth0AccessToken the user authentication token from Auth0
   */
  constructor(private auth0AccessToken: string) {}

  async fetchApplication(applicationId?: string): Promise<Application> {
    try {
      const client = axios.create()
      axiosBetterStacktrace(client, { errorMsg: 'ApplicationAPI' })

      const { data } = await client.get(
        `/api/application/?applicationId=${applicationId}`,
        {
          headers: {
            Authorization: `Bearer ${this.auth0AccessToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      return data
    } catch (error) {
      const errorI18nKey = 'error.application.fetch'

      let errorMessage

      if (axios.isAxiosError(error)) {
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          errorMessage = error.response
        } else if (error.request) {
          // The request was made but no response was received
          errorMessage = error.response
        }
      } else {
        // Something happened in setting up the request and triggered an Error
        errorMessage = error.message
      }

      error.message = `Failed fetchApplication(): '${errorI18nKey}' - ${JSON.stringify(
        errorMessage
      )}`

      captureException(error, {
        level: 'error',
        contexts: {
          data: {
            applicationId,
            error,
            errorResponse: (error as AxiosError).response,
          },
        },
        fingerprint: [error.message],
      })

      throw new Error(errorI18nKey)
    }
  }
}
