import { AnyAction } from 'redux'
import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { LOGOUT_REQUEST, LOGIN_REQUEST } from './actions'
import { LoginState } from './reducer'
import { DemoUser } from './types'
import { isPluggyUserEmail } from './utils'

export const getState = (state: RootState): LoginState =>
  state.login as LoginState
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type
) => state.some((action: AnyAction) => action.type === type)

export const isLoggingIn = (state: RootState) =>
  isLoadingType(getLoading(state), LOGIN_REQUEST)

export const isLoggingOut = (state: RootState) =>
  isLoadingType(getLoading(state), LOGOUT_REQUEST)

export const getUser = (state: RootState): DemoUser | null =>
  getData(state).user

// To use this function you have to first check if logged in
export const getPluggyApiKey = (state: RootState): string | undefined =>
  getUser(state)?.pluggyApiKey

export const isLoggedIn = (state: RootState): boolean => !!getUser(state)

export const getEmail = (state: RootState): string | undefined =>
  getUser(state)?.email

export const getIsPluggyUserEmail = (state: RootState): boolean => {
  const email = getEmail(state)
  return email !== undefined ? isPluggyUserEmail(email) : false
}
