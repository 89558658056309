import type { Item, AcquirerSale } from 'pluggy-sdk'

import { BaseAPI, PageResponse } from './BaseAPI'

export class AcquirerSaleAPI extends BaseAPI {
  async fetchAcquirerSales(itemId: Item['id']): Promise<AcquirerSale[]> {
    const { results } = await this.request<PageResponse<AcquirerSale>>(
      'get',
      `/acquirer-sales?itemId=${itemId}`
    )
    return results
  }
}
