import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { loginReducer as login } from './login/reducer'
import { accountReducer as account } from './account/reducer'
import { itemReducer as item } from './item/reducer'
import { connectTokenReducer as auth } from './connectToken/reducer'
import { transactionReducer as transaction } from './transaction/reducer'
import { categoryReducer as category } from './category/reducer'
import { investmentReducer as investment } from './investment/reducer'
import { identityReducer as identity } from './identity/reducer'
import { insightsReducer as insights } from './insights/reducer'
import { loadingReducer as loading } from './loading/reducer'
import { loanReducer as loan } from './loan/reducer'
import { benefitReducer as benefit } from './benefit/reducer'
import { acquirerSaleReducer as acquirerSale } from './acquirerSale/reducer'
import { acquirerReceivableReducer as acquirerReceivable } from './acquirerReceivable/reducer'
import { acquirerAnticipationReducer as acquirerAnticipation} from './acquirerAnticipation/reducer'
import { opportunityReducer as opportunity } from './opportunity/reducer'
import { reducer as notifications } from 'react-notification-system-redux'
import { notificationReducer as notification } from './notification/reducer'
import { applicationReducer as application } from './application/reducer'
import { investmentTransactionReducer as investmentTransaction } from './investmentTransaction/reducer'

export const createRootReducer = (history: History) =>
  combineReducers({
    account,
    application,
    auth,
    category,
    login,
    identity,
    insights,
    investment,
    investmentTransaction,
    item,
    loading,
    loan,
    notification,
    notifications,
    opportunity,
    router: connectRouter(history),
    transaction,
    acquirerSale,
    acquirerReceivable,
    acquirerAnticipation,
    benefit
  })

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>
