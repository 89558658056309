import { all } from 'redux-saga/effects'

import { accountSaga } from './account/sagas'
import { analyticsSaga } from './analytics/sagas'
import { applicationSaga } from './application/sagas'
import { connectTokenSaga } from './connectToken/sagas'
import { categorySaga } from './category/sagas'
import { identitySaga } from './identity/sagas'
import { insightsSaga } from './insights/sagas'
import { investmentSaga } from './investment/sagas'
import { itemSaga } from './item/sagas'
import { loginSaga } from './login/sagas'
import { opportunitySaga } from './opportunity/sagas'
import { transactionSaga } from './transaction/sagas'
import { notificationSaga } from './notification/sagas'
import { investmentTransactionSaga } from './investmentTransaction/sagas'
import { loanSaga } from './loan/sagas'
import { acquirerSaleSaga } from './acquirerSale/sagas'
import { acquirerReceivableSaga } from './acquirerReceivable/sagas'
import { acquirerAnticipationSaga } from './acquirerAnticipation/sagas'
import { benefitSaga } from './benefit/sagas'

export function* rootSaga() {
  yield all([
    accountSaga(),
    analyticsSaga(),
    applicationSaga(),
    connectTokenSaga(),
    categorySaga(),
    identitySaga(),
    insightsSaga(),
    investmentSaga(),
    investmentTransactionSaga(),
    itemSaga(),
    loanSaga(),
    acquirerSaleSaga(),
    acquirerReceivableSaga(),
    acquirerAnticipationSaga(),
    loginSaga(),
    notificationSaga(),
    opportunitySaga(),
    transactionSaga(),
    benefitSaga(),
  ])
}
