import React from 'react'

import { PluggyLogo } from './PluggyLogo'

import './LoadingPage.css'

const LoadingPage = () => (
  <div className={'LoadingPage'}>
    <PluggyLogo />
    <div className={'progress-bar'} />
  </div>
)

export default React.memo(LoadingPage)
