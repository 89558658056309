import { AnyAction } from 'redux'
import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { isItemsCreateLimitExceeded } from './utils'
import { getItemsTotalExceptSandbox } from '../item/selectors'

export const getState = (state: RootState) => state.application
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type
) => state.some((action: AnyAction) => action.type === type)

const DEFAULT_APPLICATION_ITEMS_CREATE_LIMIT = 100

export const getCreateItemsLimit = (state: RootState): number | null => {
  const application = getData(state)
  if (!application) {
    return null
  }

  const {
    subscription: { itemsLimit = DEFAULT_APPLICATION_ITEMS_CREATE_LIMIT },
  } = application

  return itemsLimit
}

export const getIsItemsCreateLimitExceeded = (
  state: RootState
): boolean | null => {
  const application = getData(state)
  const itemsTotalExceptSandbox = getItemsTotalExceptSandbox(state)
  if (application === null || itemsTotalExceptSandbox === null) {
    return null
  }

  return isItemsCreateLimitExceeded(application, itemsTotalExceptSandbox)
}
