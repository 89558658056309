import { action } from 'typesafe-actions'

import type { AcquirerSale } from 'pluggy-sdk'

/// Fetch acquirer sales

export const FETCH_ACQUIRER_SALES_REQUEST = '[Request] Fetch acquirer sales'
export const FETCH_ACQUIRER_SALES_SUCCESS = '[Success] Fetch acquirer sales'
export const FETCH_ACQUIRER_SALES_FAILURE = '[Failure] Fetch acquirer sales'

export const fetchAcquirerSalesRequest = (itemId: string) =>
  action(FETCH_ACQUIRER_SALES_REQUEST, { itemId })

export const fetchAcquirerSalesSuccess = (
  acquirerSales: AcquirerSale[],
  itemId: string
) => action(FETCH_ACQUIRER_SALES_SUCCESS, { acquirerSales, itemId })

export const fetchAcquirerSalesFailure = (error: string, itemId: string) =>
  action(FETCH_ACQUIRER_SALES_FAILURE, { error, itemId })

export type FetchAcquirerSalesRequestAction = ReturnType<
  typeof fetchAcquirerSalesRequest
>
export type FetchLAcquirerSalesSuccessAction = ReturnType<
  typeof fetchAcquirerSalesSuccess
>
export type FetchAcquirerSalesFailureAction = ReturnType<
  typeof fetchAcquirerSalesFailure
>
