import { Item, Parameters } from 'pluggy-sdk'

import { BaseAPI, PageResponse } from './BaseAPI'

export class ItemAPI extends BaseAPI {
  async updateItem(
    item: {
      id: string
      webhookUrl?: string
    },
    parameters?: Parameters
  ): Promise<Item> {
    return await this.request<Item>('patch', `/items/${item.id}`, {
      ...item,
      parameters,
    })
  }

  async deleteItem(id: Item['id']): Promise<number> {
    const { count } = await this.request<{ count: number }>(
      'delete',
      `/items/${id}`
    )
    return count
  }

  async fetchItems({
    pageSize,
    sandbox,
  }: { pageSize?: number; sandbox?: boolean } = {}): Promise<{
    results: Item[]
    total: number
  }> {
    const { results, total } = await this.request<PageResponse<Item>>(
      'get',
      '/items',
      {
        pageSize,
        sandbox,
      }
    )

    return {
      results,
      total,
    }
  }

  async fetchItem(id: Item['id']): Promise<Item> {
    return this.request<Item>('get', `/items/${id}`)
  }
}
