import { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { locations } from '../../modules/routing/locations'
import { HomePage } from '../HomePage'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { LoadingPage } from '../LoadingPage'
import { InsightsPage } from '../InsightsPage'
import { Props } from './Routes.types'
import { LogoutPage } from '../LogoutPage'
import { Button } from '@pluggyai/ui'
import { parseLoginErrorI18nKey } from './utils'

const LOGIN_ERROR_RELOAD_PAGE_TIME_SECONDS = 5

const Routes = ({
  onLoginRequest,
  error,
  loading,
  onNavigateReplace,
  onLogout,
}: Props) => {
  const { isLoading: auth0IsLoading, error: auth0Error, user } = useAuth0()

  // dispatch initial API login request once after Application mounted
  // and when Auth0 login has been completed
  useEffect(() => {
    if (auth0IsLoading || auth0Error || !user) {
      // auth0 still loading, or resulted in error, or not yet started login/logged in
      return
    }
    // auth0 logged in
    // dispatch initial 'login' request (retrieve pluggy-api key)
    onLoginRequest()
  }, [auth0IsLoading, auth0Error, user, onLoginRequest])

  // Set timer to reload page in case the Auth0 login flow finished with an error
  useEffect(() => {
    if (!auth0Error) {
      return
    }

    const reloadTimeout = window.setTimeout(() => {
      onNavigateReplace(locations.root())
    }, LOGIN_ERROR_RELOAD_PAGE_TIME_SECONDS * 1000)

    return () => {
      clearTimeout(reloadTimeout)
    }
  }, [auth0Error, onNavigateReplace])

  if (auth0IsLoading || loading) {
    // validating auth0 session, or doing login (pluggy-api) request
    return <LoadingPage />
  }

  if (auth0Error) {
    // auth0 session resulted in error
    console.error('Auth0 login error:', auth0Error)
  }

  if (error) {
    // pluggy api-key login response error
    console.error(`API login error '${error}'`, parseLoginErrorI18nKey(error))
  }

  const loginErrorMessage = error && parseLoginErrorI18nKey(error)

  return auth0IsLoading || loading ? (
    // login loading
    <LoadingPage />
  ) : auth0Error || loginErrorMessage ? (
    // login error page
    <div className={'LoginError'}>
      <p>Login failed. Error: {auth0Error?.message || loginErrorMessage}</p>
      {auth0Error && (
        <p>
          Reloading page in {LOGIN_ERROR_RELOAD_PAGE_TIME_SECONDS} seconds...
        </p>
      )}
      {error && (
        <Button secondary onClick={onLogout}>
          Logout
        </Button>
      )}
    </div>
  ) : (
    // render App
    <Switch>
      <Route
        exact
        path={locations.root()}
        component={withAuthenticationRequired(HomePage)}
      />
      <Route
        exact
        path={locations.insights()}
        component={withAuthenticationRequired(InsightsPage)}
      />
      <Route exact path={locations.logout()} component={LogoutPage} />
      <Redirect to={locations.root()} />
    </Switch>
  )
}

export default Routes
