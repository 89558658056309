import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { Item } from 'pluggy-sdk'

import { ItemAPI } from '../../lib/api/item'
import { getIsPluggyUserEmail, getPluggyApiKey } from '../login/selectors'
import {
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEMS_REQUEST,
  DELETE_ITEMS_SUCCESS,
  deleteItemFailure,
  DeleteItemRequestAction,
  deleteItemsFailure,
  DeleteItemsRequestAction,
  deleteItemsSuccess,
  deleteItemSuccess,
  FETCH_ITEM_REQUEST,
  FETCH_ITEMS_REQUEST,
  fetchItemFailure,
  FetchItemRequestAction,
  fetchItemsFailure,
  fetchItemsRequest,
  FetchItemsRequestAction,
  fetchItemsSuccess,
  fetchItemSuccess,
  POLL_ITEM_START,
  POLL_ITEM_STOP,
  sendNotificationAction,
  startPollingItem,
  StartPollingItemAction,
  stopPollingItem,
  UPDATE_ITEM_REQUEST,
  updateItemFailure,
  UpdateItemRequestAction,
  updateItemSuccess,
} from './actions'
import { fetchAccountsRequest } from '../account/actions'
import { fetchInvestmentsRequest } from '../investment/actions'
import { addNotificationAction } from '../notification/actions'
import { PageResponse } from '../../lib/api/BaseAPI'

function* handleUpdateItemRequest(action: UpdateItemRequestAction) {
  const { itemId, parameters, webhookUrl } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const itemAPI = new ItemAPI(apiKey)
  try {
    const item: Item = yield call(() =>
      itemAPI.updateItem(
        {
          id: itemId,
          webhookUrl,
        },
        parameters
      )
    )
    yield put(updateItemSuccess(item))
    yield put(startPollingItem(item.id))
  } catch (error) {
    yield put(updateItemFailure(error.message))
  }
}

function* handleDeleteItemRequest(action: DeleteItemRequestAction) {
  const { item } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const itemAPI = new ItemAPI(apiKey)
  try {
    const count: number = yield call(() => itemAPI.deleteItem(item.id))
    if (count > 0) {
      yield put(
        addNotificationAction({
          level: 'succeed',
          title: 'Pronto!',
          message: 'O item foi excluído com sucesso.',
        })
      )
      yield put(deleteItemSuccess(item))
    } else {
      throw new Error(`Could not delete item ${item.id}`)
    }
  } catch (error) {
    yield put(
      addNotificationAction({
        level: 'error',
        title: 'Algo deu errado',
        message: `Não foi possível processar sua solicitação. Por favor, tente novamente mais tarde.`,
      })
    )
    yield put(deleteItemFailure(item, error.message))
  }
}

function* handleDeleteItemsRequest(action: DeleteItemsRequestAction) {
  const { items } = action.payload

  const apiKey: string = yield select(getPluggyApiKey)
  const itemAPI = new ItemAPI(apiKey)

  try {
    const successfulDeletedItems: Item[] = []
    const failedDeletedItems: Item[] = []

    yield all(
      items.map((item) =>
        itemAPI
          .deleteItem(item.id)
          .then(() => successfulDeletedItems.push(item))
          .catch(() => failedDeletedItems.push(item))
      )
    )

    if (successfulDeletedItems.length === 0) {
      throw new Error('Could not delete any item')
    }
    if (successfulDeletedItems.length === items.length) {
      // all items deleted
      yield put(
        addNotificationAction({
          level: 'succeed',
          title: 'Pronto!',
          message: 'Os itens foram excluídos com sucesso.',
        })
      )
    } else {
      //  some items deleted
      yield put(
        addNotificationAction({
          level: 'error',
          title: 'Algo deu errado',
          message: `${
            items.length - successfulDeletedItems.length
          } itens não puderam ser excluídos. Por favor, tente novamente.`,
        })
      )
    }

    yield put(deleteItemsSuccess(successfulDeletedItems))
  } catch (error) {
    yield put(
      addNotificationAction({
        level: 'error',
        title: 'Algo deu errado',
        message:
          'Não foi possível excluir nenhum item. Por favor, tente novamente.',
      })
    )
    yield put(deleteItemsFailure(items, error.message))
  }
}

function* handleDeleteItemSuccess() {
  // item deleted, re-fetch all items to update with latest data
  yield put(fetchItemsRequest())
}

function* handleDeleteItemsSuccess() {
  // items deleted, re-fetch all items to update with latest data
  yield put(fetchItemsRequest())
}

// default items pageSize is up to 100 items, as this app is not optimized to retrieve and render more than 100 items
// also to discourage clients usage of this Application instance with PluggyDemo which could have users productive data.
const FETCH_ITEMS_DEFAULT_PAGE_SIZE = 100

// allow internal usage (plugueiros) increased items pageSize of up to 500
const FETCH_ITEMS_PLUGGY_USER_PAGE_SIZE = 500

function* handleFetchItemsRequest(_: FetchItemsRequestAction) {
  const apiKey: string = yield select(getPluggyApiKey)
  const itemAPI = new ItemAPI(apiKey)
  const isPluggyUser: boolean = yield select(getIsPluggyUserEmail)

  const pageSize = isPluggyUser
    ? FETCH_ITEMS_PLUGGY_USER_PAGE_SIZE
    : FETCH_ITEMS_DEFAULT_PAGE_SIZE

  try {
    const [{ results: items, total }, { total: totalExceptSandbox }]: [
      PageResponse<Item>,
      PageResponse<Item>
    ] = yield all([
      itemAPI.fetchItems({ pageSize }),
      itemAPI.fetchItems({ sandbox: false }),
    ])

    yield put(fetchItemsSuccess(items, total, totalExceptSandbox))
  } catch (error) {
    yield put(fetchItemsFailure(error.message))
  }
}

function* handleFetchItemRequest(action: FetchItemRequestAction) {
  const { id } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const itemAPI = new ItemAPI(apiKey)
  try {
    const item: Item = yield call(() => itemAPI.fetchItem(id))
    yield put(fetchItemSuccess(item))
  } catch (error) {
    yield put(fetchItemFailure(id, error.message))
  }
}

function getPollTask(itemId: string) {
  return function* pollTask() {
    const apiKey: string = yield select(getPluggyApiKey)
    const itemAPI = new ItemAPI(apiKey)
    while (true) {
      try {
        const item: Item = yield call(() => itemAPI.fetchItem(itemId))
        yield put(fetchItemSuccess(item))
        if (item.status !== 'UPDATING') {
          yield put(stopPollingItem(itemId))
          if (item.status === 'UPDATED') {
            yield put(
              sendNotificationAction({
                level: 'success',
                title: 'Item was synced successfully',
                message: `The item <b>${item.id}</b> was correctly connected with the institution <b>${item.connector.name}</b>`,
                position: 'bc',
              })
            )
            yield put(fetchAccountsRequest(itemId))
            yield put(fetchInvestmentsRequest(itemId))
          } else {
            yield put(
              sendNotificationAction({
                level: 'warning',
                title: 'Item was not sync successfully!',
                message: `The item <b>${item.id}</b> couldn't connect with the institution <b>${item.connector.name}</b>. ${item.error?.message}`,
                position: 'bc',
              })
            )
          }
        }
        yield delay(4000)
      } catch (error) {
        console.error(error)
        yield put(fetchItemFailure(itemId, error.message))
        yield put(stopPollingItem(itemId))
      }
    }
  }
}

function* pollTaskWatcher(action: StartPollingItemAction) {
  const { itemId } = action.payload
  yield race([call(getPollTask(itemId)), take(POLL_ITEM_STOP)])
}

export function* itemSaga() {
  yield all([
    takeEvery(UPDATE_ITEM_REQUEST, handleUpdateItemRequest),
    takeEvery(DELETE_ITEM_REQUEST, handleDeleteItemRequest),
    takeEvery(DELETE_ITEM_SUCCESS, handleDeleteItemSuccess),
    takeEvery(DELETE_ITEMS_REQUEST, handleDeleteItemsRequest),
    takeEvery(DELETE_ITEMS_SUCCESS, handleDeleteItemsSuccess),
    takeEvery(FETCH_ITEM_REQUEST, handleFetchItemRequest),
    takeEvery(POLL_ITEM_START, pollTaskWatcher),
    takeLatest(FETCH_ITEMS_REQUEST, handleFetchItemsRequest),
  ])
}
