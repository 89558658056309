import { RootState } from '../reducer'
import { ConnectTokenState } from './reducer'
import { isLoadingType } from '../loading/selectors'
import { CREATE_CONNECT_TOKEN_REQUEST } from './actions'

export const getState = (state: RootState): ConnectTokenState => state.auth
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const getItemCreateConnectToken = (state: RootState): string | null =>
  getData(state).createToken

export const getItemUpdateConnectToken = (
  state: RootState,
  itemId: string
): string | null => getData(state).updateTokensByItem[itemId] || null

export const isCreateConnectTokenLoading = (state: RootState): boolean =>
  isLoadingType(getLoading(state).createToken, CREATE_CONNECT_TOKEN_REQUEST)

export const isUpdateConnectTokenLoading = (
  state: RootState,
  itemId: string
): boolean => {
  const updateTokenLoadingStateByItemId = getLoading(state).updateTokensByItem[
    itemId
  ]
  if (!updateTokenLoadingStateByItemId) {
    return false
  }
  return isLoadingType(
    updateTokenLoadingStateByItemId,
    CREATE_CONNECT_TOKEN_REQUEST
  )
}
