const PLUGGY_API_KEY_STORAGE_KEY = 'pluggy-api-key'
const APPLICATION_ID_KEY = 'pluggy-application-id'
const LOGIN_EMAIL_KEY = 'pluggy-login-email'

export function retrievePluggyApiKey(): string | null {
  return localStorage.getItem(PLUGGY_API_KEY_STORAGE_KEY)
}

export function storePluggyApiKey(pluggyApiKey: string): void {
  localStorage.setItem(PLUGGY_API_KEY_STORAGE_KEY, pluggyApiKey)
}

export function clearStoredPluggyApiKey(): void {
  localStorage.removeItem(PLUGGY_API_KEY_STORAGE_KEY)
}

export function storeApplicationId(value: string) {
  window.localStorage.setItem(APPLICATION_ID_KEY, value)
}

export function retrieveStoredApplicationId(): string | undefined {
  return window.localStorage.getItem(APPLICATION_ID_KEY) || undefined
}

export function retrieveStoredLoginEmail(): string | undefined {
  return window.localStorage.getItem(LOGIN_EMAIL_KEY) || undefined
}

export function clearStoredLoginEmail(): void {
  return window.localStorage.removeItem(LOGIN_EMAIL_KEY)
}
