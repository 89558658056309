import { takeEvery, put, call, select } from 'redux-saga/effects'
import type { AcquirerReceivable } from 'pluggy-sdk'
import { getPluggyApiKey } from '../login/selectors'
import { FETCH_ACQUIRER_RECEIVABLES_REQUEST, FetchAcquirerReceivablesRequestAction, fetchAcquirerReceivablesFailure, fetchAcquirerReceivablesSuccess } from './actions'
import { AcquirerReceivableAPI } from '../../lib/api/acquirerReceivable'


function* handleFetchAcquirerReceivableRequest(
  action: FetchAcquirerReceivablesRequestAction
) {
  const { itemId } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const acquirerReceivableAPI = new AcquirerReceivableAPI(apiKey)
  try {
    const acquirerReceivables: AcquirerReceivable[] = yield call(() =>
    acquirerReceivableAPI.fetchAcquirerReceivables(itemId)
    )

    yield put(fetchAcquirerReceivablesSuccess(acquirerReceivables, itemId))
  } catch (error) {
    yield put(fetchAcquirerReceivablesFailure(error.message, itemId))
  }
}

export function* acquirerReceivableSaga() {
  yield takeEvery(FETCH_ACQUIRER_RECEIVABLES_REQUEST, handleFetchAcquirerReceivableRequest)
}
