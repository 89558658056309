import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import { history, store } from './modules/store'
import { Routes } from './components/Routes'
import { Toasts } from './components/Toasts'
import { Auth0Provider } from './components/Auth0Provider'
import { locations } from './modules/routing/locations'

import './themes'
import './index.css'

const {
  REACT_APP_AUTH0_DOMAIN = '',
  REACT_APP_AUTH0_CLIENTID = '',
  REACT_APP_AUTH0_API_AUDIENCE = '',
} = process.env

if (
  !REACT_APP_AUTH0_DOMAIN ||
  !REACT_APP_AUTH0_CLIENTID ||
  !REACT_APP_AUTH0_API_AUDIENCE
) {
  throw new Error(
    'Missing REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENTID, and/or REACT_APP_AUTH0_API_AUDIENCE environment variables.'
  )
}

const component = (
  <Provider store={store}>
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENTID}
      authorizationParams={{
        audience: REACT_APP_AUTH0_API_AUDIENCE,
        redirect_uri: new URL(locations.root(), document.baseURI).href,
      }}
    >
      <Toasts />
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Auth0Provider>
  </Provider>
)

ReactDOM.render(component, document.getElementById('root'))
