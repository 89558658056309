import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Button } from '@pluggyai/ui'
import { Item } from 'pluggy-sdk'
import {
  ConnectEventPayload,
  InstanceType,
  PluggyConnect,
  PluggyConnectProps,
} from 'react-pluggy-connect'
import { Popup } from 'semantic-ui-react'

import { ArrowIcon } from '../Icon'
import { getConnectorLetter } from '../Item/utils'
import {
  CONNECTION_STEP,
  isExecutionError,
  mapExecutionStatusToStep,
} from './utils'
import { ItemSkeleton } from '../Item/ItemSkeleton'
import { Props } from './ItemConnecting.types'
import { usePrevious } from '../../utils/hooks'
import { ItemStatus } from '../Item/Item'

import './ItemConnecting.css'

const totalSteps = CONNECTION_STEP.FINISHED

const ItemConnecting = ({
  connectToken,
  onRemoveConnectIdFromList,
  itemCardId,
  connectWebappUrl,
  isItemsCreateLimitExceeded,
  onFetchItems,
}: Props) => {
  const [item, setItem] = useState<Item>()
  const isConnectHiddenRef = useRef(false)

  const innerConnectRef = useRef<InstanceType | null>(null)

  const handleConnectEvent = useCallback((payload: ConnectEventPayload) => {
    if (payload.event === 'ITEM_RESPONSE') {
      setItem(payload.item)
    }
  }, [])

  const handleConnectButtonClick = useCallback(() => {
    innerConnectRef.current?.show()
    isConnectHiddenRef.current = false
  }, [])

  const pluggyConnectWidgetProps: PluggyConnectProps = {
    url: connectWebappUrl,
    allowConnectInBackground: true,
    connectToken: connectToken || '',
    includeSandbox: true,
    // only display Sandbox connectors when items create limit is exceeded
    connectorIds: isItemsCreateLimitExceeded ? [] : undefined,
    innerRef: (instance) => {
      // get current inner ref
      innerConnectRef.current = instance
    },
    onError: (error) => {
      console.error('Whoops! Pluggy Connect error... ', error)

      const { item } = error.data || {}

      if (!item) {
        return
      }
      // set new data to Item
      setItem(item)
      // refresh state with the new items data and totals
      onFetchItems()

      if (isConnectHiddenRef.current) {
        onRemoveConnectIdFromList(itemCardId)
      }
    },
    onEvent: handleConnectEvent,
    onSuccess: (data) => {
      console.log('Yay! Pluggy connect success!', data)

      // refresh state with the new items data and totals
      onFetchItems()

      if (isConnectHiddenRef.current) {
        onRemoveConnectIdFromList(itemCardId)
      }
    },
    onClose: () => {
      onRemoveConnectIdFromList(itemCardId)
    },
    onHide: () => {
      isConnectHiddenRef.current = true
    },
  }
  const [imageLoadError, setImageLoadError] = useState<boolean>()
  const handleImageLoadError = useCallback(() => {
    setImageLoadError(true)
  }, [])

  // percentage and progress bar

  const [currentStep, setCurrentStep] = useState<CONNECTION_STEP>(
    CONNECTION_STEP.LOGIN_IN_PROGRESS
  )
  const [connectionStartTime, setConnectionStartTime] = useState<number>(0)

  useEffect(() => {
    if (currentStep !== CONNECTION_STEP.LOGIN_IN_PROGRESS) {
      return
    }
    // if connection is starting -> save start time
    setConnectionStartTime(Date.now())
  }, [currentStep])

  const [progress, setProgress] = useState(0)
  // interval timer to advance progress bar over time while waiting for next step
  const intervalRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (!item) {
      return
    }
    const connectionStep = mapExecutionStatusToStep(item)

    if (isExecutionError(connectionStep)) {
      return
    }
    if (connectionStep === currentStep || connectionStep === 'ERROR') {
      // don't set the same step again
      return
    }

    setCurrentStep(connectionStep)
  }, [item, currentStep])

  const previousStep = usePrevious(currentStep)
  useEffect(() => {
    const isInProgress = currentStep >= 0
    if (!isInProgress) {
      // not started, or already finished -> clear interval
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
      return
    }
    if (previousStep === currentStep) {
      // is still same step, do nothing
      return
    }

    if (intervalRef.current) {
      // current step updated -> refresh interval
      clearInterval(intervalRef.current)
    }
    if (currentStep === totalSteps) {
      // done
      setProgress(100)
      return
    }

    // progress started -> init interval
    intervalRef.current = setInterval(() => {
      const stepProgress = 1 / totalSteps
      const currentStepProgress = currentStep * stepProgress

      const currentElapsedTime = Date.now() - connectionStartTime

      const currentElapsedSeconds = currentElapsedTime / 1000
      // function to add decrementally-gradual progress, capped at the next step progress value
      const timeProgress =
        stepProgress * (1 / totalSteps - 1 / currentElapsedSeconds)

      const activeProgress = currentStepProgress + timeProgress
      const activeProgressPercent = Math.floor(activeProgress * 100)
      if (activeProgressPercent < progress) {
        // got a new active progress *less* than the last one, this can happen if steps complete in different order.
        // for now, we just ignore it
        // TODO rework step to show progress to actual number of steps completed VS connector expected ones.
        return
      }
      setProgress((prevProgress) =>
        Math.max(prevProgress, activeProgressPercent)
      )
    }, 500)
  }, [connectionStartTime, currentStep, previousStep, progress])

  return (
    <>
      <PluggyConnect {...pluggyConnectWidgetProps} />
      {item ? (
        <div className={'ItemConnecting'}>
          {progress &&
            item.status !== 'LOGIN_ERROR' &&
            !isExecutionError(item.executionStatus) && (
              <div
                className={'progress-bar'}
                style={{ width: `${progress}%` }}
              />
            )}
          <div className={'header'}>
            <div className={'item-connecting-header'}>
              <div className={'connector-logo'}>
                {!imageLoadError ? (
                  <img
                    alt={`${item.connector.name} imagem`}
                    src={item.connector.imageUrl}
                    onError={handleImageLoadError}
                  />
                ) : (
                  getConnectorLetter(item.connector)
                )}
              </div>
              <Popup
                content={item.id}
                hoverable={true}
                trigger={
                  <div className={'ellipsis item-connector-name'}>
                    {item.connector.name}
                  </div>
                }
                wide="very"
              />
            </div>
            <div className={'status-tag-container'}>
              <ItemStatus item={item} />
            </div>
          </div>
          <div className={'line'} />
          <div className={'card-footer'}>
            <div className={'connectiong-percentage'}>
              {progress &&
                item.status !== 'LOGIN_ERROR' &&
                !isExecutionError(item.executionStatus) &&
                `${progress}%`}
            </div>
            <Button link onClick={handleConnectButtonClick}>
              Open to see connection progress <ArrowIcon />
            </Button>
          </div>
        </div>
      ) : (
        <ItemSkeleton />
      )}
    </>
  )
}

export default React.memo(ItemConnecting)
