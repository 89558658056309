import { action } from 'typesafe-actions'

import type { Benefit } from 'pluggy-sdk'

/// Fetch benefits
export const FETCH_BENEFITS_REQUEST = '[Request] Fetch benefits'
export const FETCH_BENEFITS_SUCCESS = '[Success] Fetch benefits'
export const FETCH_BENEFITS_FAILURE = '[Failure] Fetch benefits'

export const fetchBenefitsRequest = (itemId: string) =>
  action(FETCH_BENEFITS_REQUEST, { itemId })

export const fetchBenefitsSuccess = (
  benefits: Benefit[],
  itemId: string
) => action(FETCH_BENEFITS_SUCCESS, { benefits, itemId })

export const fetchBenefitsFailure = (error: string, itemId: string) =>
  action(FETCH_BENEFITS_FAILURE, { error, itemId })

export type FetchBenefitsRequestAction = ReturnType<
  typeof fetchBenefitsRequest
>
export type FetchBenefitsSuccessAction = ReturnType<
  typeof fetchBenefitsSuccess
>
export type FetchBenefitsFailureAction = ReturnType<
  typeof fetchBenefitsFailure
>
