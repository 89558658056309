import { captureException } from '@sentry/react'
import axios, { AxiosError, AxiosInstance, isAxiosError } from 'axios'
import axiosBetterStacktrace from 'axios-better-stacktrace'

export class DemoAPI {
  private service: AxiosInstance | undefined

  /**
   * @param auth0AccessToken Auth0 Access Token
   */
  constructor(private auth0AccessToken: string) {}

  /**
   * Get or initialize the Axios client instance
   */
  protected getServiceInstance(): AxiosInstance {
    if (this.service === undefined) {
      this.service = axios.create({
        headers: {
          Authorization: `Bearer ${this.auth0AccessToken}`,
          'Content-Type': 'application/json',
        },
      })

      axiosBetterStacktrace(this.service, { errorMsg: 'DemoAPI' })
    }
    return this.service
  }

  async fetchPluggyApiKey(applicationId?: string): Promise<string> {
    try {
      const {
        data: { accessToken },
      } = await this.getServiceInstance().post<{
        accessToken: string
      }>('/api/pluggy-token', { applicationId })

      return accessToken
    } catch (error) {
      let errorMessage: string = error.message
      let errorI18nKey = 'error.server.unexpected_error'
      let status: number | undefined
      let code: string | undefined

      if (
        isAxiosError<{
          message: string
          i18nKey?: string
        }>(error)
      ) {
        const { request, response } = error

        ;({ code } = error)

        if (response) {
          ;({ status } = response)

          // The request was made and the server responded with a status code that falls out of the range of 2xx
          const {
            data: { i18nKey },
          } = response

          errorI18nKey =
            typeof i18nKey !== 'undefined'
              ? i18nKey
              : 'error.server.unexpected_error'

          errorMessage = `error response: ${errorMessage} - ${JSON.stringify(
            response.data
          )}`
        } else {
          // network error
          errorI18nKey = 'error.client.network_error'

          if (request) {
            // The request was made but no response was received
            errorMessage = `no response: ${errorMessage}`
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = `failed to send request: ${errorMessage}`
          }
        }
      }

      error.message = `Failed fetchPluggyApiKey(): '${errorI18nKey}' - ${errorMessage}`

      captureException(error, {
        contexts: {
          errorData: {
            error,
            errorResponse: (error as AxiosError).response,
          },
        },
        fingerprint: [errorI18nKey, error.message, String(status), code],
      })

      throw new Error(errorI18nKey)
    }
  }

  async fetchMetabaseUrl(pluggyApiKey: string): Promise<string> {
    const {
      data: { iframeUrl },
    } = await this.getServiceInstance().post('/api/metabase', undefined, {
      headers: {
        'x-api-key': pluggyApiKey,
      },
    })
    return iframeUrl
  }
}
