import { connect } from 'react-redux'

import {
  logoutRequest,
  LOGIN_REQUEST,
  loginRequest,
} from '../../modules/login/actions'

import { MapDispatch, MapDispatchProps, MapStateProps } from './Routes.types'
import Routes from './Routes'
import { RootState } from '../../modules/reducer'
import { getError, getLoading } from '../../modules/login/selectors'
import { isLoadingType } from '../../modules/loading/selectors'
import { replace } from 'connected-react-router'

const mapState = (state: RootState): MapStateProps => ({
  loading: isLoadingType(getLoading(state), LOGIN_REQUEST),
  error: getError(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onLoginRequest: () => dispatch(loginRequest()),
  onNavigateReplace: (path: string) => dispatch(replace(path)),
  onLogout: () => dispatch(logoutRequest()),
})

export default connect(mapState, mapDispatch)(Routes)
