import { takeEvery, put, call, select } from 'redux-saga/effects'

import type { Loan } from 'pluggy-sdk'

import { LoanAPI } from '../../lib/api/loan'
import { getPluggyApiKey } from '../login/selectors'
import {
  fetchLoansFailure,
  FetchLoansRequestAction,
  fetchLoansSuccess,
  FETCH_LOANS_REQUEST,
} from './actions'

function* handleFetchLoansRequest(
  action: FetchLoansRequestAction
) {
  const { itemId } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const loanAPI = new LoanAPI(apiKey)
  try {
    const loans: Loan[] = yield call(() =>
      loanAPI.fetchLoans(itemId)
    )

    yield put(fetchLoansSuccess(loans, itemId))
  } catch (error) {
    yield put(fetchLoansFailure(error.message, itemId))
  }
}

export function* loanSaga() {
  yield takeEvery(FETCH_LOANS_REQUEST, handleFetchLoansRequest)
}
