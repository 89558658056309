import type { Account, Transaction } from 'pluggy-sdk'

import { loadingReducer, LoadingState } from '../loading/reducer'

import {
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_TRANSACTIONS_REQUEST,
  FETCH_TRANSACTIONS_SUCCESS,
  FetchTransactionsFailureAction,
  FetchTransactionsRequestAction,
  FetchTransactionsSuccessAction,
} from './actions'
import {
  UPDATE_TRANSACTION_CATEGORY_SUCCESS,
  UpdateTransactionCategorySuccessAction,
} from '../category/actions'

export type TransactionState = {
  data: Record<Account['id'], Transaction[] | undefined>
  loading: Record<Account['id'], LoadingState | undefined>
  error: Record<Account['id'], string | null | undefined>
}

const INITIAL_STATE: TransactionState = {
  data: {},
  loading: {},
  error: {},
}

type TransactionReducerAction =
  | FetchTransactionsRequestAction
  | FetchTransactionsSuccessAction
  | FetchTransactionsFailureAction
  | UpdateTransactionCategorySuccessAction

export function transactionReducer(
  state = INITIAL_STATE,
  action: TransactionReducerAction
) {
  switch (action.type) {
    case FETCH_TRANSACTIONS_REQUEST: {
      const {
        payload: { accountId },
      } = action
      return {
        ...state,
        loading: {
          ...state.loading,
          [accountId]: loadingReducer(state.loading[accountId], action),
        },
      }
    }
    case FETCH_TRANSACTIONS_SUCCESS: {
      const {
        payload: { transactions, accountId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [accountId]: loadingReducer(state.loading[accountId], action),
        },
        error: {
          ...state.error,
          [accountId]: null,
        },
        data: {
          ...state.data,
          [accountId]: transactions,
        },
      }
    }
    // when user recategorized a transaction, we need to update the transaction itself
    case UPDATE_TRANSACTION_CATEGORY_SUCCESS: {
      const {
        payload: { transactionCategorized },
      } = action

      const { accountId, id } = transactionCategorized

      // update transaction on current state
      const transactionsUpdated = [...(state.data[accountId] || [])]
      const transactionIndex = transactionsUpdated.findIndex(
        (_transaction) => _transaction.id === id
      )
      if (transactionIndex <= -1) {
        // not found -> return
        return {
          ...state,
          loadingReducer: {
            ...state.loading,
            [accountId]: loadingReducer(state.loading[accountId], action),
          },
          error: {
            ...state.error,
            [accountId]: null,
          },
        }
      }

      // found -> update transaction
      transactionsUpdated[transactionIndex] = { ...transactionCategorized }

      return {
        ...state,
        loadingReducer: {
          ...state.loading,
          [accountId]: loadingReducer(state.loading[accountId], action),
        },
        error: {
          ...state.error,
          [accountId]: null,
        },
        data: {
          ...state.data,
          [accountId]: transactionsUpdated,
        },
      }
    }

    case FETCH_TRANSACTIONS_FAILURE: {
      const {
        payload: { accountId, error },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [accountId]: loadingReducer(state.loading[accountId], action),
        },
        error: {
          ...state.error,
          [accountId]: error,
        },
      }
    }
    default:
      return state
  }
}
