import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { RootState } from '../../modules/reducer'

import { MapDispatch, MapDispatchProps, MapStateProps } from './HomePage.types'
import HomePage from './HomePage'
import { isLoggedIn } from '../../modules/login/selectors'

const mapState = (state: RootState): MapStateProps => ({
  notifications: state.notifications,
  isLoggedIn: isLoggedIn(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onNavigate: (path: string) => dispatch(push(path)),
})

export default connect(mapState, mapDispatch)(HomePage)
