import type { IdentityResponse as Identity } from 'pluggy-sdk'
import { action } from 'typesafe-actions'

// Fetch identity by ItemId
export const FETCH_IDENTITY_BY_ITEMID_REQUEST =
  '[Request] Fetch identity by ItemId'
export const FETCH_IDENTITY_BY_ITEMID_SUCCESS =
  '[Success] Fetch identity by ItemId'
export const FETCH_IDENTITY_BY_ITEMID_FAILURE =
  '[Failure] Fetch identity by ItemId'

export const fetchIdentityByItemIdRequest = (itemId: string) =>
  action(FETCH_IDENTITY_BY_ITEMID_REQUEST, { itemId })

export const fetchIdentityByItemIdSuccess = (
  identity: Identity,
  itemId: string
) => action(FETCH_IDENTITY_BY_ITEMID_SUCCESS, { identity, itemId })

export const fetchIdentityByItemIdFailure = (error: string, itemId: string) =>
  action(FETCH_IDENTITY_BY_ITEMID_FAILURE, { error, itemId })

export type FetchIdentityByItemIdRequestAction = ReturnType<
  typeof fetchIdentityByItemIdRequest
>
export type FetchIdentityByItemIdSuccessAction = ReturnType<
  typeof fetchIdentityByItemIdSuccess
>
export type FetchIdentityByItemIdFailureAction = ReturnType<
  typeof fetchIdentityByItemIdFailure
>
