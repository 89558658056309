import React, { useEffect } from 'react'

import { Props } from './LogoutPage.types'

const LogoutPage = ({ onLogout }: Props) => {
  useEffect(() => {
    onLogout()
  })

  return (
    <div>
      <p>Logging out...</p>
    </div>
  )
}

export default React.memo(LogoutPage)
