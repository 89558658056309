import { AnyAction } from 'redux'

import type { Account } from 'pluggy-sdk'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { AccountState } from './reducer'

export const getState = (state: RootState): AccountState => state.account
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type
) => state.some((action: AnyAction) => action.type === type)

export const getAccountsByItemId = (
  state: RootState,
  itemId: string
): Record<string, Account | undefined> | null => getData(state)[itemId] || null

export const getAccountsLoadingByItemId = (
  state: RootState,
  itemId: string
): LoadingState => getLoading(state)[itemId] || []

export const getAccountsErrorByItemId = (
  state: RootState,
  itemId: string
): string | null => getError(state)[itemId] || null
