import { action } from 'typesafe-actions'
import { Application } from './types'

// Fetch application by id
export const FETCH_APPLICATION_REQUEST = '[Request] Fetch Application'
export const FETCH_APPLICATION_SUCCESS = '[Success] Fetch Application'
export const FETCH_APPLICATION_FAILURE = '[Failure] Fetch Application'

export const fetchApplicationRequest = () => action(FETCH_APPLICATION_REQUEST)

export const fetchApplicationSuccess = (application: Application) =>
  action(FETCH_APPLICATION_SUCCESS, { application })

export const fetchApplicationFailure = (error: string) =>
  action(FETCH_APPLICATION_FAILURE, { error })

export type FetchApplicationRequestAction = ReturnType<
  typeof fetchApplicationRequest
>
export type FetchApplicationSuccessAction = ReturnType<
  typeof fetchApplicationSuccess
>
export type FetchApplicationFailureAction = ReturnType<
  typeof fetchApplicationFailure
>
