import { takeEvery, put, call, select } from 'redux-saga/effects'

import type { Transaction } from 'pluggy-sdk'

import { TransactionAPI } from '../../lib/api/transaction'
import { getPluggyApiKey } from '../login/selectors'
import {
  FetchTransactionsRequestAction,
  fetchTransactionsSuccess,
  fetchTransactionsFailure,
  FETCH_TRANSACTIONS_REQUEST,
} from './actions'

export function* transactionSaga() {
  yield takeEvery(FETCH_TRANSACTIONS_REQUEST, handleFetchTransactionsRequest)
}

function* handleFetchTransactionsRequest(
  action: FetchTransactionsRequestAction
) {
  const {
    payload: { accountId },
  } = action
  const apiKey: string = yield select(getPluggyApiKey)
  const transactionAPI = new TransactionAPI(apiKey)

  try {
    const transactions: Transaction[] = yield call(() =>
      transactionAPI.fetchAll(accountId)
    )
    yield put(fetchTransactionsSuccess(transactions, accountId))
  } catch (error) {
    yield put(fetchTransactionsFailure(error.message, accountId))
  }
}
