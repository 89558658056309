import type { Item, Investment } from 'pluggy-sdk'

import { BaseAPI, PageResponse } from './BaseAPI'

export class InvestmentAPI extends BaseAPI {
  async fetchAll(itemId: Item['id']): Promise<Investment[]> {
    const { results } = await this.request<PageResponse<Investment>>(
      'get',
      `/investments?itemId=${itemId}`
    )
    return results
  }
}
