import type { Loan, Item } from 'pluggy-sdk'

import { BaseAPI, PageResponse } from './BaseAPI'

export class LoanAPI extends BaseAPI {
  async fetchLoans(itemId: Item['id']): Promise<Loan[]> {
    const { results } = await this.request<PageResponse<Loan>>(
      'get',
      `/loans?itemId=${itemId}`
    )
    return results
  }
}
