import React from 'react'
import { Container } from 'semantic-ui-react'
import { Props } from './Page.types'
import { Navbar } from '../Navbar'

import './Page.css'

const Page = (props: Props) => {
  const {
    className,
    children,
    isLoggingIn,
    isLoggingOut,
    withNabvar = true,
    fullscreen,
  } = props

  const classNames = ['Page']
  if (className) {
    classNames.push(className)
  }
  if (isLoggingOut) {
    classNames.push('logging-out')
  }
  if (isLoggingIn) {
    classNames.push('logging-in')
  }
  if (fullscreen) {
    classNames.push('fullscreen')
  }
  if (withNabvar) {
    classNames.push('with-navbar')
  }

  return (
    <>
      {withNabvar && <Navbar />}
      <Container className={classNames.join(' ')}>{children}</Container>
    </>
  )
}

export default React.memo(Page)
