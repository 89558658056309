import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from '@pluggyai/ui'

import { locations } from '../../modules/routing/locations'
import { NavbarItem } from './NavbarItem'
import { Props } from './Navbar.types'

import './Navbar.css'

const Navbar = ({ onLogout, isLoggingOut, isLoggingIn }: Props) => (
  <nav className={`Navbar ${isLoggingIn ? 'logging-in' : ''}`}>
    <Link to={locations.root()} className={'logo'}>
      <img src="../logo-full.png" alt="Pluggy logo" width="128" />
    </Link>
    <div className={'items-group'}>
      <NavbarItem href={locations.root()}>Overview</NavbarItem>
      <NavbarItem href={'https://meu.pluggy.ai'}>
        Insights by MeuPluggy
      </NavbarItem>
    </div>
    <div className={'action-group'}>
      <Button
        className={'logout-button'}
        onClick={onLogout}
        disabled={isLoggingOut || isLoggingIn}
        text
      >
        Sair
      </Button>
    </div>
  </nav>
)

export default React.memo(Navbar)
