/**
 * return the first letter capitalized found
 * @param value
 * @returns - letter
 */
export function getFirstCapitalizedLetter(value: string): string {
  for (let i = 0; i < value.length; i++) {
    const letter = value.charAt(i)
    if (letter !== ' ' && letter === letter.toUpperCase()) {
      return letter
    }
  }

  return value[0]
}
