import type { AcquirerReceivable, Item } from 'pluggy-sdk'

import { loadingReducer, LoadingState } from '../loading/reducer'
import { FETCH_ACQUIRER_RECEIVABLES_FAILURE, FETCH_ACQUIRER_RECEIVABLES_REQUEST, FETCH_ACQUIRER_RECEIVABLES_SUCCESS, FetchAcquirerReceivablesFailureAction, FetchAcquirerReceivablesRequestAction, FetchLAcquirerReceivablesSuccessAction } from './actions'

export type AcquirerReceivableState = {
  data: Record<Item['id'], AcquirerReceivable[] | undefined>
  loading: Record<Item['id'], LoadingState | undefined>
  error: Record<Item['id'], string | null | undefined>
}

const INITIAL_STATE: AcquirerReceivableState = {
  data: {},
  loading: {},
  error: {},
}

type AcquirerReceivableReducer =
  | FetchAcquirerReceivablesRequestAction
  | FetchAcquirerReceivablesFailureAction
  | FetchLAcquirerReceivablesSuccessAction

export function acquirerReceivableReducer(state = INITIAL_STATE, action: AcquirerReceivableReducer): AcquirerReceivableState {
  switch (action.type) {
    case FETCH_ACQUIRER_RECEIVABLES_REQUEST: {
      const {
        payload: { itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
      }
    }
    case FETCH_ACQUIRER_RECEIVABLES_SUCCESS: {
      const {
        payload: { acquirerReceivables, itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: null,
        },
        data: {
          ...state.data,
          [itemId]: acquirerReceivables,
        },
      }
    }
    case FETCH_ACQUIRER_RECEIVABLES_FAILURE: {
      const {
        payload: { error, itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: error,
        },
      }
    }
    default:
      return state
  }
}
