import { AnyAction } from 'redux'

import type { Benefit } from 'pluggy-sdk'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'

export const getState = (state: RootState) => state.benefit
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type
) => state.some((action: AnyAction) => action.type === type)

export const getBenefitsByItemId = (
  state: RootState,
  itemId: string
): Record<string, Benefit | undefined> | null =>
  getData(state)[itemId] || null

export const getBenefitsLoadingByItemId = (
  state: RootState,
  itemId: string
): LoadingState => getLoading(state)[itemId] || []

export const getBenefitsErrorByItemId = (
  state: RootState,
  itemId: string
): string | null => {
  const benefitsErrorByItemId = getError(state)
  const benefitsError = benefitsErrorByItemId[itemId]
  return benefitsError || null
}
