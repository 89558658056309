import React from 'react'

import { Grid } from 'semantic-ui-react'

import './ProductSkeleton.css'

const ProductSkeleton = ({ className }: { className?: string }) => (
  <div className={`ProductSkeleton ${className || ''}`}>
    <div className={'skeleton title'} />
    <Grid className={'skeleton-grid'} columns={3}>
      <Grid.Row>
        <Grid.Column className={'product-info-container'}>
          <div className={'skeleton image'} />
          <div className={'text-container'}>
            <div className={'skeleton text title'} />
            <div className={'skeleton text'} />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className={'skeleton text title'} />
          <div className={'skeleton text'} />
        </Grid.Column>
        <Grid.Column>
          <div className={'skeleton text show-more'} />
        </Grid.Column>
      </Grid.Row>
      <div className={'divider'} />
      <Grid.Row>
        <Grid.Column className={'product-info-container'}>
          <div className={'skeleton image'} />
          <div className={'text-container'}>
            <div className={'skeleton text title'} />
            <div className={'skeleton text'} />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className={'skeleton text title'} />
          <div className={'skeleton text'} />
        </Grid.Column>
        <Grid.Column>
          <div className={'skeleton text show-more'} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
)

export default React.memo(ProductSkeleton)
