import { takeEvery, put, call, select } from 'redux-saga/effects'
import type { AcquirerSale } from 'pluggy-sdk'
import { getPluggyApiKey } from '../login/selectors'
import { FETCH_ACQUIRER_SALES_REQUEST, FetchAcquirerSalesRequestAction, fetchAcquirerSalesFailure, fetchAcquirerSalesSuccess } from './actions'
import { AcquirerSaleAPI } from '../../lib/api/acquirerSale'


function* handleFetchAcquirerSalesRequest(
  action: FetchAcquirerSalesRequestAction
) {
  const { itemId } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const acquirerSaleAPI = new AcquirerSaleAPI(apiKey)
  try {
    const acquirerSales: AcquirerSale[] = yield call(() =>
      acquirerSaleAPI.fetchAcquirerSales(itemId)
    )

    yield put(fetchAcquirerSalesSuccess(acquirerSales, itemId))
  } catch (error) {
    yield put(fetchAcquirerSalesFailure(error.message, itemId))
  }
}

export function* acquirerSaleSaga() {
  yield takeEvery(FETCH_ACQUIRER_SALES_REQUEST, handleFetchAcquirerSalesRequest)
}
