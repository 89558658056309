import { takeEvery, put, call, select } from 'redux-saga/effects'
import type { AcquirerAnticipation } from 'pluggy-sdk'
import { getPluggyApiKey } from '../login/selectors'
import { FETCH_ACQUIRER_ANTICIPATIONS_REQUEST, FetchAcquirerAnticipationsRequestAction, fetchAcquirerAnticipationsFailure, fetchAcquirerAnticipationsSuccess } from './actions'
import { AcquirerAnticipationAPI } from '../../lib/api/acquirerAnticipation'


function* handleFetchAcquirerAnticipationsRequest(
  action: FetchAcquirerAnticipationsRequestAction
) {
  const { itemId } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const acquirerAnticipationAPI = new AcquirerAnticipationAPI(apiKey)
  try {
    const acquirerAnticipations: AcquirerAnticipation[] = yield call(() =>
      acquirerAnticipationAPI.fetchAcquirerAnticipations(itemId)
    )

    yield put(fetchAcquirerAnticipationsSuccess(acquirerAnticipations, itemId))
  } catch (error) {
    yield put(fetchAcquirerAnticipationsFailure(error.message, itemId))
  }
}

export function* acquirerAnticipationSaga() {
  yield takeEvery(FETCH_ACQUIRER_ANTICIPATIONS_REQUEST, handleFetchAcquirerAnticipationsRequest)
}
