import { takeEvery, put, call, select } from 'redux-saga/effects'

import type { Benefit } from 'pluggy-sdk'

import { getPluggyApiKey } from '../login/selectors'
import {
  fetchBenefitsFailure,
  FetchBenefitsRequestAction,
  fetchBenefitsSuccess,
  FETCH_BENEFITS_REQUEST,
} from './actions'
import { BenefitAPI } from '../../lib/api/benefit'

function* handleFetchBenefitsRequest(
  action: FetchBenefitsRequestAction
) {
  const { itemId } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const benefitAPI = new BenefitAPI(apiKey)
  try {
    const benefits: Benefit[] = yield call(() =>
      benefitAPI.fetchBenefits(itemId)
    )

    yield put(fetchBenefitsSuccess(benefits, itemId))
  } catch (error) {
    yield put(fetchBenefitsFailure(error.message, itemId))
  }
}

export function* benefitSaga() {
  yield takeEvery(FETCH_BENEFITS_REQUEST, handleFetchBenefitsRequest)
}
