import React from 'react'
import { Header } from 'semantic-ui-react'
import { Section } from '../Section'
import { Props } from './MainSection.types'

import './MainSection.css'

const MainSection = (props: Props) => {
  const { title, subtitle } = props

  return (
    <Section className="MainSection">
      {<Header as="h1">{title}</Header>}
      {subtitle ? <p className="subtitle">{subtitle}</p> : null}
    </Section>
  )
}

export default React.memo(MainSection)
