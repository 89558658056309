import { TrackEventName } from './events'
import { retrieveStoredApplicationId } from '../login/storage'

const { REACT_APP_SEGMENT_API_KEY: analyticsApiKey = '' } = process.env

/**
 * Set analytics
 *
 * @returns - the analytics object that it's inside window
 */
function getAnalytics(): SegmentAnalytics.AnalyticsJS {
  const { analytics } = window
  if (typeof analytics === 'undefined') {
    throw new Error(
      'Analytics not enabled because `window.analytics` is not present'
    )
  }
  if (!analyticsApiKey) {
    throw new Error('Analytics not enabled due to missing API key.')
  }
  return analytics
}

/**
 * Combine event-specified properties with the current context data
 *
 * @param properties
 */
function combineEventPropertiesWithContext(
  properties?: Record<string, unknown>
): Record<string, unknown> | undefined {
  const context = {
    applicationId: retrieveStoredApplicationId(),
    baseUrl: `https://${window.location.hostname}/`,
  }

  return {
    ...(properties || {}),
    ...context,
  }
}

/**
 * Send 'track' call to analytics.
 *
 * @param name - the name of the event to track
 * @param properties - extra pieces of information of the events to track (optional)
 * @param options - options to pass to Segment (optional)
 * @param callback - function to call when is done, only if we want to use it async (optional)
 */
export function track(
  name: TrackEventName,
  properties?: Record<string, unknown>,
  options?: Record<string, unknown>,
  callback?: () => void
): void {
  try {
    const analytics = getAnalytics()
    if (typeof analytics.track !== 'function') {
      callback?.()
      return
    }

    analytics.track(
      name,
      combineEventPropertiesWithContext(properties),
      options,
      callback
    )
  } catch (error) {
    // probably, analytics not enabled, or other issue sending the report
    // ignore this error - if not enabled it was already logged once
    callback?.()
  }
}

/**
 * Send 'page' call to analytics.
 *
 * @param name - the name of the event to track
 * @param properties - parameters to pass as part of the track / page call
 * @param options - options to pass to Segment
 *
 */
export function page(
  name?: string,
  properties?: Record<string, unknown>,
  options?: Record<string, unknown>
): void {
  try {
    getAnalytics().page(
      name,
      combineEventPropertiesWithContext(properties),
      options
    )
  } catch (error) {
    // probably, analytics not enabled, or other issue sending the report
    // ignore this error - if not enabled it was already logged once
  }
}

/**
 * Send 'identify' call to analytics.
 * Used to tie an user and their actions to a recognizable userId and traits
 *
 * @param userId - the id for the user to identify
 * @param traits - user-related data, such as name, email etc.
 * @param options - options to pass to Segment
 *
 */
export function identify(
  userId: string,
  traits?: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts
): void {
  try {
    getAnalytics().identify(userId, traits, options)
  } catch (error) {
    // probably, analytics not enabled, or other issue sending the report
    // ignore this error - if not enabled it was already logged once
  }
}

/**
 * Initial analytics load() call - should be called as early as possible.
 * in this case we call it in store.ts
 */
export function initAnalytics(): void {
  if (!analyticsApiKey) {
    console.warn('Analytics not enabled due to missing API key')
    return
  }
  let analytics: SegmentAnalytics.AnalyticsJS
  try {
    analytics = getAnalytics()
  } catch (error) {
    console.warn(error.message)
    return
  }
  analytics.load(analyticsApiKey)
}
