import type { Item, Loan } from 'pluggy-sdk'

import {
  FETCH_LOANS_FAILURE,
  FETCH_LOANS_REQUEST,
  FETCH_LOANS_SUCCESS,
  FetchLoansFailureAction,
  FetchLoansRequestAction,
  FetchLoansSuccessAction,
} from './actions'
import { loadingReducer, LoadingState } from '../loading/reducer'

export type LoanState = {
  data: Record<Item['id'], Record<Loan['id'], Loan | undefined>>
  loading: Record<Item['id'], LoadingState | undefined>
  error: Record<Item['id'], string | null | undefined>
}

const INITIAL_STATE: LoanState = {
  data: {},
  loading: {},
  error: {},
}

type LoanReducer =
  | FetchLoansRequestAction
  | FetchLoansFailureAction
  | FetchLoansSuccessAction

export function loanReducer(state = INITIAL_STATE, action: LoanReducer) {
  switch (action.type) {
    case FETCH_LOANS_REQUEST: {
      const {
        payload: { itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
      }
    }
    case FETCH_LOANS_SUCCESS: {
      const {
        payload: { loans, itemId },
      } = action

      // group loans by id
      const loansById: Record<string, Loan> = {}

      for (const loan of loans) {
        loansById[loan.id] = loan
      }

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: null,
        },
        data: {
          ...state.data,
          [itemId]: loansById,
        },
      }
    }
    case FETCH_LOANS_FAILURE: {
      const {
        payload: { error, itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: error,
        },
      }
    }
    default:
      return state
  }
}
