import { loadingReducer, LoadingState } from '../loading/reducer'
import {
  CREATE_CONNECT_TOKEN_FAILURE,
  CREATE_CONNECT_TOKEN_REQUEST,
  CREATE_CONNECT_TOKEN_SUCCESS,
  CreateConnectTokenFailureAction,
  CreateConnectTokenRequestAction,
  CreateConnectTokenSuccessAction,
} from './actions'

export type ConnectTokenState = {
  data: {
    createToken: string | null
    updateTokensByItem: Record<string, string | undefined>
  }
  loading: {
    createToken: LoadingState
    updateTokensByItem: Record<string, LoadingState | undefined>
  }
  error: {
    createToken: Error | null
    updateTokensByItem: Record<string, Error | undefined>
  }
}

const INITIAL_STATE: ConnectTokenState = {
  data: {
    createToken: null,
    updateTokensByItem: {},
  },
  loading: {
    createToken: [],
    updateTokensByItem: {},
  },
  error: {
    createToken: null,
    updateTokensByItem: {},
  },
}

type ConnectTokenReducerAction =
  | CreateConnectTokenRequestAction
  | CreateConnectTokenSuccessAction
  | CreateConnectTokenFailureAction

export function connectTokenReducer(
  state = INITIAL_STATE,
  action: ConnectTokenReducerAction
) {
  switch (action.type) {
    case CREATE_CONNECT_TOKEN_REQUEST: {
      const { itemId } = action.payload
      return {
        ...state,
        loading: {
          ...state.loading,
          ...(itemId
            ? {
                updateTokensByItem: {
                  ...state.loading.updateTokensByItem,
                  [itemId]: loadingReducer(
                    state.loading.updateTokensByItem[itemId],
                    action
                  ),
                },
              }
            : {
                createToken: loadingReducer(state.loading.createToken, action),
              }),
        },
        error: {
          ...state.error,
          ...(itemId
            ? {
                updateTokensByItem: {
                  ...state.error.updateTokensByItem,
                  [itemId]: null,
                },
              }
            : { createToken: null }),
        },
      }
    }
    case CREATE_CONNECT_TOKEN_SUCCESS: {
      const { token, itemId } = action.payload

      if (!itemId) {
        // no itemId -> just a regular create Connect Token
        return {
          ...state,
          loading: {
            ...state.loading,
            createToken: loadingReducer(state.loading.createToken, action),
          },
          data: {
            ...state.data,
            createToken: token,
          },
        }
      }

      // has itemId present -> an update Connect Token
      return {
        ...state,
        loading: {
          ...state.loading,
          updateTokensByItem: {
            ...state.loading.updateTokensByItem,
            [itemId]: loadingReducer(
              state.loading.updateTokensByItem[itemId],
              action
            ),
          },
        },
        data: {
          ...state.data,
          updateTokensByItem: {
            ...state.data.updateTokensByItem,
            [itemId]: token,
          },
        },
      }
    }
    case CREATE_CONNECT_TOKEN_FAILURE: {
      const { error, itemId } = action.payload

      return {
        ...state,
        loading: {
          ...state.loading,
          ...(itemId
            ? {
                updateTokensByItem: {
                  ...state.loading.updateTokensByItem,
                  [itemId]: loadingReducer(
                    state.loading.updateTokensByItem[itemId],
                    action
                  ),
                },
              }
            : {
                createToken: loadingReducer(state.loading.createToken, action),
              }),
        },
        error: {
          ...state.error,
          ...(itemId
            ? {
                updateTokensByItem: {
                  ...state.error.updateTokensByItem,
                  [itemId]: error,
                },
              }
            : {
                createToken: error,
              }),
        },
      }
    }
    default:
      return state
  }
}
