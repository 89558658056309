import { AnyAction } from 'redux'

import type { Loan } from 'pluggy-sdk'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'

export const getState = (state: RootState) => state.loan
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type
) => state.some((action: AnyAction) => action.type === type)

export const getLoansByItemId = (
  state: RootState,
  itemId: string
): Record<string, Loan | undefined> | null =>
  getData(state)[itemId] || null

export const getLoansLoadingByItemId = (
  state: RootState,
  itemId: string
): LoadingState => getLoading(state)[itemId] || []

export const getLoansErrorByItemId = (
  state: RootState,
  itemId: string
): string | null => {
  const loansErrorByItemId = getError(state)
  const loansError = loansErrorByItemId[itemId]
  return loansError || null
}
