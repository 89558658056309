import type { Connector, Item } from 'pluggy-sdk'

import { getFirstCapitalizedLetter } from '../../utils/string'

export function getUpdateActionAndText(
  item: Item,
  onOpenConnectWidgetUpdate: () => void,
  onUpdate: () => void
): { text: string; onUpdateHandler: () => void } | null {
  const {
    status: itemStatus,
    executionStatus: itemExecutionStatus,
    connector: { hasMFA: itemHasMFA, oauth: itemIsOauthFlow },
  } = item

  /***
   * item is updatable when:
   *  - already updated successfully -> update directly (unless requires MFA, in that case provide it)
   *  - was outdated (note: if it's due to INVALID_CREDENTIALS_MFA requires providing input)
   *  - an user input (mfa) is expected (requires providing it)
   *  - item.connector.oauth: true and/or oauth flow is in progress
   *  - new credentials are needed (requires providing them)
   */
  if (
    !(
      itemStatus === 'UPDATED' ||
      itemStatus === 'OUTDATED' ||
      itemStatus === 'WAITING_USER_INPUT' ||
      itemStatus === 'WAITING_USER_ACTION' ||
      itemStatus === 'LOGIN_ERROR'
    )
  ) {
    return null
  }

  /**
   * item needs more input (show Modal) when:
   *  full credentials:
   *  - credentials failed - itemStatus == LOGIN_ERROR
   *
   *  mfa only:
   *  - is WAITING_USER_INPUT state
   *  - was OUTDATED (execution failed) due to INVALID_CREDENTIALS_MFA (can reuse credentials).
   */
  const needsMfaInput =
    itemHasMFA ||
    itemStatus === 'WAITING_USER_INPUT' ||
    (itemStatus === 'OUTDATED' &&
      itemExecutionStatus === 'INVALID_CREDENTIALS_MFA')

  const needsAllCredentialsInput = itemStatus === 'LOGIN_ERROR'

  /**
   * NO need more input when:
   *  - is OUTDATED and not due to bad MFA (ie. connector had an error)
   */
  const canReuseCredentials =
    itemStatus === 'OUTDATED' &&
    itemExecutionStatus !== 'INVALID_CREDENTIALS_MFA'

  /**
   * In case of items with connector.oauth: true,
   * we always run the Connect update flow, since we don't really know
   * if the oauth session will need to be renewed or not.
   */
  const needsOauthUpdate =
    itemIsOauthFlow || itemStatus === 'WAITING_USER_ACTION'

  if (needsOauthUpdate) {
    return {
      text: 'Atualizar',
      onUpdateHandler: onOpenConnectWidgetUpdate,
    }
  }

  if (needsMfaInput) {
    return {
      text: 'Atualizar MFA',
      onUpdateHandler: onOpenConnectWidgetUpdate,
    }
  }

  if (needsAllCredentialsInput) {
    return {
      text: 'Atualizar credenciais',
      onUpdateHandler: onOpenConnectWidgetUpdate,
    }
  }

  if (canReuseCredentials) {
    // had execution error, but can reuse credentials to retry.
    return {
      text: 'Atualizar (reutilizar credenciais)',
      onUpdateHandler: onUpdate,
    }
  }

  // item updated successfully, but can update again manually if desired
  return { text: 'Atualização manual', onUpdateHandler: onUpdate }
}

export function getConnectorLetter(connector: Connector): string {
  if (!connector) {
    // no connector just return
    return ''
  }
  const { name } = connector
  if (name.startsWith('Banco')) {
    return getFirstCapitalizedLetter(name.substring(6, name.length))
  }
  return name.toUpperCase()
}
