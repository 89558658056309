import { AnyAction } from 'redux'

import type { Investment } from 'pluggy-sdk'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { InvestmentState } from './reducer'

export const getState = (state: RootState): InvestmentState => state.investment
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type
) => state.some((action: AnyAction) => action.type === type)

export const getInvestmentsByItemId = (
  state: RootState,
  itemId: string
): Record<string, Investment | undefined> | null =>
  getData(state)[itemId] || null

export const getInvestmentsLoadingByItemId = (
  state: RootState,
  itemId: string
): LoadingState => getLoading(state)[itemId] || []

export const getInvestmentsErrorByItemId = (
  state: RootState,
  itemId: string
): string | null => getError(state)[itemId] || null
