import React, { useCallback, useEffect, useState } from 'react'

import { PluggyConnect, PluggyConnectProps } from 'react-pluggy-connect'

import { Button, Radio, Tag } from '@pluggyai/ui'
import { Confirm, Dropdown, Grid, Popup, Segment } from 'semantic-ui-react'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import pt from 'date-fns/locale/pt'
import { Item as ItemType } from 'pluggy-sdk'

import { getConnectorLetter, getUpdateActionAndText } from './utils'
import { ColumnHeader } from '../ColumnHeader'
import { ContextMenuIcon } from '../Icon'
import { Props } from './Item.types'
import { usePrevious } from '../../utils/hooks'
import { track } from '../../modules/analytics/utils'
import { TrackEventName } from '../../modules/analytics/events'

import './Item.css'

const { REACT_APP_CONNECT_WEBAPP_URL: connectWebappUrl = '' } = process.env

if (!connectWebappUrl) {
  throw new Error(
    'Must specify REACT_APP_CONNECT_WEBAPP_URL environment variable'
  )
}

export const ItemStatus = ({
  item: { error, status, executionStatus },
}: {
  item: ItemType
}) => {
  if (status === 'UPDATING') {
    // is loading
    return (
      <Popup
        content={status}
        hoverable={true}
        trigger={
          <div>
            <Tag type={'info'} text={'Conectando'} />
          </div>
        }
        wide="very"
      />
    )
  }

  if (status === 'WAITING_USER_INPUT' || status === 'WAITING_USER_ACTION') {
    return (
      <Popup
        content={'Abra o Connect widget para finalizar sua conexão'}
        hoverable={true}
        trigger={
          <div>
            <Tag type={'warning'} text={'Ação requerida'} />
          </div>
        }
        wide="very"
      />
    )
  }

  if (
    status === 'LOGIN_ERROR' ||
    executionStatus === 'INVALID_CREDENTIALS' ||
    executionStatus === 'INVALID_CREDENTIALS_MFA' ||
    executionStatus === 'USER_INPUT_TIMEOUT' ||
    executionStatus === 'USER_AUTHORIZATION_NOT_GRANTED'
  ) {
    // finished in login error
    return (
      <Popup
        content={error?.message}
        hoverable={Boolean(error?.message)}
        trigger={
          <div>
            <Tag type={'error'} text={'Erro de login'} />
          </div>
        }
        wide="very"
      />
    )
  }

  if (status === 'OUTDATED') {
    return (
      <Popup
        content={error?.message}
        hoverable={Boolean(error?.message)}
        trigger={
          <div>
            <Tag type={'warning'} text={'Desatualizado'} />
          </div>
        }
        wide="very"
      />
    )
  }

  return <Tag type={'success'} text={'Atualizada'} />
}

const Item = ({
  item,
  isConnectTokenLoading,
  current,
  connectToken,
  onDelete,
  onFetchItem,
  onUpdate,
  onFetchAccounts,
  onFetchInvestments,
  onFetchIdentityByItemId,
  onRefreshConnectToken,
  onClick,
  addNotification,
  selected,
  onSelect,
  selectMode,
}: Props) => {
  const [confirmDelete, setConfirmDelete] = useState(false)

  const {
    id: itemId,
    connector: { hasMFA: itemHasMFA },
  } = item

  const handleModalConfirm = useCallback(() => {
    setConfirmDelete(false)
    onDelete(item)
  }, [onDelete, item])

  const handleOnUpdate = useCallback(() => {
    if (itemHasMFA) {
      console.error(`Item has MFA, can't directly call onUpdate()`, { item })
      return
    }
    onUpdate(itemId)
  }, [itemHasMFA, onUpdate, itemId, item])

  const [waitingConnectToken, setWaitingConnectToken] = useState(false)
  const [isConnectVisible, setIsConnectVisible] = useState(false)

  const handleOpenConnectWidgetUpdate = useCallback(() => {
    // request a new connect token to prevent token expired error
    onRefreshConnectToken(itemId)
    // open Connect widget modal
    setWaitingConnectToken(true)
  }, [itemId, onRefreshConnectToken])

  const prevConnectToken = usePrevious(connectToken)

  // check if new connectToken has been obtained to update Item, to open the widget
  useEffect(() => {
    if (!waitingConnectToken || !connectToken) {
      // not waiting connect token, return
      return
    }
    if (connectToken !== prevConnectToken) {
      // connect token changed, stop waiting and open widget
      setWaitingConnectToken(false)
      setIsConnectVisible(true)
    }
  }, [waitingConnectToken, connectToken, prevConnectToken])

  const pluggyConnectWidgetProps: PluggyConnectProps = {
    url: connectWebappUrl,
    connectToken: connectToken || '',
    updateItem: itemId,
    onError: (error) => {
      console.error(
        `Whoops! Pluggy Connect update item id ${itemId} error... `,
        error
      )
      // refresh state with the updated item data
      onFetchItem(itemId)
    },
    onSuccess: (data) => {
      console.log(`Yay! Pluggy update item ${itemId} success!`, data)
      // refresh state with the updated item data
      onFetchItem(itemId)
      onFetchAccounts(itemId)
      onFetchInvestments(itemId)
      onFetchIdentityByItemId(itemId)
    },
    onClose: () => {
      setIsConnectVisible(false)
    },
  }

  const classNames = ['Item', 'pointer']
  if (current) {
    classNames.push('selected')
  }

  if (selected) {
    classNames.push('multi-selected')
  }

  const itemUpdateAction = getUpdateActionAndText(
    item,
    handleOpenConnectWidgetUpdate,
    handleOnUpdate
  )

  const [imageLoadError, setImageLoadError] = useState<boolean>()

  const handleImageLoadError = useCallback(() => {
    setImageLoadError(true)
  }, [])

  const handleModalCancel = useCallback(() => {
    setConfirmDelete(false)
  }, [])

  const handleDeleteOptionClick = useCallback(() => {
    setConfirmDelete(true)
  }, [])

  const handleCopyItemIdClick = useCallback(() => {
    try {
      navigator.clipboard.writeText(item.id)
      addNotification({
        title: 'Sucesso!',
        message: 'Item ID copiado para a área de transferência.',
      })
    } catch (error) {
      console.error('Failed copy to clipboard', error)
    }

    track(TrackEventName.BUTTON_CLICKED, {
      text: 'Copiar Item ID',
    })
  }, [addNotification, item.id])

  return (
    <Segment className={classNames.join(' ')} onClick={onClick}>
      {isConnectVisible && <PluggyConnect {...pluggyConnectWidgetProps} />}
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column className="item-column-name">
            {selectMode && (
              <Radio type="checkbox" checked={selected} onClick={onSelect} />
            )}

            {/* connector name + icon */}
            <div className={'connector-logo'}>
              {!imageLoadError ? (
                <img
                  alt={`${item.connector.name} imagem`}
                  src={item.connector.imageUrl}
                  onError={handleImageLoadError}
                />
              ) : (
                getConnectorLetter(item.connector)
              )}
            </div>
            <Popup
              content={itemId}
              hoverable={true}
              trigger={
                <div className={'ellipsis item-connector-name'}>
                  {item.connector.name}
                </div>
              }
              wide="very"
            />
          </Grid.Column>
          <Grid.Column className="item-column">
            <div className={'item-status-container'}>
              <ItemStatus item={item} />
              {/* dropdown */}
              <Dropdown icon={<ContextMenuIcon />} direction={'left'}>
                <Dropdown.Menu>
                  {itemUpdateAction && (
                    <Dropdown.Item
                      text={itemUpdateAction.text}
                      onClick={itemUpdateAction.onUpdateHandler}
                      disabled={isConnectTokenLoading}
                    />
                  )}
                  <Confirm
                    size="small"
                    content={
                      <div className="content">
                        Tem certeza de que deseja excluir "{item.connector.name}
                        "?
                        <br />
                        Isso removerá todo o conteúdo e não poderá ser desfeito.
                      </div>
                    }
                    open={confirmDelete}
                    onCancel={handleModalCancel}
                    cancelButton={<Button secondary>Cancelar</Button>}
                    onConfirm={handleModalConfirm}
                    confirmButton={<Button primary>Confirme</Button>}
                  />
                  <Dropdown.Item
                    text="Copiar Item ID"
                    onClick={handleCopyItemIdClick}
                  />
                  <Dropdown.Item
                    text="Excluir"
                    onClick={handleDeleteOptionClick}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Grid.Column>
        </Grid.Row>
        <div className={'item-divider'} />
        <Grid.Row>
          <Grid.Column className="item-column-date">
            <ColumnHeader>Última atualização:</ColumnHeader>
            <div className={'last-date-updated'}>
              {item.lastUpdatedAt
                ? formatDistanceToNow(new Date(item.lastUpdatedAt), {
                    addSuffix: true,
                    locale: pt,
                  })
                : 'Ainda não'}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default React.memo(Item)
