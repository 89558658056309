import { AnyAction } from 'redux'
import type { InvestmentTransaction } from 'pluggy-sdk'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { InvestmentTransactionState } from './reducer'

export const getState = (state: RootState): InvestmentTransactionState =>
  state.investmentTransaction
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type
) => state.some((action: AnyAction) => action.type === type)

export const getInvestmentTransactionsByInvestmentId = (
  state: RootState,
  investmentId: string
): InvestmentTransaction[] | null => getData(state)[investmentId] || null

export const getInvestmentTransactionsLoadingByInvestmentId = (
  state: RootState,
  investmentId: string
): LoadingState => getLoading(state)[investmentId] || []
