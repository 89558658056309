import type { Item, AcquirerAnticipation } from 'pluggy-sdk'

import { BaseAPI, PageResponse } from './BaseAPI'

export class AcquirerAnticipationAPI extends BaseAPI {
  async fetchAcquirerAnticipations(itemId: Item['id']): Promise<AcquirerAnticipation[]> {
    const { results } = await this.request<PageResponse<AcquirerAnticipation>>(
      'get',
      `/acquirer-anticipations?itemId=${itemId}`
    )
    return results
  }
}
