import type { AcquirerSale, Item } from 'pluggy-sdk'

import {
  FETCH_ACQUIRER_SALES_FAILURE,
  FETCH_ACQUIRER_SALES_REQUEST,
  FETCH_ACQUIRER_SALES_SUCCESS,
  FetchAcquirerSalesFailureAction,
  FetchAcquirerSalesRequestAction,
  FetchLAcquirerSalesSuccessAction,

} from './actions'
import { loadingReducer, LoadingState } from '../loading/reducer'

export type AcquirerSaleState = {
  data: Record<Item['id'], AcquirerSale[] | undefined>
  loading: Record<Item['id'], LoadingState | undefined>
  error: Record<Item['id'], string | null | undefined>
}

const INITIAL_STATE: AcquirerSaleState = {
  data: {},
  loading: {},
  error: {},
}

type AcquirerSaleReducer =
  | FetchAcquirerSalesRequestAction
  | FetchAcquirerSalesFailureAction
  | FetchLAcquirerSalesSuccessAction

export function acquirerSaleReducer(state = INITIAL_STATE, action: AcquirerSaleReducer) {
  switch (action.type) {
    case FETCH_ACQUIRER_SALES_REQUEST: {
      const {
        payload: { itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
      }
    }
    case FETCH_ACQUIRER_SALES_SUCCESS: {
      const {
        payload: { acquirerSales, itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: null,
        },
        data: {
          ...state.data,
          [itemId]: acquirerSales,
        },
      }
    }
    case FETCH_ACQUIRER_SALES_FAILURE: {
      const {
        payload: { error, itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: error,
        },
      }
    }
    default:
      return state
  }
}
