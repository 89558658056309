import {
  ADD_NOTIFICATION,
  AddNotificationAction,
  REMOVE_NOTIFICATION,
  RemoveNotificationAction,
} from './actions'
import { Notification } from './types'

export type NotificationState = {
  data: Notification[]
}

const INITIAL_STATE: NotificationState = {
  data: [],
}

type NotificationReducerAction =
  | AddNotificationAction
  | RemoveNotificationAction

export function notificationReducer(
  state: NotificationState = INITIAL_STATE,
  action: NotificationReducerAction
): NotificationState {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        data: [...state.data, action.payload.notification],
      }
    case REMOVE_NOTIFICATION:
      const notification = state.data.find((n) => n.id === action.payload.id)
      if (notification) {
        notification.open = false
      }
      return {
        data: [...state.data],
      }
    default:
      return state
  }
}
