import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { isJwtExpired } from '../../modules/login/utils'
import { store } from '../../modules/store'
import {
  createPluggyApiKeyRequest,
  logoutRequest,
} from '../../modules/login/actions'
import { getError, getPluggyApiKey } from '../../modules/login/selectors'
import axiosBetterStacktrace from 'axios-better-stacktrace'

export interface APIParam {
  [key: string]: any
}

export const getApiUrl = () => process.env.REACT_APP_PLUGGY_API_URL || ''
if (!getApiUrl()) {
  throw new Error('Missing environment variable REACT_APP_PLUGGY_API_URL')
}

export type PageResponse<T> = {
  results: T[]
  page: number
  total: number
  totalPages: number
}

export class BaseAPI {
  private service: AxiosInstance | undefined

  constructor(public apiKey: string, public url: string = getApiUrl()) {}

  /**
   * Get or initialize the Axios client instance
   */
  protected getServiceInstance(): AxiosInstance {
    if (this.service === undefined) {
      this.service = axios.create({
        headers: {
          'X-API-KEY': this.apiKey,
          'Content-Type': 'application/json',
        },
      })

      axiosBetterStacktrace(this.service, {
        // use concrete class name for base error stack
        errorMsg: this.constructor.name,
      })
    }
    return this.service
  }

  getUrl(path: string) {
    return `${this.url}${path}`
  }

  /**
   * Helper to build the axios request options object
   *
   * @param method
   * @param path
   * @param params
   * @param axiosRequestConfig
   * @private
   */
  private buildRequestOptions(
    method: AxiosRequestConfig['method'],
    path: string,
    params: APIParam | null = null,
    axiosRequestConfig: AxiosRequestConfig
  ) {
    const options: AxiosRequestConfig = {
      ...axiosRequestConfig,
      method,
      url: this.getUrl(path),
    }

    if (params) {
      if (method === 'get') {
        options.params = params
      } else {
        options.data = params
      }
    }
    return options
  }

  /**
   * Helper to actually perform the desired request
   *
   * @param method
   * @param path
   * @param params
   * @param axiosRequestConfig
   * @private
   */
  private async doRequest<T = unknown>(
    method: AxiosRequestConfig['method'],
    path: string,
    params: APIParam | null = null,
    axiosRequestConfig: AxiosRequestConfig = {}
  ): Promise<T> {
    const options = this.buildRequestOptions(
      method,
      path,
      params,
      axiosRequestConfig
    )

    try {
      const { data } = await this.getServiceInstance().request<T>(options)

      return data
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        error.message = `Unexpected error performing HTTP request: ${error.message}`
        throw error
      }

      if (BaseAPI.isTokenExpiredError(error)) {
        // token expired error -> re-throw again as-is, so it can be retried
        throw error
      }

      const { response, message } = error

      if (!response) {
        // is a Network error
        console.error(
          `[API] No HTTP response from server: ${message || ''}`,
          error
        )
        throw error
      }

      // is an API error
      const { status } = response

      console.warn(
        `[API] HTTP request failed: ${status}${message ? ` - ${message}` : ''}`,
        error
      )

      throw error
    }
  }

  /**
   * Helper to perform request to create pluggy API key
   * updating it inside Redux store directly, and retrieving the result.
   *
   * @private
   */
  private async dispatchCreatePluggyApiKeyRequest(): Promise<string> {
    return await new Promise<string>((resolve, reject) => {
      const cleanup = store.subscribe(() => {
        const state = store.getState()
        const error = getError(state)

        // detect CREATE_PLUGGY_API_KEY_FAILURE
        if (error) {
          cleanup()
          reject(error)
          return
        }
        // detect CREATE_PLUGGY_API_KEY_SUCCESS
        const updatedPluggyApiKey = getPluggyApiKey(state)
        if (!updatedPluggyApiKey || updatedPluggyApiKey === this.apiKey) {
          // value not yet available or didn't change
          return
        }

        resolve(updatedPluggyApiKey)
        cleanup()
      })

      store.dispatch(createPluggyApiKeyRequest())
    })
  }

  /**
   * Check if is Pluggy API token expired error response
   * @param error
   * @private
   */
  private static isTokenExpiredError(error: AxiosError): boolean {
    const { response, config: request } = error
    if (!response) {
      throw new Error('No response from server')
    }
    if (!request) {
      throw new Error('No request object is present')
    }

    const originalApiKey = request.headers?.['X-API-KEY'] as string | undefined

    if (!originalApiKey) {
      throw new Error('X-API-KEY not present in the original request')
    }

    return response.status === 403 && isJwtExpired(originalApiKey)
  }

  public async request<T = unknown>(
    method: AxiosRequestConfig['method'],
    path: string,
    params: APIParam | null = null,
    axiosRequestConfig: AxiosRequestConfig = {}
  ): Promise<T> {
    if (isJwtExpired(this.apiKey)) {
      console.info(
        '[API] Pluggy API key expired, refreshing before proceeding with request...'
      )

      // try to refresh token, then retry the original request once
      // Note: we do it within Redux store because we need it to be updated there as well
      let updatedPluggyApiKey: string

      try {
        updatedPluggyApiKey = await this.dispatchCreatePluggyApiKeyRequest()
      } catch (error) {
        error.message = `Could not refresh API key: ${error.message}`
        // failed to refresh Pluggy API key after it has expired
        console.warn('[API]', error)
        // dispatch logout request
        store.dispatch(logoutRequest())
        throw error
      }

      this.apiKey = updatedPluggyApiKey
      console.info('[API] Pluggy Api key refreshed, resuming request.')
    }

    // got a new API key, proceed with the original request
    return await this.doRequest<T>(method, path, params, axiosRequestConfig)
  }
}
