import React from 'react'

import './PluggyLogo.css'

const PluggyLogo = () => (
  <svg
    width="61"
    height="40"
    viewBox="0 0 61 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={'PluggyLogo'}
  >
    <g id="Pluggy">
      <path
        id="Fill 1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.2119 33.9803C27.1543 33.9803 26.0967 33.5841 25.2906 32.7923C21.4952 29.0655 19.4056 24.1131 19.4056 18.8448C19.4056 13.631 21.458 8.71566 25.1848 5.0035C26.7867 3.40808 29.4028 3.38788 31.0258 4.96029C32.6517 6.53101 32.6705 9.09894 31.0698 10.6938C28.8762 12.8801 27.6677 15.7752 27.6677 18.8448C27.6677 21.947 28.8985 24.864 31.1333 27.0582C32.746 28.6418 32.746 31.2097 31.1321 32.7928C30.326 33.5841 29.2684 33.9803 28.2119 33.9803Z"
        fill="#50E2C6"
      />
      <mask
        id="mask0_301_1110"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="61"
        height="40"
      >
        <path
          id="Clip 4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H60.0898V40H0V0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_301_1110)">
        <path
          id="Fill 3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.8793 31.7775C38.8317 31.7775 37.8082 31.6307 36.8216 31.3724C39.0876 28.1411 40.4198 24.2225 40.4198 20.0006C40.4198 15.777 39.0876 11.8578 36.8216 8.62703C37.8077 8.36814 38.8306 8.22304 39.8793 8.22304C46.441 8.22304 51.7802 13.5055 51.7802 20.0006C51.7802 26.4933 46.441 31.7775 39.8793 31.7775ZM8.30956 20.0006C8.30956 13.5055 13.6477 8.22304 20.2099 8.22304C26.7722 8.22304 32.1108 13.5055 32.1108 20.0006C32.1108 26.4933 26.7722 31.7775 20.2099 31.7775C13.6477 31.7775 8.30956 26.4933 8.30956 20.0006ZM39.8794 0C36.3709 0 33.0067 0.880797 30.0323 2.52916C27.1218 0.921195 23.7737 0 20.21 0C9.06628 0 0 8.9724 0 20.0006C0 31.0276 9.06628 40 20.21 40C23.7737 40 27.1229 39.0782 30.0329 37.4691C33.0101 39.1181 36.3755 40 39.8794 40C51.0237 40 60.09 31.0276 60.09 20.0006C60.09 8.9724 51.0237 0 39.8794 0Z"
          fill="#EF294B"
        />
      </g>
    </g>
  </svg>
)

export default React.memo(PluggyLogo)
