import React, { MouseEventHandler } from 'react'

import './Icon.css'

type Props = {
  className?: string
  children: React.ReactNode
  height?: string
  width?: string
  viewBox?: string
  fill?: string
  onClick?: MouseEventHandler<SVGSVGElement>
  disabled?: boolean
}

const Icon = ({
  children,
  className,
  height,
  width,
  viewBox,
  fill,
  onClick,
  disabled,
}: Props) => (
  <svg
    className={`Icon ${className || ''} ${disabled ? 'disabled' : ''}`}
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    height={height}
    width={width}
    viewBox={viewBox}
    fill={fill}
  >
    {children}
  </svg>
)

export default React.memo(Icon)
