import React from 'react'

import Icon from '../Icon'

import './ContextMenuIcon.css'

type Props = {
  onClick?: () => void
}

const ContextMenuIcon = ({ onClick }: Props) => (
  <Icon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={'ContextMenuIcon'}
    onClick={onClick}
  >
    <circle cx="9.61539" cy="4.61538" r="1.61538" fill="#111111" />
    <circle cx="9.61539" cy="10" r="1.61538" fill="#111111" />
    <circle cx="9.61539" cy="15.3846" r="1.61538" fill="#111111" />
  </Icon>
)

export default React.memo(ContextMenuIcon)
