import { action } from 'typesafe-actions'

import type { Investment } from 'pluggy-sdk'

// Fetch Investments
export const FETCH_INVESTMENTS_REQUEST = '[Request] Fetch investments'
export const FETCH_INVESTMENTS_SUCCESS = '[Success] Fetch investments'
export const FETCH_INVESTMENTS_FAILURE = '[Failure] Fetch investments'

export const fetchInvestmentsRequest = (itemId: string) =>
  action(FETCH_INVESTMENTS_REQUEST, { itemId })

export const fetchInvestmentsSuccess = (
  investments: Investment[],
  itemId: string
) => action(FETCH_INVESTMENTS_SUCCESS, { investments, itemId })

export const fetchInvestmentsFailure = (error: string, itemId: string) =>
  action(FETCH_INVESTMENTS_FAILURE, { error, itemId })

export type FetchInvestmentsRequestAction = ReturnType<
  typeof fetchInvestmentsRequest
>
export type FetchInvestmentsSuccessAction = ReturnType<
  typeof fetchInvestmentsSuccess
>
export type FetchInvestmentsFailureAction = ReturnType<
  typeof fetchInvestmentsFailure
>
