import { takeEvery, put, call, select } from 'redux-saga/effects'

import type { InvestmentTransaction } from 'pluggy-sdk'

import { getPluggyApiKey } from '../login/selectors'
import {
  FetchInvestmentTransactionsRequestAction,
  fetchInvestmentTransactionsSuccess,
  fetchInvestmentTransactionsFailure,
  FETCH_INVESTMENT_TRANSACTIONS_REQUEST,
} from './actions'
import { InvestmentTransactionAPI } from '../../lib/api/investmentTransactions'

function* handleFetchInvestmentTransactionsRequest(
  action: FetchInvestmentTransactionsRequestAction
) {
  const {
    payload: { investmentId },
  } = action
  const apiKey: string = yield select(getPluggyApiKey)
  const investmentTransactionAPI = new InvestmentTransactionAPI(apiKey)

  try {
    const transactions: InvestmentTransaction[] = yield call(() =>
      investmentTransactionAPI.fetchAll(investmentId)
    )
    yield put(fetchInvestmentTransactionsSuccess(transactions, investmentId))
  } catch (error) {
    yield put(fetchInvestmentTransactionsFailure(error.message, investmentId))
  }
}

export function* investmentTransactionSaga() {
  yield takeEvery(
    FETCH_INVESTMENT_TRANSACTIONS_REQUEST,
    handleFetchInvestmentTransactionsRequest
  )
}
