import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { all, takeEvery } from 'redux-saga/effects'

import { page } from './utils'
import { retrieveStoredApplicationId } from '../login/storage'

/**
 * Track page navigation
 */
function handleLocationChange(action: LocationChangeAction) {
  const {
    payload: { isFirstRendering },
  } = action
  const applicationId = retrieveStoredApplicationId()
  page(undefined, { applicationId, isFirstRendering })
}

export function* analyticsSaga() {
  yield all([takeEvery(LOCATION_CHANGE, handleLocationChange)])
}
