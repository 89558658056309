import React from 'react'

import { Grid, Header, Loader } from 'semantic-ui-react'
import { Section } from '../Section'
import { Props } from './ListSection.types'

import './ListSection.css'

const ListSection = <T extends unknown>({
  data,
  isLoading,
  title,
  renderValue,
  withDegradee,
  className,
}: Props<T>) => {
  const isFirstLoading = isLoading && !data

  return (
    <Section className={`ListSection ${className || ''}`}>
      <Grid>
        <Grid.Row>
          <Grid.Column className={'list-section-column-data'}>
            {isFirstLoading ? (
              <Loader className="list-section-loader" size="big" active />
            ) : (
              data.length > 0 && (
                <>
                  {title && (
                    <div className="list-header-container">
                      <Header as="h2">
                        {data.length} {title}
                        {data.length > 1 ? 's' : null}
                      </Header>
                    </div>
                  )}
                  <div className={'list-item'}>{data.map(renderValue)}</div>
                </>
              )
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {withDegradee && <div className={'list-section-degradee'} />}
    </Section>
  )
}

// Note: casting memo result 'as typeof ListSection' as an easy fix
// to support generic <T> data in ListSection component
export default React.memo(ListSection) as typeof ListSection
