import type { Investment, Item } from 'pluggy-sdk'

import { loadingReducer, LoadingState } from '../loading/reducer'
import {
  FETCH_INVESTMENTS_FAILURE,
  FETCH_INVESTMENTS_REQUEST,
  FETCH_INVESTMENTS_SUCCESS,
  FetchInvestmentsFailureAction,
  FetchInvestmentsRequestAction,
  FetchInvestmentsSuccessAction,
} from './actions'

export type InvestmentState = {
  data: Record<
    Item['id'],
    Record<Investment['id'], Investment | undefined> | undefined
  >
  loading: Record<Item['id'], LoadingState | undefined>
  error: Record<Item['id'], string | null>
}

const INITIAL_STATE: InvestmentState = {
  data: {},
  loading: {},
  error: {},
}

type InvestmentReducer =
  | FetchInvestmentsRequestAction
  | FetchInvestmentsSuccessAction
  | FetchInvestmentsFailureAction

export function investmentReducer(
  state = INITIAL_STATE,
  action: InvestmentReducer
) {
  switch (action.type) {
    case FETCH_INVESTMENTS_REQUEST: {
      const {
        payload: { itemId },
      } = action
      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
      }
    }
    case FETCH_INVESTMENTS_SUCCESS: {
      const { investments, itemId } = action.payload

      const investmentsById: Record<string, Investment | undefined> = {}
      for (const investment of investments) {
        investmentsById[investment.id] = investment
      }

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: null,
        },
        data: {
          ...state.data,
          [itemId]: investmentsById,
        },
      }
    }

    case FETCH_INVESTMENTS_FAILURE: {
      const { error, itemId } = action.payload

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: error,
        },
      }
    }
    default:
      return state
  }
}
