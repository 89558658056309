import React from 'react'

import { Accounts as AccountsComponent } from '@pluggyai/ui'

import { Props } from './Accounts.types'

import './Accounts.css'

const Accounts = ({
  onAccountClick,
  onUpdateTransactionCategory,
  categories,
  accountIdSelected,
  accountsWithTransactions,
  isTransactionsLoading,
}: Props) => (
  <AccountsComponent
    className={'Accounts'}
    accountIdSelected={accountIdSelected}
    accountsWithTransactions={accountsWithTransactions}
    onAccountClick={onAccountClick}
    categories={categories}
    onUpdateTransactionCategory={onUpdateTransactionCategory}
    isTransactionsLoading={isTransactionsLoading}
  />
)

export default React.memo(Accounts)
