import { Application } from './types'

const APPLICATION_DEVELOPMENT_ITEMS_LIMIT_DEFAULT = 100

/**
 *
 * Check if items create limit has been reached or exceeded for the current application.
 * Limit applies to 'DEVELOPMENT' environment applications,
 * only for non contract-signed (aka. production == true) plans.
 *
 * @see Notion docs for latest definitions: https://www.notion.so/pluggy/Applications-97192cfbb1a34e38bcb4be104ed0fba5#9127a022358b4906a89ceb8bccf60799
 * @param application
 * @param itemsTotalExceptSandbox
 */
export function isItemsCreateLimitExceeded(
  application: Application,
  itemsTotalExceptSandbox: number
): boolean {
  const {
    subscription: {
      production: isContractSignedSubscription,
      itemsLimit = APPLICATION_DEVELOPMENT_ITEMS_LIMIT_DEFAULT,
    },
    environment,
  } = application
  const isDevelopmentApplication = environment === 'DEVELOPMENT'

  return (
    !isContractSignedSubscription &&
    isDevelopmentApplication &&
    itemsTotalExceptSandbox >= itemsLimit
  )
}
