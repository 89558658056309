import { loadingReducer, LoadingState } from '../loading/reducer'
import { Insights } from './types'
import {
  GET_METABASE_IFRAME_INSIGHTS_FAILURE,
  GET_METABASE_IFRAME_INSIGHTS_REQUEST,
  GET_METABASE_IFRAME_INSIGHTS_SUCCESS,
  GetMetabaseIframeInsightsFailureAction,
  GetMetabaseIframeInsightsRequestAction,
  GetMetabaseIframeInsightsSuccessAction,
} from './actions'

export type InsightsState = {
  data: Record<string, Insights | string>
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: InsightsState = {
  data: {},
  loading: [],
  error: null,
}

type InsightsReducer =
  | GetMetabaseIframeInsightsFailureAction
  | GetMetabaseIframeInsightsRequestAction
  | GetMetabaseIframeInsightsSuccessAction

export function insightsReducer(
  state = INITIAL_STATE,
  action: InsightsReducer
): InsightsState {
  switch (action.type) {
    case GET_METABASE_IFRAME_INSIGHTS_REQUEST: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    }
    case GET_METABASE_IFRAME_INSIGHTS_SUCCESS: {
      const { metabaseUrl } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          metabaseUrl,
        },
      }
    }
    case GET_METABASE_IFRAME_INSIGHTS_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    }
    default:
      return state
  }
}
