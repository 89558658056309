import { call, put, select, takeEvery } from 'redux-saga/effects'

import type { IdentityResponse as Identity } from 'pluggy-sdk'

import { IdentityAPI } from '../../lib/api/identity'
import { getPluggyApiKey } from '../login/selectors'
import {
  FETCH_IDENTITY_BY_ITEMID_REQUEST,
  fetchIdentityByItemIdFailure,
  FetchIdentityByItemIdRequestAction,
  fetchIdentityByItemIdSuccess,
} from './actions'

function* handleFetchIdentityByItemIdRequest(
  action: FetchIdentityByItemIdRequestAction
) {
  const { itemId } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const identityAPI = new IdentityAPI(apiKey)
  try {
    const identity: Identity = yield call(() =>
      identityAPI.fetchByItemId(itemId)
    )
    yield put(fetchIdentityByItemIdSuccess(identity, itemId))
  } catch (error) {
    yield put(fetchIdentityByItemIdFailure(error.message, itemId))
  }
}

export function* identitySaga() {
  yield takeEvery(
    FETCH_IDENTITY_BY_ITEMID_REQUEST,
    handleFetchIdentityByItemIdRequest
  )
}
