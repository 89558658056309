import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { AnyAction, applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import createSagasMiddleware from 'redux-saga'

import { createRootReducer } from './reducer'
import { rootSaga } from './sagas'
import { initAnalytics } from './analytics/utils'
import { initSentry } from '../lib/sentry'
import { subscribeReduxPersist } from './storage'
import { consumePreviewApplicationIdParamsFromUrl } from './login/utils'

// In development mode we expose compose enhancers
const isDev = process.env.NODE_ENV === 'development'

const anyWindow = window as Window &
  typeof globalThis & {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: (_: Record<string, unknown>) => any
  }
const composeEnhancers =
  isDev && anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? anyWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose

initSentry()
initAnalytics()

export const history = createBrowserHistory()
const rootReducer = createRootReducer(history)

// We log everything in dev and only errors in production
const sagasMiddleware = createSagasMiddleware()
const loggerMiddleware = createLogger({
  collapsed: () => true,
  predicate: (_: unknown, action: AnyAction) =>
    isDev || action.type.includes('Failure'),
})

const middleware = applyMiddleware(
  sagasMiddleware,
  routerMiddleware(history),
  loggerMiddleware
)
const enhancer = composeEnhancers(middleware)
const store = createStore(rootReducer, enhancer)

export type ReduxStore = typeof store

sagasMiddleware.run(rootSaga)

// track store changes and persist them
subscribeReduxPersist(store)

// In development we make global state available in window
if (isDev) {
  const _window = window as typeof window & { getState: typeof store.getState }
  _window.getState = store.getState
}

// parse and handle initial URL query params
consumePreviewApplicationIdParamsFromUrl()

export { store }
