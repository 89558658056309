import { all, call, put, race, take, takeEvery } from 'redux-saga/effects'

import { DemoAPI } from '../../lib/api/demo'

import {
  CREATE_PLUGGY_API_KEY_FAILURE,
  CREATE_PLUGGY_API_KEY_REQUEST,
  CREATE_PLUGGY_API_KEY_SUCCESS,
  createPluggyApiKeyFailure,
  CreatePluggyApiKeyFailureAction,
  createPluggyApiKeyRequest,
  createPluggyApiKeySuccess,
  CreatePluggyApiKeySuccessAction,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  loginFailure,
  LoginFailureAction,
  loginSuccess,
  LoginSuccessAction,
  LOGOUT_REQUEST,
  logoutFailure,
  LogoutRequestAction,
  logoutSuccess,
} from './actions'
import { fetchItemsRequest } from '../item/actions'
import { identify, track } from '../analytics/utils'
import { DemoUser } from './types'
import UAParser from 'ua-parser-js'
import { TrackEventName } from '../analytics/events'
import {
  clearStoredPluggyApiKey,
  retrievePluggyApiKey,
  retrieveStoredApplicationId,
  storePluggyApiKey,
} from './storage'
import { isJwtExpired } from './utils'
import { addSentryUserData } from '../../lib/sentry'
import { fetchApplicationRequest } from '../application/actions'
import { fetchCategoriesRequest } from '../category/actions'
import { auth0LogOut, getAuth0TokenJwtPayload } from '../../lib/auth0'
import { getAuth0AccessToken } from '../../components/Auth0Provider'
import { extractCustomHostDomain } from '../../utils/url'

const {
  REACT_APP_PLUGGY_DASHBOARD_URL: pluggyDashboardUrl = 'https://dashboard.pluggy.ai',
} = process.env

/**
 * Helper to run 'createPluggyApiKeyRequest' action and wait for it's result
 * If success, return the pluggyApiKey value; if error, throw with the error message
 */
function* createPluggyApiKeyRequestAndWaitResult(): Generator<
  unknown,
  string,
  [CreatePluggyApiKeyFailureAction, CreatePluggyApiKeySuccessAction]
> {
  // run the request for Pluggy API key
  yield put(createPluggyApiKeyRequest())

  // wait for the action result
  const [errorResult, successResult]: [
    CreatePluggyApiKeyFailureAction | undefined,
    CreatePluggyApiKeySuccessAction
  ] = yield race([
    take(CREATE_PLUGGY_API_KEY_FAILURE),
    take(CREATE_PLUGGY_API_KEY_SUCCESS),
  ])

  if (errorResult) {
    // could not create api key, fail the entire login & show error message
    const {
      payload: { error },
    } = errorResult

    throw new Error(error)
  }

  const {
    payload: { apiKey: pluggyApiKey },
  } = successResult

  return pluggyApiKey
}

function* handleLogoutRequest(_action: LogoutRequestAction) {
  clearStoredPluggyApiKey()
  try {
    yield call(auth0LogOut)
    yield put(logoutSuccess())
  } catch (error) {
    console.error('Logout failed:', error)
    yield put(logoutFailure(error.message))
  }
}

function* handleLoginRequest() {
  let auth0AccessToken: string | undefined

  try {
    auth0AccessToken = yield call(getAuth0AccessToken)
  } catch (error) {
    error.message = `Could not complete pluggy-api login due to Auth0 error: ${error.message}`
    console.error(error)
    yield put(loginFailure(error.message))
    return
  }

  if (!auth0AccessToken) {
    yield put(loginFailure('Missing Auth0 access token'))
    return
  }

  try {
    // create a new Pluggy API key and store it
    const { email, sub: auth0UserId = '' } = getAuth0TokenJwtPayload(
      auth0AccessToken
    )

    // identify logged user in Segment and Sentry
    identifyUser({ email, auth0UserId })

    // run the request for Pluggy API key
    // if it fails we must fail the entire login & display informative error in UI
    // dispatch request saga & wait for result
    const pluggyApiKey: string = yield createPluggyApiKeyRequestAndWaitResult()

    yield put(
      loginSuccess({
        email,
        pluggyApiKey,
        auth0UserId: auth0UserId || email,
      })
    )
  } catch (error) {
    yield put(loginFailure(error.message))
  }
}

function* handleLoginSuccess(action: LoginSuccessAction) {
  const {
    payload: { user },
  } = action

  if (!user) {
    // no user logged in
    return
  }
  // login success
  // fetch initial application data
  yield put(fetchItemsRequest())
  yield put(fetchCategoriesRequest())
  yield put(fetchApplicationRequest())
}

function handleLoginFailure(action: LoginFailureAction) {
  const {
    payload: { error },
  } = action

  const customUrlDomain = extractCustomHostDomain(window.location.href)

  if (
    (error === 'error.application.not_found' ||
      error === 'error.user.not_registered') &&
    !customUrlDomain
  ) {
    // application_id not specified, not found or don't have access with current account
    // also, is NOT a custom URL domain
    // redirect to Dashboard /applications
    const pluggyDashboardApplicationsUrl = `${pluggyDashboardUrl}/applications`
    console.log(`Redirect to ${pluggyDashboardApplicationsUrl}...`)
    window.location.href = pluggyDashboardApplicationsUrl
  }
}

function identifyUser({
  email,
  auth0UserId,
}: Pick<DemoUser, 'email' | 'auth0UserId'>) {
  // add user to Sentry
  addSentryUserData({
    email,
  })

  // get user agent from user navigator
  const {
    navigator: { userAgent },
  } = window

  const parser = new UAParser(userAgent)
  // get data from the user agent
  const { browser, device, os } = parser.getResult()

  // identify user. We use 'email' as id, in case a same user accesses from
  // different social login providers
  identify(email, { email, auth0UserId })

  // extract login method from auth0 jwt 'sub' field
  // usually these come as: 'github|123', 'google-oauth|3211', 'auth0|333', 'windowslive|222'
  const loginMethod = auth0UserId.split('|')[0]

  // track user login with device information
  track(TrackEventName.USER_LOGGED_IN, { loginMethod, browser, device, os })
}

function* handleCreatePluggyApiKeyRequest() {
  // retrieve Auth0 access token
  let auth0AccessToken: string | undefined

  try {
    auth0AccessToken = yield call(getAuth0AccessToken)
  } catch (error) {
    error.message = `Could not retrieve pluggy-api key due to Auth0 error: ${error.message}`
    console.error(error)
    yield put(createPluggyApiKeyFailure(error.message))
    return
  }

  if (!auth0AccessToken) {
    yield put(createPluggyApiKeyFailure('Missing Auth0 access token'))
    return
  }

  const { email } = getAuth0TokenJwtPayload(auth0AccessToken)
  // add user data to Sentry
  addSentryUserData({ email })

  // get current applicationId from URL
  const applicationId = retrieveStoredApplicationId()

  // if api key is in storage and not expired, reuse it
  let pluggyApiKey = retrievePluggyApiKey()
  if (pluggyApiKey) {
    if (isJwtExpired(pluggyApiKey)) {
      console.info('Pluggy api key in storage was expired, refreshing it...')
    } else {
      console.info('Reusing pluggy api key from storage')
    }
  }

  if (pluggyApiKey && !isJwtExpired(pluggyApiKey)) {
    yield put(createPluggyApiKeySuccess(pluggyApiKey))
    return
  }

  // no apiKey or it was expired -> clear it (if there was any)
  clearStoredPluggyApiKey()

  const demoAPI = new DemoAPI(auth0AccessToken)

  try {
    // get a new token
    pluggyApiKey = (yield call(() =>
      demoAPI.fetchPluggyApiKey(applicationId)
    )) as string

    // store it in localStorage to reuse for future requests
    storePluggyApiKey(pluggyApiKey)

    yield put(createPluggyApiKeySuccess(pluggyApiKey))
  } catch (error) {
    // note: error.message is already an i18nKey, specified in fetchPluggyApiKey()
    yield put(createPluggyApiKeyFailure(error.message))
  }
}

export function* loginSaga() {
  yield all([
    takeEvery(LOGOUT_REQUEST, handleLogoutRequest),
    takeEvery(LOGIN_REQUEST, handleLoginRequest),
    takeEvery(LOGIN_SUCCESS, handleLoginSuccess),
    takeEvery(LOGIN_FAILURE, handleLoginFailure),
    takeEvery(CREATE_PLUGGY_API_KEY_REQUEST, handleCreatePluggyApiKeyRequest),
  ])
}
