import React from 'react'
import { Props } from './ColumnHeader.types'
import './ColumnHeader.css'

const ColumnHeader = (props: Props) => {
  const { children } = props
  return <div className="ColumnHeader">{children}</div>
}

export default React.memo(ColumnHeader)
