import type { Account, Transaction } from 'pluggy-sdk'
import { action } from 'typesafe-actions'

// Fetch Transactions
export const FETCH_TRANSACTIONS_REQUEST = '[Request] Fetch transactions'
export const FETCH_TRANSACTIONS_SUCCESS = '[Success] Fetch transactions'
export const FETCH_TRANSACTIONS_FAILURE = '[Failure] Fetch transactions'

export const fetchTransactionsRequest = (accountId: Account['id']) =>
  action(FETCH_TRANSACTIONS_REQUEST, { accountId })

export const fetchTransactionsSuccess = (
  transactions: Transaction[],
  accountId: Account['id']
) => action(FETCH_TRANSACTIONS_SUCCESS, { transactions, accountId })

export const fetchTransactionsFailure = (error: string, accountId: string) =>
  action(FETCH_TRANSACTIONS_FAILURE, { error, accountId })

export type FetchTransactionsRequestAction = ReturnType<
  typeof fetchTransactionsRequest
>
export type FetchTransactionsSuccessAction = ReturnType<
  typeof fetchTransactionsSuccess
>
export type FetchTransactionsFailureAction = ReturnType<
  typeof fetchTransactionsFailure
>
