import React, { useEffect } from 'react'

import { Loader } from 'semantic-ui-react'

import { Page } from '../Page'
import { Props } from './InsightsPage.types'

import './InsightsPage.css'

const InsightsPage = ({
  isLoggedIn,
  isLoadingMetabaseInsights,
  onGetMetabaseIframeInsights,
  metabaseIframeUrl,
}: Props) => {
  useEffect(() => {
    if (!metabaseIframeUrl && isLoggedIn) {
      onGetMetabaseIframeInsights()
    }
  }, [isLoggedIn, metabaseIframeUrl, onGetMetabaseIframeInsights])

  return (
    <Page className={'InsightsPage'}>
      {isLoadingMetabaseInsights && (
        <div className={'loading-overlay'}>
          <Loader size="big" active />
        </div>
      )}
      <div className={'content-container'}>
        {isLoggedIn && metabaseIframeUrl && (
          <iframe
            src={metabaseIframeUrl}
            title="Reporting"
            frameBorder="0"
            className="iframe-container"
          />
        )}
      </div>
    </Page>
  )
}

export default React.memo(InsightsPage)
