import { connect } from 'react-redux'

import { logoutRequest } from '../../modules/login/actions'
import { MapDispatch, MapDispatchProps } from './LogoutPage.types'
import CallbackPage from './LogoutPage'

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onLogout: () => dispatch(logoutRequest()),
})

export default connect(null, mapDispatch)(CallbackPage)
