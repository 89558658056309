import { AnyAction } from 'redux'

import type { AcquirerReceivable } from 'pluggy-sdk'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'

export const getState = (state: RootState) => state.acquirerReceivable
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type
) => state.some((action: AnyAction) => action.type === type)

export const getAcquirerReceivablesByItemId = (
  state: RootState,
  itemId: string
): AcquirerReceivable[] | undefined | null =>
  getData(state)[itemId] || null

export const getAcquirerReceivablesLoadingByItemId = (
  state: RootState,
  itemId: string
): LoadingState => getLoading(state)[itemId] || []

export const getAcquirerReceivablesErrorByItemId = (
  state: RootState,
  itemId: string
): string | null => {
  const acquirerReceivablesErrorByItemId = getError(state)
  const acquirerReceivablesError = acquirerReceivablesErrorByItemId[itemId]
  return acquirerReceivablesError || null
}
