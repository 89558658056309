import type { Item, Benefit } from 'pluggy-sdk'

import {
  FETCH_BENEFITS_FAILURE,
  FETCH_BENEFITS_REQUEST,
  FETCH_BENEFITS_SUCCESS,
  FetchBenefitsFailureAction,
  FetchBenefitsRequestAction,
  FetchBenefitsSuccessAction,
} from './actions'
import { loadingReducer, LoadingState } from '../loading/reducer'

export type BenefitState = {
  data: Record<Item['id'], Record<Benefit['id'], Benefit | undefined>>
  loading: Record<Item['id'], LoadingState | undefined>
  error: Record<Item['id'], string | null | undefined>
}

const INITIAL_STATE: BenefitState = {
  data: {},
  loading: {},
  error: {},
}

type BenefitReducer =
  | FetchBenefitsRequestAction
  | FetchBenefitsFailureAction
  | FetchBenefitsSuccessAction

export function benefitReducer(state = INITIAL_STATE, action: BenefitReducer) {
  switch (action.type) {
    case FETCH_BENEFITS_REQUEST: {
      const {
        payload: { itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
      }
    }
    case FETCH_BENEFITS_SUCCESS: {
      const {
        payload: { benefits, itemId },
      } = action

      // group benefits by id
      const benefitsById: Record<string, Benefit> = {}

      for (const benefit of benefits) {
        benefitsById[benefit.id] = benefit
      }

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: null,
        },
        data: {
          ...state.data,
          [itemId]: benefitsById,
        },
      }
    }
    case FETCH_BENEFITS_FAILURE: {
      const {
        payload: { error, itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: error,
        },
      }
    }
    default:
      return state
  }
}
