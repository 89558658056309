import type { Category } from 'pluggy-sdk'

import { LoadingState, loadingReducer } from '../loading/reducer'
import {
  FetchCategoriesRequestAction,
  FetchCategoriesSuccessAction,
  FetchCategoriesFailureAction,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
} from './actions'

export type CategoryState = {
  data: Category[]
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: CategoryState = {
  data: [],
  loading: [],
  error: null,
}

type CategoryReducerAction =
  | FetchCategoriesRequestAction
  | FetchCategoriesSuccessAction
  | FetchCategoriesFailureAction

export function categoryReducer(
  state = INITIAL_STATE,
  action: CategoryReducerAction
) {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    }
    case FETCH_CATEGORIES_SUCCESS: {
      const { categories } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: [...categories],
      }
    }

    case FETCH_CATEGORIES_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    }
    default:
      return state
  }
}
