import * as Sentry from '@sentry/react'
import {
  configureScope,
  Scope,
  setTag,
  setUser,
  User as SentryUser,
} from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { Integration } from '@sentry/types'
import { getApplicationIdQueryParam } from '../modules/login/utils'
import { retrieveStoredApplicationId } from '../modules/login/storage'
import { Application } from '../modules/application/types'

const {
  REACT_APP_SENTRY_DSN_KEY: sentryDsnKey = '',
  REACT_APP_VERCEL_ENV: environment = '', // preview | production | development
  REACT_APP_VERCEL_URL: vercelUrl = '', // current URL of the deployment
  REACT_APP_VERCEL_GIT_COMMIT_REF: gitBranchName = '', // current git branch name
  REACT_APP_VERCEL_GIT_COMMIT_SHA: vercelGitCommitHash = '', // current git commit sha
  REACT_APP_PLUGGY_API_URL: pluggyCoreApiUrl = '',
  REACT_APP_CONNECT_WEBAPP_URL: pluggyConnectWebappUrl = '',
} = process.env

/**
 * Add deployment info as 'Env' to Sentry context
 */
function addAppContextToSentry(): void {
  // Add non-sensitive env vars
  Sentry.setContext('Env', {
    vercelUrl,
    gitBranchName,
    vercelGitCommitHash,
    pluggyCoreApiUrl,
    pluggyConnectWebappUrl,
  })

  const applicationId =
    getApplicationIdQueryParam() || retrieveStoredApplicationId()

  Sentry.setTag('application.id', applicationId)
}

/**
 * Initialize Sentry instance with initial configuration.
 */
export function initSentry(): void {
  if (!sentryDsnKey) {
    console.warn('Sentry has not been enabled')
    return
  }

  Sentry.init({
    dsn: sentryDsnKey,
    integrations: (integrations: Integration[]) => {
      // integrations will be all default integrations
      const defaultIntegrationsFiltered = integrations.filter(
        (integration: Integration) => {
          const { name } = integration
          // disabling 'Dedupe' default integration due to issue unexpectedly dropping valid events
          // https://github.com/getsentry/sentry-javascript/issues/3939#issuecomment-909204914
          const exclusions: string[] = ['Dedupe']
          return !exclusions.includes(name)
        }
      )

      const extraIntegrations = [new BrowserTracing()]

      return [...defaultIntegrationsFiltered, ...extraIntegrations]
    },

    release:
      gitBranchName && vercelGitCommitHash
        ? `${gitBranchName}:${vercelGitCommitHash.slice(0, 10)}`
        : undefined,
    environment: environment || 'localhost',
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
    normalizeDepth: 10, // Or however deep you want your state context to be.

    beforeSend: (event: Sentry.Event) => {
      const { breadcrumbs } = event

      if (!breadcrumbs) {
        // no breadcrumbs, proceed
        return event
      }

      // breadcrumbs present, filter out unneeded ones
      function isReduxLog(breadcrumb: Sentry.Breadcrumb): boolean {
        const messageStarts = ['%c next state', '%c action', '%c prev state']
        return (
          breadcrumb.category === 'console' &&
          breadcrumb.level === 'log' &&
          messageStarts.some((messageStart) =>
            breadcrumb.message?.startsWith(messageStart)
          )
        )
      }

      // exclude redux logger breadcrumbs, to prevent Sentry 413 response
      const breadcrumbsFiltered = breadcrumbs.filter(
        (breadcrumb) => !isReduxLog(breadcrumb)
      )

      // remove duplicates by timestamp+category+level, to prevent 413 response
      const breadcrumbsFilteredUnique = Array.from(
        new Map(
          breadcrumbsFiltered.map((b) => [
            `${b.timestamp}_${b.category}_${b.level}`,
            b,
          ])
        ).values()
      ).sort((b1, b2) =>
        // restore ascending order
        b1.timestamp && b2.timestamp ? b1.timestamp - b2.timestamp : 0
      )

      event.breadcrumbs = breadcrumbsFilteredUnique

      return event
    },
  })

  addAppContextToSentry()
}

export function addSentryUserData(data: Partial<SentryUser>): void {
  configureScope((scope: Scope) => {
    const currentUser = scope.getUser()
    const userUpdated: SentryUser = {
      ...(currentUser || {}),
      ...data,
    }
    setUser(userUpdated)
  })
}

/**
 * Helper to append current application data to Sentry context, to
 * be able to better understand, ponderate and prioritize Sentry errors.
 *
 * @param application
 */
export function setApplicationTagsInSentryContext(
  application: Application
): void {
  const {
    subscription: { plan, production },
    environment,
  } = application

  setTag('application.subscription.plan', plan)
  setTag('application.subscription.production', production)
  setTag('application.environment', environment)
}
