import React from 'react'
import { Props } from './Section.types'
import './Section.css'

const Section = (props: Props) => {
  const { className, children } = props

  const classNames = ['Section']
  if (className) {
    classNames.push(className)
  }

  return <div className={classNames.join(' ')}>{children}</div>
}

export default React.memo(Section)
