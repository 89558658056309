import type { Investment, InvestmentTransaction } from 'pluggy-sdk'

import { loadingReducer, LoadingState } from '../loading/reducer'

import {
  FETCH_INVESTMENT_TRANSACTIONS_FAILURE,
  FETCH_INVESTMENT_TRANSACTIONS_REQUEST,
  FETCH_INVESTMENT_TRANSACTIONS_SUCCESS,
  FetchInvestmentTransactionsFailureAction,
  FetchInvestmentTransactionsRequestAction,
  FetchInvestmentTransactionsSuccessAction,
} from './actions'

export type InvestmentTransactionState = {
  data: Record<Investment['id'], InvestmentTransaction[] | undefined>
  loading: Record<Investment['id'], LoadingState | undefined>
  error: Record<Investment['id'], string | null | undefined>
}

const INITIAL_STATE: InvestmentTransactionState = {
  data: {},
  loading: {},
  error: {},
}

type InvestmentTransactionReducerAction =
  | FetchInvestmentTransactionsRequestAction
  | FetchInvestmentTransactionsSuccessAction
  | FetchInvestmentTransactionsFailureAction

export function investmentTransactionReducer(
  state = INITIAL_STATE,
  action: InvestmentTransactionReducerAction
) {
  switch (action.type) {
    case FETCH_INVESTMENT_TRANSACTIONS_REQUEST: {
      const {
        payload: { investmentId },
      } = action
      return {
        ...state,
        loading: {
          ...state.loading,
          [investmentId]: loadingReducer(state.loading[investmentId], action),
        },
      }
    }
    case FETCH_INVESTMENT_TRANSACTIONS_SUCCESS: {
      const {
        payload: { transactions, investmentId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [investmentId]: loadingReducer(state.loading[investmentId], action),
        },
        error: {
          ...state.error,
          [investmentId]: null,
        },
        data: {
          ...state.data,
          [investmentId]: transactions,
        },
      }
    }

    case FETCH_INVESTMENT_TRANSACTIONS_FAILURE: {
      const {
        payload: { investmentId, error },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [investmentId]: loadingReducer(state.loading[investmentId], action),
        },
        error: {
          ...state.error,
          [investmentId]: error,
        },
      }
    }
    default:
      return state
  }
}
