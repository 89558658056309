import { action } from 'typesafe-actions'

import { DemoUser } from './types'

/**
 *  Account Logout
 */
export const LOGOUT_REQUEST = '[Request] Logout'
export const LOGOUT_SUCCESS = '[Success] Logout'
export const LOGOUT_FAILURE = '[Failure] Logout'

export const logoutRequest = () => action(LOGOUT_REQUEST)
export const logoutSuccess = () => action(LOGOUT_SUCCESS)
export const logoutFailure = (error: string) =>
  action(LOGOUT_FAILURE, { error })

export type LogoutRequestAction = ReturnType<typeof logoutRequest>
export type LogoutSuccessAction = ReturnType<typeof logoutSuccess>
export type LogoutFailureAction = ReturnType<typeof logoutFailure>

/**
 * Initial Pluggy Api login request
 * Use the user Auth0 accessToken to retrieve a pluggy-api token
 */
export const LOGIN_REQUEST = '[Request] Login'
export const LOGIN_SUCCESS = '[Success] Login'
export const LOGIN_FAILURE = '[Failure] Login'

export const loginRequest = () => action(LOGIN_REQUEST)
export const loginSuccess = (user: DemoUser | null) =>
  action(LOGIN_SUCCESS, { user })
export const loginFailure = (error: string) => action(LOGIN_FAILURE, { error })

export type LoginRequestAction = ReturnType<typeof loginRequest>
export type LoginSuccessAction = ReturnType<typeof loginSuccess>
export type LoginFailureAction = ReturnType<typeof loginFailure>

/**
 *  Request Pluggy API key
 */
export const CREATE_PLUGGY_API_KEY_REQUEST = '[Request] Create Pluggy API key'
export const CREATE_PLUGGY_API_KEY_SUCCESS = '[Success] Create Pluggy API key'
export const CREATE_PLUGGY_API_KEY_FAILURE = '[Failure] Create Pluggy API key'

export const createPluggyApiKeyRequest = () =>
  action(CREATE_PLUGGY_API_KEY_REQUEST)
export const createPluggyApiKeySuccess = (apiKey: string) =>
  action(CREATE_PLUGGY_API_KEY_SUCCESS, { apiKey })
export const createPluggyApiKeyFailure = (error: string) =>
  action(CREATE_PLUGGY_API_KEY_FAILURE, { error })

export type CreatePluggyApiKeyRequestAction = ReturnType<
  typeof createPluggyApiKeyRequest
>
export type CreatePluggyApiKeySuccessAction = ReturnType<
  typeof createPluggyApiKeySuccess
>
export type CreatePluggyApiKeyFailureAction = ReturnType<
  typeof createPluggyApiKeyFailure
>
