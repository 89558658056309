import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Item } from 'pluggy-sdk'
import { Alert, Button, Confirm, Radio, Tag } from '@pluggyai/ui'
import { nanoid } from 'nanoid'

import { ItemConnecting } from '../ItemConnecting'
import { Item as ItemComponent } from '../Item'
import { ItemProductsContainer } from '../ItemProductsContainer'
import { ItemProductsContainerSkeleton } from '../ItemProductsContainer/ItemProductsContainerSkeleton'
import { ItemSkeleton } from '../Item/ItemSkeleton'
import { ListSection } from '../ListSection'
import { MainSection } from '../MainSection'
import { Props } from './ItemsContainer.types'
import { Section } from '../Section'
import { StepsToConnect } from '../StepsToConnect'
import { track } from '../../modules/analytics/utils'
import { TrackEventName } from '../../modules/analytics/events'
import { usePrevious } from '../../utils/hooks'
import { DeleteIcon } from '../Icon/DeleteIcon'
import { sortItemsByCreatedAtDesc } from '../../modules/item/utils'

import './ItemsContainer.css'

const { REACT_APP_CONNECT_WEBAPP_URL: connectWebappUrl = '' } = process.env

if (!connectWebappUrl) {
  throw new Error(
    'Must specify REACT_APP_CONNECT_WEBAPP_URL environment variable'
  )
}

const EmptyItem = () => (
  <div className={'EmptyItem'}>
    <p>Aqui você verá o item conectado.</p>
  </div>
)

const ItemsContainer = ({
  itemsById,
  itemsTotal,
  isLoading,
  itemsError,
  itemsTotalExceptSandbox,
  applicationCreateItemsLimit,
  isItemsCreateLimitExceeded,
  connectToken,
  isLoadingConnectToken,
  onCreateConnectToken,
  onDeleteItems,
}: Props) => {
  const [currentItem, setCurrentItem] = useState<Item>() // initialize with first items

  const [connectsConnecting, setConnectsConnecting] = useState<
    {
      connectId: string
      connectToken: string
    }[]
  >([])

  const [selectMode, setSelectMode] = useState(false)
  const [selectedItems, setSelectedItems] = useState<Item[]>([])

  const items = useMemo(() => {
    const itemsValues = Object.values(itemsById).filter(
      (item): item is Item => item !== undefined
    )

    return sortItemsByCreatedAtDesc(itemsValues)
  }, [itemsById])

  const previousItems = usePrevious(items)
  useEffect(() => {
    const userHasSameItems =
      previousItems !== undefined && previousItems.length === items.length
    if (items.length === 0 || userHasSameItems) {
      return
    }

    // initialize with first item if not current
    setCurrentItem(items[0])
  }, [currentItem, items, previousItems])

  const handleToggleSelectedItem = useCallback(
    (item: Item) => {
      if (selectedItems.includes(item)) {
        setSelectedItems((previousSelectedItems) =>
          previousSelectedItems.filter(
            (previousItem) => previousItem.id !== item.id
          )
        )
        return
      }

      setSelectedItems((previousSelectedItems) => [
        ...previousSelectedItems,
        item,
      ])
    },
    [selectedItems]
  )

  const handleItemClick = useCallback(
    (item: Item, event?: React.MouseEvent<HTMLDivElement>) => {
      if (selectMode) {
        if (event?.shiftKey && selectedItems.length > 0) {
          // shift key was pressed while clicking and there's at least one item selected
          // select all items between the last selected item and the current item
          const lastSelectedItem = selectedItems[selectedItems.length - 1]
          const lastSelectedItemIndex = items.findIndex(
            (item) => item.id === lastSelectedItem.id
          )
          const currentItemIndex = items.findIndex(
            (item_) => item_.id === item.id
          )

          const fromIndex = Math.min(lastSelectedItemIndex, currentItemIndex)
          const toIndex = Math.max(lastSelectedItemIndex, currentItemIndex)

          // there are 2 cases:
          // 1. the user selected first an item that is after the current item
          // 2. the user selected first an item that is before the current item

          // the first item in the selection is the first of the two
          // the last item is the last of the two.

          const itemsToSelect = items.slice(fromIndex, toIndex + 1)

          setSelectedItems((previousSelectedItems) => [
            ...previousSelectedItems,
            ...itemsToSelect.filter(
              (item) => !previousSelectedItems.includes(item)
            ),
          ])
          return
        }

        handleToggleSelectedItem(item)
        return
      }

      setCurrentItem(item)
    },
    [handleToggleSelectedItem, items, selectedItems, selectMode]
  )

  const connectButtonText = 'Conectar conta'

  const handleConnectButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, { text: connectButtonText })
    // only fetch connect token when the current one is expired
    onCreateConnectToken()
  }, [onCreateConnectToken])

  const previousConnectToken = usePrevious(connectToken)

  useEffect(() => {
    if (
      isLoadingConnectToken ||
      connectToken === null ||
      previousConnectToken === connectToken
    ) {
      // connect token didn't change, no need add a new widget
      return
    }

    // generate randoms ids to identify each connect opened
    setConnectsConnecting((previous) => [
      { connectId: nanoid(), connectToken },
      ...previous,
    ])
  }, [connectToken, isLoadingConnectToken, previousConnectToken])

  const handleRemoveConnectIdFromList = useCallback((connectId: string) => {
    setConnectsConnecting((previous) =>
      previous.filter((previous) => previous.connectId !== connectId)
    )
  }, [])

  const isFirstTimeLoading = isLoading && items.length === 0

  // mock 2 items for the skeleton
  // note: this was review with Agus
  const mockedItems = Array.from(Array(2))

  const areItemsConnecting = connectsConnecting.length > 0

  const [isItemsListFullScrolled, setIsItemsListFullScrolled] = React.useState<
    boolean
  >(false)

  const [
    itemsListRef,
    setItemsListRef,
  ] = React.useState<HTMLDivElement | null>()

  const [confirmDelete, setConfirmDelete] = useState(false)

  // note: using callback instead of ref as a workaround to get element ref
  // when node is mounted on DOM instead of when components itself mounts
  const handleItemsListRefMount = React.useCallback((node: HTMLDivElement) => {
    setItemsListRef(node)
  }, [])

  const hasOverflow =
    itemsListRef && itemsListRef.scrollHeight > itemsListRef.clientHeight

  const handleScroll = React.useCallback(
    (event) => {
      const {
        target: { scrollHeight, scrollTop, clientHeight },
      } = event

      const isScrolledToBottom =
        Math.floor(scrollHeight) - Math.floor(scrollTop) ===
        Math.floor(clientHeight)
      if (!isScrolledToBottom && !isItemsListFullScrolled) {
        // nothing to do, it is not full scrolled (prevents unneeded re-renders)
        return
      }
      setIsItemsListFullScrolled(isScrolledToBottom)
    },
    [isItemsListFullScrolled]
  )

  const handleDeleteButtonClick = useCallback(() => {
    if (selectedItems.length <= 0) {
      return
    }

    setConfirmDelete(true)
  }, [selectedItems])

  const handleCancelDeleteItems = useCallback(() => {
    setConfirmDelete(false)
  }, [])

  const handleSelectAllItems = useCallback(() => {
    setSelectedItems(items)
  }, [items])

  const allItemsSelected =
    items.length > 0 && selectedItems.length === items.length

  const handleExitSelectMode = useCallback(() => {
    setSelectMode(false)
    setSelectedItems([])
  }, [])

  const handleToggleSelectMode = useCallback(() => {
    if (selectMode) {
      handleExitSelectMode()
      return
    }
    setSelectMode(true)
  }, [handleExitSelectMode, selectMode])

  const handleDeleteSelectedItems = useCallback(() => {
    handleExitSelectMode()
    setConfirmDelete(false)
    onDeleteItems(selectedItems)
  }, [handleExitSelectMode, selectedItems, onDeleteItems])

  const handleSelectAllToggle = useCallback(() => {
    if (allItemsSelected) {
      setSelectedItems([])
      return
    }
    handleSelectAllItems()
  }, [allItemsSelected, handleSelectAllItems])

  const itemsCreateLimitExceededMessage =
    itemsTotalExceptSandbox === null || applicationCreateItemsLimit === null
      ? undefined
      : `${
          itemsTotalExceptSandbox > applicationCreateItemsLimit
            ? // items total exceeds the limit, for example if the limit was raised or decreased via API
              `Você criou atualmente ${itemsTotalExceptSandbox} itens (sem contar os de Sandbox) que excede o limite de ${applicationCreateItemsLimit} itens criados`
            : // items total is *at* the limit, it has just been reached organically, so no need to display both numbers
              `Você alcançou o limite de ${applicationCreateItemsLimit} itens criados (sem contar os de Sandbox)`
        }. Para continuar testando o Pluggy Connect, por favor, delete alguns ou crie uma nova aplicação no Dashboard.`

  return (
    <Section className={'ItemsContainer'}>
      <div className={'product-container'}>
        {isItemsCreateLimitExceeded && (
          <Alert
            message={itemsCreateLimitExceededMessage}
            type="warning"
            size="medium"
          />
        )}
        <MainSection
          title="Demo Pluggy Connect"
          subtitle="Este aplicativo mostra os detalhes da conta e das transações de um usuário, resumindo como funciona uma integração de ponta a ponta com a Pluggy."
        />
        {isFirstTimeLoading ? (
          // in the first load, show skeleton
          <ItemProductsContainerSkeleton />
        ) : itemsError ? (
          // error fetching -> show error
          <Alert
            type={'error'}
            message={
              'Estamos com problemas para obter os itens. Tente atualizar e se o problema persistir não hesite em nos contatar.'
            }
          />
        ) : currentItem ? (
          <ItemProductsContainer item={currentItem} />
        ) : (
          <StepsToConnect />
        )}
      </div>
      <div className={'items-card-container'}>
        <div className={'header-container'}>
          <h3>Items</h3>
          <Confirm
            size="small"
            content={
              <div className="content">
                Tem certeza de que deseja excluir {selectedItems.length} items?
                <br />
                Isso removerá todo o conteúdo e não poderá ser desfeito.
              </div>
            }
            open={confirmDelete}
            onCancel={handleCancelDeleteItems}
            cancelButton={<Button secondary>Cancelar</Button>}
            onConfirm={handleDeleteSelectedItems}
            confirmButton={<Button primary>Excluir</Button>}
          />
          <Button
            primary
            onClick={handleConnectButtonClick}
            loading={isLoadingConnectToken}
          >
            {connectButtonText}
          </Button>
        </div>
        <div className={'select-mode-header'}>
          <span className={'items-count'}>
            {items.length > 0 &&
              itemsTotal !== null &&
              `${items.length} de ${itemsTotal}`}
          </span>
          {selectMode && (
            <Radio
              checked={allItemsSelected}
              onClick={handleSelectAllToggle}
              label={'Selecionar Todos'}
            />
          )}
          <div className={'actions'}>
            {selectMode && (
              <DeleteIcon
                onClick={handleDeleteButtonClick}
                disabled={selectedItems.length === 0}
              />
            )}

            <Tag
              selectable
              text={'Selecionar'}
              onClick={handleToggleSelectMode}
              isSelected={selectMode}
            />
          </div>
        </div>

        {isFirstTimeLoading ? (
          <ListSection
            className={'mocked-items-list'}
            data={mockedItems}
            renderValue={() => <ItemSkeleton />}
          />
        ) : (
          <div
            className={`items-list-container ${
              items.length >= 10 ? 'with-scrollbar' : ''
            }`}
            ref={(node: HTMLDivElement) => handleItemsListRefMount(node)}
            onScroll={handleScroll}
          >
            <div className={'items-list'}>
              {connectToken &&
                areItemsConnecting &&
                connectsConnecting.map(
                  ({ connectId, connectToken: connectToken_ }) => (
                    <ItemConnecting
                      itemCardId={connectId}
                      key={connectId}
                      connectToken={connectToken_}
                      onRemoveConnectIdFromList={handleRemoveConnectIdFromList}
                      connectWebappUrl={connectWebappUrl}
                    />
                  )
                )}
              {items.length > 0
                ? items.map((item) => (
                    <ItemComponent
                      item={item}
                      onClick={(event) => handleItemClick(item, event)}
                      current={item.id === currentItem?.id}
                      key={item.id}
                      selected={selectedItems.includes(item)}
                      onSelect={() => handleItemClick(item)}
                      selectMode={selectMode}
                    />
                  ))
                : !areItemsConnecting && <EmptyItem />}
            </div>
          </div>
        )}
        {hasOverflow && !isItemsListFullScrolled && (
          <div className={'scroll-shadow'} />
        )}
      </div>
    </Section>
  )
}

export default React.memo(ItemsContainer)
