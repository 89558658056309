import { Notification, NotificationOptions } from './types'

let notificationIdCounter = 0

const defaultNotificationOptions: Pick<Notification, 'level' | 'duration'> = {
  level: 'succeed',
  duration: 10000, // 10 seconds,
}

export default function createNotification(
  options: NotificationOptions
): Notification {
  return {
    ...defaultNotificationOptions,
    ...options,
    id: String(notificationIdCounter++),
    createdAt: new Date(),
    open: true,
  }
}
