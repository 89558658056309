import { connect } from 'react-redux'
import { RootState } from '../../modules/reducer'

import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './InsightsPage.types'
import InsightsPage from './InsightsPage'
import { isLoggedIn } from '../../modules/login/selectors'
import { getData as getInsightsData } from '../../modules/insights/selectors'
import { isMetabaseInsightsLoading } from '../../modules/insights/selectors'
import { getMetabaseIframeInsightsRequest } from '../../modules/insights/actions'

const mapState = (state: RootState): MapStateProps => ({
  isLoggedIn: isLoggedIn(state),
  isLoadingMetabaseInsights: isMetabaseInsightsLoading(state),
  metabaseIframeUrl: getInsightsData(state).metabaseUrl as string,
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onGetMetabaseIframeInsights: () =>
    dispatch(getMetabaseIframeInsightsRequest()),
})

export default connect(mapState, mapDispatch)(InsightsPage)
