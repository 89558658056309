import { takeEvery, put, call, select } from 'redux-saga/effects'

import type { Category, Transaction } from 'pluggy-sdk'

import { CategoryAPI } from '../../lib/api/category'
import { getPluggyApiKey } from '../login/selectors'
import {
  FetchCategoriesRequestAction,
  fetchCategoriesSuccess,
  fetchCategoriesFailure,
  FETCH_CATEGORIES_REQUEST,
  UpdateTransactionCategoryRequestAction,
  updateTransactionCategorySuccess,
  updateTransactionCategoryFailure,
  UPDATE_TRANSACTION_CATEGORY_REQUEST,
} from './actions'

function* handleFetchCategoriesRequest(_: FetchCategoriesRequestAction) {
  const apiKey: string = yield select(getPluggyApiKey)
  const categoryAPI = new CategoryAPI(apiKey)
  try {
    const categories: Category[] = yield call(() => categoryAPI.fetchAll())

    yield put(fetchCategoriesSuccess(categories))
  } catch (error) {
    yield put(fetchCategoriesFailure(error.message))
  }
}

function* handleUpdateTransactionCategoryRequest(
  action: UpdateTransactionCategoryRequestAction
) {
  const { transactionId, categoryId } = action.payload

  const apiKey: string = yield select(getPluggyApiKey)
  const categoryAPI = new CategoryAPI(apiKey)
  try {
    const transactionCategorized: Transaction = yield call(() =>
      categoryAPI.updateTransactionCategory(transactionId, categoryId)
    )

    yield put(updateTransactionCategorySuccess(transactionCategorized))
  } catch (error) {
    yield put(updateTransactionCategoryFailure(error.message))
  }
}

export function* categorySaga() {
  yield takeEvery(FETCH_CATEGORIES_REQUEST, handleFetchCategoriesRequest)
  yield takeEvery(
    UPDATE_TRANSACTION_CATEGORY_REQUEST,
    handleUpdateTransactionCategoryRequest
  )
}
