import { ReduxStore } from './store'

const PLUGGY_STATE_STORE_KEY = 'pluggy-store-state'

const SAVED_STATE_KEYS = ['item'] as const
type SavedStateKey = typeof SAVED_STATE_KEYS[number]

type SavedState = Pick<ReturnType<ReduxStore['getState']>, SavedStateKey>

/**
 * Subscribe to redux store changes, to persist them in localStorage instance.
 */
export function subscribeReduxPersist(store: ReduxStore): void {
  store.subscribe(() => {
    const state = store.getState()

    const stateToSave: SavedState = {
      item: state.item,
    }
    saveStoreState(stateToSave)
  })
}

export function loadStoreState<K extends SavedStateKey>(
  key: K
): SavedState[K] | undefined {
  const storedState = localStorage.getItem(`${PLUGGY_STATE_STORE_KEY}_${key}`)
  if (storedState === null) {
    return undefined
  }

  try {
    return JSON.parse(storedState) as SavedState[K]
  } catch (err) {
    // parsing error
    return undefined
  }
}

export function saveStoreState(state: SavedState): void {
  for (const [key, value] of Object.entries(state)) {
    const valueStringified = JSON.stringify(value)

    try {
      localStorage.setItem(`${PLUGGY_STATE_STORE_KEY}_${key}`, valueStringified)
    } catch {
      // could not save data, probably due to memory exceeded
    }
  }
}

export function clearStoreState(): void {
  for (const key of SAVED_STATE_KEYS) {
    localStorage.removeItem(`${PLUGGY_STATE_STORE_KEY}_${key}`)
  }
}
