import { Item } from 'pluggy-sdk'
import { loadingReducer, LoadingState } from '../loading/reducer'
import {
  DELETE_ITEM_FAILURE,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DeleteItemFailureAction,
  DeleteItemRequestAction,
  DeleteItemSuccessAction,
  FETCH_ITEM_FAILURE,
  FETCH_ITEM_REQUEST,
  FETCH_ITEM_SUCCESS,
  FETCH_ITEMS_FAILURE,
  FETCH_ITEMS_REQUEST,
  FETCH_ITEMS_SUCCESS,
  FetchItemFailureAction,
  FetchItemRequestAction,
  FetchItemsFailureAction,
  FetchItemsRequestAction,
  FetchItemsSuccessAction,
  FetchItemSuccessAction,
  UPDATE_ITEM_FAILURE,
  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UpdateItemFailureAction,
  UpdateItemRequestAction,
  UpdateItemSuccessAction,
  DeleteItemsRequestAction,
  DeleteItemsSuccessAction,
  DeleteItemsFailureAction,
  DELETE_ITEMS_REQUEST,
  DELETE_ITEMS_FAILURE,
  DELETE_ITEMS_SUCCESS,
} from './actions'
import { loadStoreState } from '../storage'

export type ItemState = {
  data: {
    itemsById: Record<Item['id'], Item | undefined>
    total: number | null
    totalExceptSandbox: number | null
  }
  loading: LoadingState
  error: string | null
}

const storedState = loadStoreState('item')

const isStoredStateObsolete =
  storedState &&
  (typeof storedState.data.itemsById === 'undefined' ||
    typeof storedState.data.totalExceptSandbox === 'undefined')

// previously, ItemState had type 'data: Record<string, Item>'.
// now, it's 'data: {itemsById: Record<string, Item>}'.
// only use storedState if not obsolete, otherwise ignore it, to override it later
const INITIAL_STATE: ItemState =
  storedState && !isStoredStateObsolete
    ? storedState
    : {
        data: {
          itemsById: {},
          total: null,
          totalExceptSandbox: null,
        },
        loading: [],
        error: null,
      }

type ItemReducer =
  | FetchItemsRequestAction
  | FetchItemsSuccessAction
  | FetchItemsFailureAction
  | FetchItemRequestAction
  | FetchItemSuccessAction
  | FetchItemFailureAction
  | DeleteItemRequestAction
  | DeleteItemSuccessAction
  | DeleteItemFailureAction
  | DeleteItemsRequestAction
  | DeleteItemsSuccessAction
  | DeleteItemsFailureAction
  | UpdateItemFailureAction
  | UpdateItemSuccessAction
  | UpdateItemRequestAction

export function itemReducer(
  state = INITIAL_STATE,
  action: ItemReducer
): ItemState {
  switch (action.type) {
    case FETCH_ITEM_REQUEST:
    case FETCH_ITEMS_REQUEST:
    case UPDATE_ITEM_REQUEST:
    case DELETE_ITEM_REQUEST:
    case DELETE_ITEMS_REQUEST: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    }
    case FETCH_ITEMS_SUCCESS: {
      const {
        payload: { items, total, totalExceptSandbox },
      } = action

      const itemsById: Record<string, Item> = {}

      for (const item of items) {
        itemsById[item.id] = item
      }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          itemsById,
          total,
          totalExceptSandbox,
        },
      }
    }
    case UPDATE_ITEM_SUCCESS: {
      const { item } = action.payload
      if (item.connector.hasMFA) {
        return {
          ...state,
          loading: loadingReducer(state.loading, action),
          error: null,
        }
      }
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          [item.id]: item,
        },
      }
    }
    case FETCH_ITEM_SUCCESS: {
      const { item } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          itemsById: {
            ...state.data.itemsById,
            [item.id]: item,
          },
        },
      }
    }
    case DELETE_ITEM_SUCCESS: {
      const { item } = action.payload

      const {
        data: { itemsById: previousItemsById, total: previousTotal },
      } = state

      const itemsById = { ...previousItemsById }
      const total = previousTotal !== null ? previousTotal - 1 : null
      delete itemsById[item.id]

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          itemsById,
          total,
        },
      }
    }
    case DELETE_ITEMS_SUCCESS: {
      const { items } = action.payload

      const {
        data: { itemsById: previousItemsById, total: previousTotal },
      } = state

      const itemsById = { ...previousItemsById }
      const total = previousTotal !== null ? previousTotal - items.length : null

      for (const item of items) {
        delete itemsById[item.id]
      }

      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: {
          ...state.data,
          itemsById,
          total,
        },
      }
    }
    case FETCH_ITEMS_FAILURE:
    case FETCH_ITEM_FAILURE:
    case UPDATE_ITEM_FAILURE:
    case DELETE_ITEM_FAILURE:
    case DELETE_ITEMS_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    }
    default:
      return state
  }
}
