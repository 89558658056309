import type { Item, AcquirerReceivable } from 'pluggy-sdk'

import { BaseAPI, PageResponse } from './BaseAPI'

export class AcquirerReceivableAPI extends BaseAPI {
  async fetchAcquirerReceivables(itemId: Item['id']): Promise<AcquirerReceivable[]> {
    const { results } = await this.request<PageResponse<AcquirerReceivable>>(
      'get',
      `/acquirer-receivables?itemId=${itemId}`
    )
    return results
  }
}
