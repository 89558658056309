import { action } from 'typesafe-actions'

import type { AcquirerAnticipation } from 'pluggy-sdk'

/// Fetch acquirer Anticipations

export const FETCH_ACQUIRER_ANTICIPATIONS_REQUEST = '[Request] Fetch acquirer Anticipations'
export const FETCH_ACQUIRER_ANTICIPATIONS_SUCCESS = '[Success] Fetch acquirer Anticipations'
export const FETCH_ACQUIRER_ANTICIPATIONS_FAILURE = '[Failure] Fetch acquirer Anticipations'

export const fetchAcquirerAnticipationsRequest = (itemId: string) =>
  action(FETCH_ACQUIRER_ANTICIPATIONS_REQUEST, { itemId })

export const fetchAcquirerAnticipationsSuccess = (
  acquirerAnticipations: AcquirerAnticipation[],
  itemId: string
) => action(FETCH_ACQUIRER_ANTICIPATIONS_SUCCESS, { acquirerAnticipations, itemId })

export const fetchAcquirerAnticipationsFailure = (error: string, itemId: string) =>
  action(FETCH_ACQUIRER_ANTICIPATIONS_FAILURE, { error, itemId })

export type FetchAcquirerAnticipationsRequestAction = ReturnType<
  typeof fetchAcquirerAnticipationsRequest
>
export type FetchLAcquirerAnticipationsSuccessAction = ReturnType<
  typeof fetchAcquirerAnticipationsSuccess
>
export type FetchAcquirerAnticipationsFailureAction = ReturnType<
  typeof fetchAcquirerAnticipationsFailure
>
