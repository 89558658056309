import type { Item, Account } from 'pluggy-sdk'

import { BaseAPI, PageResponse } from './BaseAPI'

export class AccountAPI extends BaseAPI {
  async fetchAccounts(itemId: Item['id']): Promise<Account[]> {
    const { results } = await this.request<PageResponse<Account>>(
      'get',
      `/accounts?itemId=${itemId}`
    )
    return results
  }
}
