import { Category, Transaction } from 'pluggy-sdk'

import { BaseAPI, PageResponse } from './BaseAPI'

export class CategoryAPI extends BaseAPI {
  async fetchAll(): Promise<Category[]> {
    const { results } = await this.request<PageResponse<Category>>(
      'get',
      `/categories`
    )
    return results
  }

  async updateTransactionCategory(
    transactionId: string,
    categoryId: string
  ): Promise<Transaction> {
    return this.request<Transaction>(
      'patch',
      `/transactions/${transactionId}`,
      null,
      { data: { categoryId } }
    )
  }
}
