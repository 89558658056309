import { connect } from 'react-redux'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Page.types'
import Page from './Page'
import { RootState } from '../../modules/reducer'
import { isLoggingIn, isLoggingOut } from '../../modules/login/selectors'

const mapState = (state: RootState): MapStateProps => ({
  isLoggingOut: isLoggingOut(state),
  isLoggingIn: isLoggingIn(state),
})

const mapDispatch = (_dispatch: MapDispatch): MapDispatchProps => ({
  // nothing here, yet
})

export default connect(mapState, mapDispatch)(Page)
