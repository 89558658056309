import React from 'react'

import { Grid, Segment } from 'semantic-ui-react'

import './ItemSkeleton.css'

const ItemSkeleton = () => (
  <Segment className={'ItemSkeleton'}>
    <Grid columns={2} className={'skeleton-grid'}>
      <Grid.Row>
        <Grid.Column className={'image-column'}>
          <div className={'skeleton item-image'} />
          <div className={'image-text-container'}>
            <div className={'skeleton item-text'} />
            <div className={'skeleton item-text'} />
          </div>
        </Grid.Column>
        <Grid.Column className={'tag-column'}>
          <div className={'skeleton item-tag'} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <div className={'divider'} />
    <Grid columns={1} className={'skeleton-grid'}>
      <Grid.Row>
        <Grid.Column>
          <div className={'skeleton text connection'} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
)

export default React.memo(ItemSkeleton)
