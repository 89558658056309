import React from 'react'

import Notifications from 'react-notification-system-redux'

import { ItemsContainer } from '../ItemsContainer'
import { Page } from '../Page'
import { Props } from './HomePage.types'

import './HomePage.css'

const HomePage = ({ isLoggedIn, notifications }: Props) => {
  const notificationStyles = {
    Containers: {
      DefaultStyle: {
        width: 600,
      },
      bc: {
        marginLeft: -(600 / 2),
      },
    },
  }

  return (
    <Page className={'HomePage'}>
      <div className={'content-container'}>
        {isLoggedIn && <ItemsContainer />}
      </div>
      <Notifications
        notifications={notifications.map((elem) => ({
          ...elem,
          message: <div dangerouslySetInnerHTML={{ __html: elem.message }} />,
        }))}
        style={notificationStyles}
      />
    </Page>
  )
}

export default React.memo(HomePage)
