import { action } from 'typesafe-actions'

// Create Connect token
export const CREATE_CONNECT_TOKEN_REQUEST = '[Request] Create Connect token'
export const CREATE_CONNECT_TOKEN_SUCCESS = '[Success] Create Connect token'
export const CREATE_CONNECT_TOKEN_FAILURE = '[Failure] Create Connect token'

export const createConnectTokenRequest = (itemId?: string) =>
  action(CREATE_CONNECT_TOKEN_REQUEST, { itemId })

export const createConnectTokenSuccess = (token: string, itemId?: string) =>
  action(CREATE_CONNECT_TOKEN_SUCCESS, { token, itemId })

export const createConnectTokenFailure = (error: Error, itemId?: string) =>
  action(CREATE_CONNECT_TOKEN_FAILURE, { error, itemId })

export type CreateConnectTokenRequestAction = ReturnType<
  typeof createConnectTokenRequest
>
export type CreateConnectTokenSuccessAction = ReturnType<
  typeof createConnectTokenSuccess
>
export type CreateConnectTokenFailureAction = ReturnType<
  typeof createConnectTokenFailure
>
