import React from 'react'

import Icon from '../Icon'

import './ArrowIcon.css'

type Props = {
  onClick?: () => void
  className?: string
}

const ArrowIcon = ({ onClick, className }: Props) => (
  <Icon
    className={`ArrowIcon ${className || ''}`}
    onClick={onClick}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.964 3.57298C16.9571 3.54572 16.9488 3.51885 16.939 3.49248C16.927 3.4663 16.9136 3.4408 16.8988 3.41609C16.8877 3.39579 16.8788 3.37469 16.8657 3.35523C16.8073 3.26781 16.7323 3.19274 16.6449 3.13431C16.6248 3.12085 16.603 3.11158 16.582 3.10016C16.5579 3.08579 16.5331 3.07273 16.5077 3.06103C16.4808 3.05108 16.4535 3.04259 16.4257 3.0356C16.4033 3.02904 16.3819 3.02034 16.3588 3.01575C16.3074 3.0054 16.255 3.00013 16.2026 3L3.79743 3C3.58594 3 3.38311 3.08401 3.23356 3.23356C3.08401 3.38311 3 3.58594 3 3.79743C3 4.00892 3.08401 4.21175 3.23356 4.3613C3.38311 4.51085 3.58594 4.59486 3.79743 4.59486L14.2775 4.59486L4.78429 14.0881C4.71003 14.1621 4.6511 14.25 4.61085 14.3468C4.57061 14.4436 4.54984 14.5474 4.54975 14.6522C4.54966 14.757 4.57024 14.8608 4.61031 14.9577C4.65038 15.0546 4.70916 15.1426 4.78329 15.2167C4.85741 15.2908 4.94543 15.3496 5.04229 15.3897C5.13916 15.4298 5.24298 15.4503 5.3478 15.4502C5.45263 15.4502 5.55641 15.4294 5.65321 15.3891C5.75 15.3489 5.83791 15.29 5.91191 15.2157L15.4051 5.72248L15.4051 16.2026C15.4051 16.4141 15.4892 16.6169 15.6387 16.7664C15.7882 16.916 15.9911 17 16.2026 17C16.4141 17 16.6169 16.916 16.7664 16.7664C16.916 16.6169 17 16.4141 17 16.2026L17 3.79741C16.9999 3.74497 16.9946 3.69267 16.9843 3.64125C16.9796 3.61764 16.9708 3.59581 16.964 3.57298Z"
      fill="#1A73E8"
    />
  </Icon>
)

export default React.memo(ArrowIcon)
