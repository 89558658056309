import { Auth0ContextInterface } from '@auth0/auth0-react/src/auth0-context'

type GetAccessTokenSilentlyMethod = Auth0ContextInterface['getAccessTokenSilently']

/**
 * Deferred instance that contains the promise which resolves to the
 * Auth0ContextInterface::getAccessTokenSilently() method.
 *
 * @private
 */
export const deferred = (() => {
  const props: {
    promise?: Promise<GetAccessTokenSilentlyMethod>
    resolve?: (value: GetAccessTokenSilentlyMethod) => void
  } = {}
  props.promise = new Promise((resolve) => (props.resolve = resolve))
  return props
})()

/**
 * Helper to expose a way to retrieve auth0 access token outside a React component,
 * while still using a bind to the getAccessTokenSilently() method from the useAuth0() hook.
 *
 * This is a sort of clean and "officially" suggested way of doing it.
 * Source: https://gist.github.com/adamjmcgrath/0ed6a04047aad16506ca24d85f1b2a5c
 */
export async function getAuth0AccessToken(
  options?: Parameters<GetAccessTokenSilentlyMethod>[0]
): Promise<ReturnType<GetAccessTokenSilentlyMethod> | undefined> {
  const {
    promise,
  }: {
    promise?: Promise<GetAccessTokenSilentlyMethod>
    resolve?: (value: GetAccessTokenSilentlyMethod) => void
  } = deferred

  const getToken: GetAccessTokenSilentlyMethod | undefined = await promise

  return getToken?.(options)
}
