import React from 'react'

import { Segment } from 'semantic-ui-react'
import { ProductSkeleton } from './ProductSkeleton'
import { IdentitySkeleton } from './IdentitySkeleton'
import { InvestmentSkeleton } from './InvestmentSkeleton'

import './ItemProductsContainerSkeleton.css'

const ItemProductsContainerSkeleton = () => (
  <Segment className={'ItemProductsContainerSkeleton'}>
    <IdentitySkeleton className={'skeleton-container'} />
    <ProductSkeleton className={'skeleton-container'} />
    <InvestmentSkeleton className={'skeleton-container'} />
    <ProductSkeleton className={'skeleton-container'} />
  </Segment>
)

export default React.memo(ItemProductsContainerSkeleton)
