import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'

import { getData as getCategoryData } from '../../modules/category/selectors'
import { updateTransactionCategoryRequest } from '../../modules/category/actions'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Accounts.types'

import Accounts from './Accounts'

const mapState = (state: RootState): MapStateProps => ({
  categories: getCategoryData(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onUpdateTransactionCategory: (transactionId: string, categoryId: string) =>
    dispatch(updateTransactionCategoryRequest(transactionId, categoryId)),
})

export default connect(mapState, mapDispatch)(Accounts)
