import React from 'react'

import './StepsToConnect.css'

const StepsToConnect = () => (
  <div className={'StepsToConnect'}>
    <p className={'header'}>Veja como a Pluggy funciona em 3 etapas</p>
    <div className={'steps'}>
      <div className={'step'}>
        <span className="step-number">1</span>Clique em conectar conta
      </div>
      <div className={'step'}>
        <span className="step-number">2</span>Escolha uma instituição e realize
        a conexão
      </div>
      <div className={'step'}>
        <span className="step-number">3</span>Pronto! A Pluggy começará a buscar
        dados
      </div>
    </div>
  </div>
)

export default React.memo(StepsToConnect)
