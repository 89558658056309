import { connect } from 'react-redux'

import type { Account, AcquirerAnticipation, AcquirerReceivable, AcquirerSale, Benefit, Investment, Loan, Opportunity } from 'pluggy-sdk'
import type { AccountWithTransactions } from '@pluggyai/ui'

import {
  FETCH_ACCOUNTS_REQUEST,
  fetchAccountsRequest,
} from '../../modules/account/actions'
import {
  FETCH_INVESTMENTS_REQUEST,
  fetchInvestmentsRequest,
} from '../../modules/investment/actions'
import {
  FETCH_IDENTITY_BY_ITEMID_REQUEST,
  fetchIdentityByItemIdRequest,
} from '../../modules/identity/actions'
import {
  FETCH_OPPORTUNITIES_REQUEST,
  fetchOpportunitiesRequest,
} from '../../modules/opportunity/actions'
import {
  getAccountsByItemId,
  getAccountsErrorByItemId,
  getAccountsLoadingByItemId,
  isLoadingType as isAccountLoadingType,
} from '../../modules/account/selectors'
import {
  getTransactionsByAccountId,
  getTransactionsLoadingByAccountId,
  isLoadingType as isTransactionLoadingType,
} from '../../modules/transaction/selectors'
import {
  getInvestmentsByItemId,
  getInvestmentsErrorByItemId,
  getInvestmentsLoadingByItemId,
  isLoadingType as isInvestmentLoadingType,
} from '../../modules/investment/selectors'
import {
  getIdentityByItemId,
  getIdentityErrorByItemId,
  getIdentityLoadingByItemId,
  isLoadingType as isIdentityLoadingType,
} from '../../modules/identity/selectors'
import {
  getOpportunitiesByItemId,
  getOpportunitiesErrorByItemId,
  getOpportunitiesLoadingByItemId,
  isLoadingType as isOpportunitiesLoadingType,
} from '../../modules/opportunity/selectors'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
  OwnProps,
} from './ItemProductsContainer.types'
import ItemProductsContainer from './ItemProductsContainer'
import { RootState } from '../../modules/reducer'
import { FETCH_TRANSACTIONS_REQUEST } from '../../modules/transaction/actions'
import {
  getInvestmentTransactionsByInvestmentId,
  getInvestmentTransactionsLoadingByInvestmentId,
} from '../../modules/investmentTransaction/selectors'
import { FETCH_INVESTMENT_TRANSACTIONS_REQUEST } from '../../modules/investmentTransaction/actions'
import {
  getLoansByItemId,
  getLoansErrorByItemId,
  getLoansLoadingByItemId,
  isLoadingType as isLoansLoadingType,
} from '../../modules/loan/selectors'
import { FETCH_LOANS_REQUEST, fetchLoansRequest } from '../../modules/loan/actions'
import { FETCH_ACQUIRER_SALES_REQUEST, fetchAcquirerSalesRequest } from '../../modules/acquirerSale/actions'
import { getAcquirerSalesByItemId, getAcquirerSalesErrorByItemId, getAcquirerSalesLoadingByItemId, isLoadingType as isAcquirerSalesLoadingType, } from '../../modules/acquirerSale/selectors'
import { FETCH_ACQUIRER_RECEIVABLES_REQUEST, fetchAcquirerReceivablesRequest } from '../../modules/acquirerReceivable/actions'
import { getAcquirerReceivablesByItemId, getAcquirerReceivablesErrorByItemId, getAcquirerReceivablesLoadingByItemId, isLoadingType as isAcquirerReceivablesLoadingType } from '../../modules/acquirerReceivable/selectors'
import { FETCH_ACQUIRER_ANTICIPATIONS_REQUEST, fetchAcquirerAnticipationsRequest } from '../../modules/acquirerAnticipation/actions'
import { getAcquirerAnticipationsByItemId, getAcquirerAnticipationsErrorByItemId, getAcquirerAnticipationsLoadingByItemId, isLoadingType as isAcquirerAnticipationsLoadingType } from '../../modules/acquirerAnticipation/selectors'
import { getBenefitsByItemId, getBenefitsErrorByItemId, getBenefitsLoadingByItemId, isLoadingType as isBenefitsLoadingType } from '../../modules/benefit/selectors'
import { FETCH_BENEFITS_REQUEST, fetchBenefitsRequest } from '../../modules/benefit/actions'

const mapState = (state: RootState, ownProps: OwnProps): MapStateProps => {
  const { item } = ownProps
  const { id: itemId } = item

  const itemAccountsByAccountId = getAccountsByItemId(state, itemId)
  const itemInvestmentsByInvestmentId = getInvestmentsByItemId(state, itemId)
  const itemOpportunitiesByItemId = getOpportunitiesByItemId(state, itemId)
  const itemIdentity = getIdentityByItemId(state, itemId)
  const itemLoansByItemId = getLoansByItemId(state, itemId)
  const itemBenefitsByItemId = getBenefitsByItemId(state, itemId)
  const itemAcquirerSalesByItemId = getAcquirerSalesByItemId(state, itemId)
  const itemAcquirerReceivablesByItemId = getAcquirerReceivablesByItemId(state, itemId)
  const itemAcquirerAnticipationsByItemId = getAcquirerAnticipationsByItemId(state, itemId)

  const itemAccounts: AccountWithTransactions[] = itemAccountsByAccountId
    ? Object.values(itemAccountsByAccountId)
        .filter(
          (account: Account | undefined): account is Account =>
            itemId === account?.itemId
        )
        .map((account): AccountWithTransactions => {
          const itemAccountsTransactionsByAccountId =
            getTransactionsByAccountId(state, account.id)

          return {
            ...account,
            transactions: itemAccountsTransactionsByAccountId || [],
          }
        })
    : []

  const itemInvestments: Investment[] = itemInvestmentsByInvestmentId
    ? Object.values(itemInvestmentsByInvestmentId)
        .filter(
          (investment: Investment | undefined): investment is Investment =>
            itemId === investment?.itemId
        )
        .map(
          (investment: Investment): Investment => ({
            ...investment,
            transactions:
              investment.transactions ||
              getInvestmentTransactionsByInvestmentId(state, investment.id) ||
              [],
          })
        )
    : []

  const itemOpportunities: Opportunity[] = itemOpportunitiesByItemId
    ? Object.values(itemOpportunitiesByItemId).filter(
        (opportunity: Opportunity | undefined): opportunity is Opportunity =>
          opportunity?.itemId === itemId
      )
    : []

  const itemLoans: Loan[] = itemLoansByItemId
    ? Object.values(itemLoansByItemId).filter(
        (loan: Loan | undefined): loan is Loan => loan?.itemId === itemId
      )
    : []

  const itemBenefits: Benefit[] = itemBenefitsByItemId
  ? Object.values(itemBenefitsByItemId).filter(
      (benefit: Benefit | undefined): benefit is Benefit => benefit?.itemId === itemId
    )
  : []


  const itemAcquirerSales: AcquirerSale[] = itemAcquirerSalesByItemId
  ? Object.values(itemAcquirerSalesByItemId).filter(
      (acquirerSale: AcquirerSale | undefined): acquirerSale is AcquirerSale => acquirerSale?.itemId === itemId
    )
  : []

  const itemAcquirerReceivables: AcquirerReceivable[] = itemAcquirerReceivablesByItemId
  ? Object.values(itemAcquirerReceivablesByItemId).filter(
      (acquirerReceivable: AcquirerReceivable | undefined): acquirerReceivable is AcquirerReceivable => acquirerReceivable?.itemId === itemId
    )
  : []

  const itemAcquirerAnticipations: AcquirerAnticipation[] = itemAcquirerAnticipationsByItemId
  ? Object.values(itemAcquirerAnticipationsByItemId).filter(
      (acquirerAnticipation: AcquirerAnticipation | undefined): acquirerAnticipation is AcquirerAnticipation => acquirerAnticipation?.itemId === itemId
    )
  : []

  const isItemAccountsTransactionsFirstLoading = itemAccounts.some((account) =>
    isTransactionLoadingType(
      getTransactionsLoadingByAccountId(state, account.id),
      FETCH_TRANSACTIONS_REQUEST
    )
  )

  const isItemInvestmentTransactionsFirstLoading = itemInvestments.some(
    (investment) =>
      isInvestmentLoadingType(
        getInvestmentTransactionsLoadingByInvestmentId(state, investment.id),
        FETCH_INVESTMENT_TRANSACTIONS_REQUEST
      )
  )

  const isAccountsLoading = isAccountLoadingType(
    getAccountsLoadingByItemId(state, itemId),
    FETCH_ACCOUNTS_REQUEST
  )
  const isIdentityLoading = isIdentityLoadingType(
    getIdentityLoadingByItemId(state, itemId),
    FETCH_IDENTITY_BY_ITEMID_REQUEST
  )
  const isInvestmentsLoading = isInvestmentLoadingType(
    getInvestmentsLoadingByItemId(state, itemId),
    FETCH_INVESTMENTS_REQUEST
  )
  const isOpportunitiesLoading = isOpportunitiesLoadingType(
    getOpportunitiesLoadingByItemId(state, itemId),
    FETCH_OPPORTUNITIES_REQUEST
  )

  const isLoansLoading = isLoansLoadingType(
    getLoansLoadingByItemId(state, itemId),
    FETCH_LOANS_REQUEST
  )

  const isBenefitsLoading = isBenefitsLoadingType(
    getBenefitsLoadingByItemId(state, itemId),
    FETCH_BENEFITS_REQUEST
  )

  const isAcquirerSalesLoading = isAcquirerSalesLoadingType(
    getAcquirerSalesLoadingByItemId(state, itemId),
    FETCH_ACQUIRER_SALES_REQUEST
  )

  const isAcquirerReceivablesLoading = isAcquirerReceivablesLoadingType(
    getAcquirerReceivablesLoadingByItemId(state, itemId),
    FETCH_ACQUIRER_RECEIVABLES_REQUEST
  )

  const isAcquirerAnticipationsLoading = isAcquirerAnticipationsLoadingType(
    getAcquirerAnticipationsLoadingByItemId(state, itemId),
    FETCH_ACQUIRER_ANTICIPATIONS_REQUEST
  )

  const isAccountsFirstTimeLoading =
    isAccountsLoading && !itemAccountsByAccountId

  const isIdentityFirstTimeLoading = isIdentityLoading && !itemIdentity

  const isInvestmentsFirstTimeLoading =
    isInvestmentsLoading && !itemInvestmentsByInvestmentId

  const isOpportunitiesFirstTimeLoading =
    isOpportunitiesLoading && !itemOpportunitiesByItemId

  const isLoansFirstTimeLoading = isLoansLoading && !itemLoansByItemId

  const isBenefitsFirstTimeLoading = isBenefitsLoading && !itemBenefitsByItemId

  const isAcquirerSalesFirstTimeLoading = isAcquirerSalesLoading && !itemAcquirerSalesByItemId

  const isAcquirerReceivablesFirstTimeLoading = isAcquirerReceivablesLoading && !itemAcquirerReceivablesByItemId

  const isAcquirerAnticipationsFirstTimeLoading = isAcquirerAnticipationsLoading && !itemAcquirerAnticipationsByItemId

  const isLoading =
    isAccountsFirstTimeLoading ||
    isIdentityFirstTimeLoading ||
    isInvestmentsFirstTimeLoading ||
    isOpportunitiesFirstTimeLoading ||
    isLoansFirstTimeLoading ||
    isBenefitsFirstTimeLoading ||
    isAcquirerSalesFirstTimeLoading ||
    isAcquirerReceivablesFirstTimeLoading ||
    isAcquirerAnticipationsFirstTimeLoading

  const itemAccountError = getAccountsErrorByItemId(state, itemId)
  const itemInvestmentError = getInvestmentsErrorByItemId(state, itemId)
  const itemIdentityError = getIdentityErrorByItemId(state, itemId)
  const itemOpportunitiesError = getOpportunitiesErrorByItemId(state, itemId)
  const itemLoansError = getLoansErrorByItemId(state, itemId)
  const itemBenefitsError = getBenefitsErrorByItemId(state, itemId)
  const itemAcquirerSalesError = getAcquirerSalesErrorByItemId(state, itemId)
  const itemAcquirerReceivablesError = getAcquirerReceivablesErrorByItemId(state, itemId)
  const itemAcquirerAnticipationsError = getAcquirerAnticipationsErrorByItemId(state, itemId)

  return {
    itemAccounts,
    itemInvestments,
    itemIdentity,
    itemOpportunities,
    itemLoans,
    itemBenefits,
    itemAcquirerSales,
    itemAcquirerReceivables,
    itemAcquirerAnticipations,
    isLoading,
    itemAccountError,
    itemInvestmentError,
    itemIdentityError,
    itemOpportunitiesError,
    itemLoansError,
    itemBenefitsError,
    itemAcquirerSalesError,
    itemAcquirerReceivablesError,
    itemAcquirerAnticipationsError,
    isTransactionsLoading: isItemAccountsTransactionsFirstLoading,
    isInvestmentTransactionsLoading: isItemInvestmentTransactionsFirstLoading,
    isAcquirerSalesLoading: isAcquirerSalesFirstTimeLoading,
    isAcquirerReceivablesLoading: isAcquirerReceivablesFirstTimeLoading,
    isAcquirerAnticipationsLoading: isAcquirerAnticipationsFirstTimeLoading,
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchAccounts: (itemId: string) => dispatch(fetchAccountsRequest(itemId)),
  onFetchInvestments: (itemId: string) =>
    dispatch(fetchInvestmentsRequest(itemId)),
  onFetchIdentityByItemId: (itemId: string) =>
    dispatch(fetchIdentityByItemIdRequest(itemId)),
  onFetchOpportunities: (itemId: string) =>
    dispatch(fetchOpportunitiesRequest(itemId)),
  onFetchLoans: (itemId: string) =>
    dispatch(fetchLoansRequest(itemId)),
  onFetchBenefits: (itemId: string) =>
    dispatch(fetchBenefitsRequest(itemId)),
  onFetchAcquirerSales: (itemId: string) => dispatch(fetchAcquirerSalesRequest(itemId)),
  onFetchAcquirerReceivables: (itemId: string) => dispatch(fetchAcquirerReceivablesRequest(itemId)),
  onFetchAcquirerAnticipations: (itemId: string) => dispatch(fetchAcquirerAnticipationsRequest(itemId)),
})

export default connect(mapState, mapDispatch)(ItemProductsContainer)
