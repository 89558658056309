import { call, put, select, takeEvery } from 'redux-saga/effects'
import { getEmail, getPluggyApiKey } from '../login/selectors'
import {
  CREATE_CONNECT_TOKEN_REQUEST,
  createConnectTokenFailure,
  CreateConnectTokenRequestAction,
  createConnectTokenSuccess,
} from './actions'
import { isConnectToken } from '../../utils/pluggy'
import { ConnectToken, ConnectTokenAPI } from '../../lib/api/connectToken'

function* handleCreateConnectTokenRequest(
  action: CreateConnectTokenRequestAction
) {
  const { itemId } = action.payload

  const email: string | undefined = yield select(getEmail)

  const apiKey: string = yield select(getPluggyApiKey)
  const pluggyApi = new ConnectTokenAPI(apiKey)

  try {
    const { accessToken }: ConnectToken = yield call(() =>
      pluggyApi.createConnectToken(itemId, {
        clientUserId: `demo:${email}`,
      })
    )
    yield put(createConnectTokenSuccess(accessToken, itemId))
  } catch (error) {
    if (isConnectToken(apiKey)) {
      // Is already a connect token sent directly from backend
      // Because the session is write-only (sensitive clients)
      yield put(createConnectTokenSuccess(apiKey, itemId))
    } else {
      yield put(createConnectTokenFailure(error, itemId))
    }
  }
}

export function* connectTokenSaga() {
  yield takeEvery(CREATE_CONNECT_TOKEN_REQUEST, handleCreateConnectTokenRequest)
}
