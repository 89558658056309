import type { Opportunity, Item } from 'pluggy-sdk'

import { BaseAPI, PageResponse } from './BaseAPI'

export class OpportunityAPI extends BaseAPI {
  async fetchOpportunities(itemId: Item['id']): Promise<Opportunity[]> {
    const { results } = await this.request<PageResponse<Opportunity>>(
      'get',
      `/opportunities?itemId=${itemId}`
    )
    return results
  }
}
