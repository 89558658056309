import { connect } from 'react-redux'

import {
  deleteItemsRequest,
  FETCH_ITEMS_REQUEST,
} from '../../modules/item/actions'
import {
  getError as getItemsError,
  getItemsById,
  getItemsTotal,
  getItemsTotalExceptSandbox,
  getLoading as getItemsLoading,
  isLoadingType as isItemsLoadingType,
} from '../../modules/item/selectors'
import ItemsContainer from './ItemsContainer'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ItemsContainer.types'
import { RootState } from '../../modules/reducer'
import {
  getIsItemsCreateLimitExceeded,
  getCreateItemsLimit,
} from '../../modules/application/selectors'
import {
  getItemCreateConnectToken,
  isCreateConnectTokenLoading,
} from '../../modules/connectToken/selectors'
import { createConnectTokenRequest } from '../../modules/connectToken/actions'

const mapState = (state: RootState): MapStateProps => ({
  itemsById: getItemsById(state),
  itemsTotal: getItemsTotal(state),
  itemsError: getItemsError(state),
  isLoading: isItemsLoadingType(getItemsLoading(state), FETCH_ITEMS_REQUEST),
  isLoadingConnectToken: isCreateConnectTokenLoading(state),
  connectToken: getItemCreateConnectToken(state),
  itemsTotalExceptSandbox: getItemsTotalExceptSandbox(state),
  applicationCreateItemsLimit: getCreateItemsLimit(state),
  isItemsCreateLimitExceeded: getIsItemsCreateLimitExceeded(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onCreateConnectToken: () => dispatch(createConnectTokenRequest()),
  onDeleteItems: (items) => dispatch(deleteItemsRequest(items)),
})

export default connect(mapState, mapDispatch)(ItemsContainer)
