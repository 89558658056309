import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import type { Account } from 'pluggy-sdk'

import { AccountAPI } from '../../lib/api/account'
import { getPluggyApiKey } from '../login/selectors'
import { fetchTransactionsRequest } from '../transaction/actions'
import {
  FETCH_ACCOUNTS_REQUEST,
  fetchAccountsFailure,
  FetchAccountsRequestAction,
  fetchAccountsSuccess,
} from './actions'

function* handleFetchAccountsRequest(action: FetchAccountsRequestAction) {
  const { itemId } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const accountAPI = new AccountAPI(apiKey)
  try {
    const accounts: Account[] = yield call(() =>
      accountAPI.fetchAccounts(itemId)
    )

    yield all(
      accounts.map((account) => put(fetchTransactionsRequest(account.id)))
    )

    yield put(fetchAccountsSuccess(accounts, itemId))
  } catch (error) {
    yield put(fetchAccountsFailure(error.message, itemId))
  }
}

export function* accountSaga() {
  yield takeEvery(FETCH_ACCOUNTS_REQUEST, handleFetchAccountsRequest)
}
