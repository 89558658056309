import { takeEvery, put, call, select, all } from 'redux-saga/effects'

import type { Investment } from 'pluggy-sdk'

import { InvestmentAPI } from '../../lib/api/investment'
import { getPluggyApiKey } from '../login/selectors'
import {
  FetchInvestmentsRequestAction,
  fetchInvestmentsSuccess,
  fetchInvestmentsFailure,
  FETCH_INVESTMENTS_REQUEST,
} from './actions'
import { fetchInvestmentTransactionsRequest } from '../investmentTransaction/actions'

function* handleFetchInvestmentsRequest(action: FetchInvestmentsRequestAction) {
  const { itemId } = action.payload
  const apiKey: string = yield select(getPluggyApiKey)
  const investmentAPI = new InvestmentAPI(apiKey)
  try {
    const investments: Investment[] = yield call(() =>
      investmentAPI.fetchAll(itemId)
    )

    const investmentsHaveTransactions = investments.some(
      (investment) => investment.transactions !== undefined
    )

    if (!investmentsHaveTransactions) {
      // new applications doesn't retreive transactions -> fetch them in other saga
      yield all(
        investments.map((investment) =>
          put(fetchInvestmentTransactionsRequest(investment.id))
        )
      )
    }

    yield put(fetchInvestmentsSuccess(investments, itemId))
  } catch (error) {
    yield put(fetchInvestmentsFailure(error.message, itemId))
  }
}

export function* investmentSaga() {
  yield takeEvery(FETCH_INVESTMENTS_REQUEST, handleFetchInvestmentsRequest)
}
