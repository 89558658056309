import type { Category, Transaction } from 'pluggy-sdk'

import { action } from 'typesafe-actions'

/// Fetch Categories

export const FETCH_CATEGORIES_REQUEST = '[Request] Fetch categories'
export const FETCH_CATEGORIES_SUCCESS = '[Success] Fetch categories'
export const FETCH_CATEGORIES_FAILURE = '[Failure] Fetch categories'

export const fetchCategoriesRequest = () => action(FETCH_CATEGORIES_REQUEST)

export const fetchCategoriesSuccess = (categories: Category[]) =>
  action(FETCH_CATEGORIES_SUCCESS, { categories })

export const fetchCategoriesFailure = (error: string) =>
  action(FETCH_CATEGORIES_FAILURE, { error })

export type FetchCategoriesRequestAction = ReturnType<
  typeof fetchCategoriesRequest
>
export type FetchCategoriesSuccessAction = ReturnType<
  typeof fetchCategoriesSuccess
>
export type FetchCategoriesFailureAction = ReturnType<
  typeof fetchCategoriesFailure
>

/// Update category

export const UPDATE_TRANSACTION_CATEGORY_REQUEST = '[Request] Update category'
export const UPDATE_TRANSACTION_CATEGORY_SUCCESS = '[Success] Update category'
export const UPDATE_TRANSACTION_CATEGORY_FAILURE = '[Failure] Update category'

export const updateTransactionCategoryRequest = (
  transactionId: string,
  categoryId: string
) => action(UPDATE_TRANSACTION_CATEGORY_REQUEST, { transactionId, categoryId })

export const updateTransactionCategorySuccess = (
  transactionCategorized: Transaction
) => action(UPDATE_TRANSACTION_CATEGORY_SUCCESS, { transactionCategorized })

export const updateTransactionCategoryFailure = (error: string) =>
  action(UPDATE_TRANSACTION_CATEGORY_FAILURE, { error })

export type UpdateTransactionCategoryRequestAction = ReturnType<
  typeof updateTransactionCategoryRequest
>
export type UpdateTransactionCategorySuccessAction = ReturnType<
  typeof updateTransactionCategorySuccess
>
export type UpdateTransactionCategoryFailureAction = ReturnType<
  typeof updateTransactionCategoryFailure
>
