import { memo } from 'react'
import { Link } from 'react-router-dom'

import { Props } from './NavbarItem.types'

import './NavbarItem.css'

const NavbarItem = ({ children, className, href }: Props) => {
  const active = window.location.pathname === href

  return (
    <Link
      className={`NavbarItem ${className || ''} ${active ? 'active' : ''}`}
      to={href}
    >
      {children}
    </Link>
  )
}

export default memo(NavbarItem)
