import { connect } from 'react-redux'
import { fetchAccountsRequest } from '../../modules/account/actions'
import { fetchInvestmentsRequest } from '../../modules/investment/actions'
import { fetchIdentityByItemIdRequest } from '../../modules/identity/actions'
import { createConnectTokenRequest } from '../../modules/connectToken/actions'
import {
  getItemUpdateConnectToken,
  isUpdateConnectTokenLoading,
} from '../../modules/connectToken/selectors'
import {
  deleteItemRequest,
  fetchItemRequest,
  updateItemRequest,
} from '../../modules/item/actions'
import { RootState } from '../../modules/reducer'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
  OwnProps,
} from './Item.types'
import Item from './Item'
import { addNotificationAction } from '../../modules/notification/actions'
import { NotificationOptions } from '../../modules/notification/types'

const mapState = (state: RootState, ownProps: OwnProps): MapStateProps => {
  const { item } = ownProps

  const connectToken = getItemUpdateConnectToken(state, item.id)
  const isConnectTokenLoading = isUpdateConnectTokenLoading(state, item.id)

  return {
    isConnectTokenLoading,
    connectToken,
  }
}

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onDelete: (item) => dispatch(deleteItemRequest(item)),
  onFetchItem: (itemId: string) => dispatch(fetchItemRequest(itemId)),
  onUpdate: (itemId, parameters, webhookUrl) =>
    dispatch(updateItemRequest(itemId, parameters, webhookUrl)),
  onFetchAccounts: (itemId: string) => dispatch(fetchAccountsRequest(itemId)),
  onFetchInvestments: (itemId: string) =>
    dispatch(fetchInvestmentsRequest(itemId)),
  onFetchIdentityByItemId: (itemId: string) =>
    dispatch(fetchIdentityByItemIdRequest(itemId)),
  onRefreshConnectToken: (itemId?: string) =>
    dispatch(createConnectTokenRequest(itemId)),
  addNotification: (options: NotificationOptions) =>
    dispatch(addNotificationAction(options)),
})

export default connect(mapState, mapDispatch)(Item)
