import type { Investment, InvestmentTransaction } from 'pluggy-sdk'
import { action } from 'typesafe-actions'

// Fetch Investment Transactions
export const FETCH_INVESTMENT_TRANSACTIONS_REQUEST =
  '[Request] Fetch Investment transactions'
export const FETCH_INVESTMENT_TRANSACTIONS_SUCCESS =
  '[Success] Fetch Investment transactions'
export const FETCH_INVESTMENT_TRANSACTIONS_FAILURE =
  '[Failure] Fetch Investment transactions'

export const fetchInvestmentTransactionsRequest = (
  investmentId: Investment['id']
) => action(FETCH_INVESTMENT_TRANSACTIONS_REQUEST, { investmentId })

export const fetchInvestmentTransactionsSuccess = (
  transactions: InvestmentTransaction[],
  investmentId: Investment['id']
) =>
  action(FETCH_INVESTMENT_TRANSACTIONS_SUCCESS, { transactions, investmentId })

export const fetchInvestmentTransactionsFailure = (
  error: string,
  investmentId: Investment['id']
) => action(FETCH_INVESTMENT_TRANSACTIONS_FAILURE, { error, investmentId })

export type FetchInvestmentTransactionsRequestAction = ReturnType<
  typeof fetchInvestmentTransactionsRequest
>
export type FetchInvestmentTransactionsSuccessAction = ReturnType<
  typeof fetchInvestmentTransactionsSuccess
>
export type FetchInvestmentTransactionsFailureAction = ReturnType<
  typeof fetchInvestmentTransactionsFailure
>
