import { Grid } from 'semantic-ui-react'

import './IdentitySkeleton.css'

const IdentitySkeleton = ({ className }: { className?: string }) => (
  <div className={`IdentitySkeleton ${className || ''}`}>
    <div className={'skeleton title'} />
    <Grid className={'skeleton-grid'} columns={3}>
      <Grid.Row>
        <Grid.Column>
          <div className={'skeleton text title'} />
          <div className={'skeleton text'} />
        </Grid.Column>
        <Grid.Column>
          <div className={'skeleton text title'} />
          <div className={'skeleton text'} />
        </Grid.Column>
        <Grid.Column>{/* intentional empty */}</Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
)

export default IdentitySkeleton
