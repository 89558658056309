import React from 'react'

import { Tag } from '@pluggyai/ui'

import {
  JsonViewProductTypes,
  Props,
} from './ItemProductsContainerJsonView.types'
import { stringifyProduct } from './utils'

import './ItemProductsContainerJsonView.css'

const ItemProductsContainerJsonView = ({
  item,
  identity,
  accounts,
  investments,
  opportunities,
  loans,
  benefits,
  acquirerSales,
}: Props) => {
  const [selectedProduct, setSelectedProduct] =
    React.useState<JsonViewProductTypes>('item')

  const products = {
    item,
    identity,
    accounts,
    investments,
    opportunities,
    loans,
    benefits,
    acquirerSales,
  }

  return (
    <div className={'ItemProductsContainerJsonView'}>
      <div className="product-selector">
        {item && (
          <Tag
            key={'item'}
            text={'Dados do Item'}
            onClick={() => setSelectedProduct('item')}
            isSelected={selectedProduct === 'item'}
            selectable
          />
        )}
        {identity && (
          <Tag
            key={'identity'}
            text={'Identidade'}
            onClick={() => setSelectedProduct('identity')}
            isSelected={selectedProduct === 'identity'}
            selectable
          />
        )}
        {accounts.length > 0 && (
          <Tag
            key={'accounts'}
            text={'Detalhes das contas'}
            onClick={() => setSelectedProduct('accounts')}
            isSelected={selectedProduct === 'accounts'}
            selectable
          />
        )}
        {investments.length > 0 && (
          <Tag
            key={'investments'}
            text={'Investimentos'}
            onClick={() => setSelectedProduct('investments')}
            isSelected={selectedProduct === 'investments'}
            selectable
          />
        )}
        {opportunities.length > 0 && (
          <Tag
            key={'opportunities'}
            text={'Oportunidades'}
            onClick={() => setSelectedProduct('opportunities')}
            isSelected={selectedProduct === 'opportunities'}
            selectable
          />
        )}
        {loans.length > 0 && (
          <Tag
            key={'loans'}
            text={'Empréstimos'}
            onClick={() => setSelectedProduct('loans')}
            isSelected={selectedProduct === 'loans'}
            selectable
          />
        )}
        {benefits.length > 0 && (
          <Tag
            key={'benefits'}
            text={'Benefícios'}
            onClick={() => setSelectedProduct('benefits')}
            isSelected={selectedProduct === 'benefits'}
            selectable
          />
        )}
      </div>
      <div className={'json-view'}>
        <pre>{stringifyProduct(products[selectedProduct])}</pre>
      </div>
    </div>
  )
}

export default React.memo(ItemProductsContainerJsonView)
