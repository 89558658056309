import { LoadingState, loadingReducer } from '../loading/reducer'
import {
  FetchApplicationRequestAction,
  FetchApplicationSuccessAction,
  FetchApplicationFailureAction,
  FETCH_APPLICATION_REQUEST,
  FETCH_APPLICATION_SUCCESS,
  FETCH_APPLICATION_FAILURE,
} from './actions'
import { Application } from './types'

export type ApplicationState = {
  data: Application | null
  loading: LoadingState
  error: string | null
}

const INITIAL_STATE: ApplicationState = {
  data: null,
  loading: [],
  error: null,
}

type ApplicationReducer =
  | FetchApplicationRequestAction
  | FetchApplicationSuccessAction
  | FetchApplicationFailureAction

export function applicationReducer(
  state = INITIAL_STATE,
  action: ApplicationReducer
): ApplicationState {
  switch (action.type) {
    case FETCH_APPLICATION_REQUEST: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
      }
    }
    case FETCH_APPLICATION_SUCCESS: {
      const { application } = action.payload
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: null,
        data: application,
      }
    }

    case FETCH_APPLICATION_FAILURE: {
      return {
        ...state,
        loading: loadingReducer(state.loading, action),
        error: action.payload.error,
      }
    }
    default:
      return state
  }
}
