import { action } from 'typesafe-actions'

import type { Loan } from 'pluggy-sdk'

/// Fetch loans

export const FETCH_LOANS_REQUEST = '[Request] Fetch loans'
export const FETCH_LOANS_SUCCESS = '[Success] Fetch loans'
export const FETCH_LOANS_FAILURE = '[Failure] Fetch loans'

export const fetchLoansRequest = (itemId: string) =>
  action(FETCH_LOANS_REQUEST, { itemId })

export const fetchLoansSuccess = (
  loans: Loan[],
  itemId: string
) => action(FETCH_LOANS_SUCCESS, { loans, itemId })

export const fetchLoansFailure = (error: string, itemId: string) =>
  action(FETCH_LOANS_FAILURE, { error, itemId })

export type FetchLoansRequestAction = ReturnType<
  typeof fetchLoansRequest
>
export type FetchLoansSuccessAction = ReturnType<
  typeof fetchLoansSuccess
>
export type FetchLoansFailureAction = ReturnType<
  typeof fetchLoansFailure
>
