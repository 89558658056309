import {
  clearStoredPluggyApiKey,
  retrieveStoredApplicationId,
  storeApplicationId,
} from './storage'
import { setTag } from '@sentry/react'
import jwtDecode, { JwtPayload } from 'jwt-decode'

/**
 * Simple utility to retrieve a JWT token expiration date in seconds.
 * @param token
 */
function getJwtTokenExpirationInSeconds(token: string): number {
  const tokenOptions = jwtDecode<JwtPayload>(token)
  if (tokenOptions?.exp) {
    return tokenOptions.exp
  }
  throw new Error(`Parsed JWT token is invalid: missing 'exp' field`)
}

// offset to use as padding time, to allow a margin
// to renew it before it actually expires
const JWT_EXPIRATION_OFFSET_IN_SECONDS = 30

/**
 * @param token - the JWT token string
 */
export function isJwtExpired(token: string): boolean {
  const expirationInMs =
    (getJwtTokenExpirationInSeconds(token) - JWT_EXPIRATION_OFFSET_IN_SECONDS) *
    1000

  return Date.now() >= expirationInMs
}

export function isPluggyUserEmail(email: string): boolean {
  return email.endsWith('@pluggy.ai')
}

/**
 * Helper function to remove a query param from the current location URL
 * without altering browser history.
 *
 * @param param
 */
function removeUrlQueryParam(param: string): void {
  const queryParams = new URLSearchParams(window.location.search)

  // delete query param
  queryParams.delete(param)

  const search = queryParams.toString()
  const searchString = search ? `?${search}` : ''

  // remove query param from URL
  window.history.replaceState(
    null,
    '',
    `${window.location.pathname}${searchString}`
  )
}

/**
 * Helper to retrieve a query param value from the current location URL.
 *
 * @param param
 */
function getUrlQueryParam(param: string): string | undefined {
  // retrieve query params
  const queryParams = new URLSearchParams(window.location.search)

  const value = queryParams.get(param)

  if (value === null || value.length === 0) {
    // not specified value, just return null
    return undefined
  }

  return value
}

/**
 * Helper to retrieve and cleanup (remove) a query param from the current location URL.
 *
 * @param param
 */
function consumeUrlQueryParam(param: string): string | undefined {
  // retrieve param from URL
  const value = getUrlQueryParam(param)
  // remove it
  removeUrlQueryParam(param)
  // return the value
  return value
}

export function getApplicationIdQueryParam(): string | undefined {
  return getUrlQueryParam('application_id')
}

function consumeApplicationIdQueryParam(): string | undefined {
  return consumeUrlQueryParam('application_id')
}

/**
 * Check if preview application query params have been specified, and store them.
 * It could be just 'application_id', or also 'e' (email login preset).
 */
export function consumePreviewApplicationIdParamsFromUrl(): void {
  // check application_id query param
  const applicationIdQueryParam = consumeApplicationIdQueryParam()
  const storedApplicationId = retrieveStoredApplicationId()

  if (
    !applicationIdQueryParam ||
    storedApplicationId === applicationIdQueryParam
  ) {
    // no applicationId query param, or it's the same as the one it was before
    // just return, leave storage as it was
    return
  }

  // new applicationId specified, store it
  storeApplicationId(applicationIdQueryParam)

  setTag('application.id', applicationIdQueryParam)

  // clear the stored pluggyApiKey, as it's a new application id
  clearStoredPluggyApiKey()
}
