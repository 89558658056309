import type { IdentityResponse as Identity } from 'pluggy-sdk'

import { loadingReducer, LoadingState } from '../loading/reducer'
import {
  FETCH_IDENTITY_BY_ITEMID_FAILURE,
  FETCH_IDENTITY_BY_ITEMID_REQUEST,
  FETCH_IDENTITY_BY_ITEMID_SUCCESS,
  FetchIdentityByItemIdFailureAction,
  FetchIdentityByItemIdRequestAction,
  FetchIdentityByItemIdSuccessAction,
} from './actions'
import { Item } from 'pluggy-sdk'

export type IdentityState = {
  data: Record<Item['id'], Identity | undefined>
  loading: Record<Item['id'], LoadingState | undefined>
  error: Record<Item['id'], string | null | undefined>
}

const INITIAL_STATE: IdentityState = {
  data: {},
  loading: {},
  error: {},
}

type IdentityReducer =
  | FetchIdentityByItemIdRequestAction
  | FetchIdentityByItemIdSuccessAction
  | FetchIdentityByItemIdFailureAction

export function identityReducer(
  state = INITIAL_STATE,
  action: IdentityReducer
) {
  switch (action.type) {
    case FETCH_IDENTITY_BY_ITEMID_REQUEST: {
      const {
        payload: { itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
      }
    }
    case FETCH_IDENTITY_BY_ITEMID_SUCCESS: {
      const {
        payload: { identity, itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: null,
        },
        data: {
          ...state.data,
          [itemId]: identity,
        },
      }
    }
    case FETCH_IDENTITY_BY_ITEMID_FAILURE: {
      const {
        payload: { error, itemId },
      } = action
      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: error,
        },
      }
    }
    default:
      return state
  }
}
