import { connect } from 'react-redux'

import { RootState } from '../../modules/reducer'
import { MapDispatch, MapDispatchProps, MapStateProps } from './Navbar.types'
import { isLoggingIn, isLoggingOut } from '../../modules/login/selectors'
import { logoutRequest } from '../../modules/login/actions'
import Navbar from './Navbar'

const mapState = (state: RootState): MapStateProps => ({
  isLoggingOut: isLoggingOut(state),
  isLoggingIn: isLoggingIn(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onLogout: () => dispatch(logoutRequest()),
})

export default connect(mapState, mapDispatch)(Navbar)
