import { WebAuth as Auth0WebAuth } from 'auth0-js'

import { locations } from '../modules/routing/locations'
import jwtDecode, { JwtPayload } from 'jwt-decode'

const {
  REACT_APP_AUTH0_DOMAIN = '',
  REACT_APP_AUTH0_CLIENTID = '',
  REACT_APP_AUTH0_API_AUDIENCE = '',
} = process.env

/**
 * Initiates Auth0 logout flow, redirecting to the auth0 logout endpoint.
 * Afterwards, a redirect to 'returnTo' is issued
 */
export function auth0LogOut(): void {
  const rootUri = new URL(locations.root(), document.baseURI).href

  const auth0Client = new Auth0WebAuth({
    domain: REACT_APP_AUTH0_DOMAIN,
    clientID: REACT_APP_AUTH0_CLIENTID,
    audience: REACT_APP_AUTH0_API_AUDIENCE,
    responseType: 'id_token token',
    scope: 'openid profile email',
  })

  auth0Client.logout({
    returnTo: rootUri,
    clientID: REACT_APP_AUTH0_CLIENTID,
  })
}

export const AUTH0_JWT_EMAIL_CLAIM = 'https://api.pluggy.ai/email'

type Auth0JwtPayload = JwtPayload & {
  [AUTH0_JWT_EMAIL_CLAIM]?: string
}

function auth0JwtDecode(token: string): Auth0JwtPayload {
  let jwtDecoded: Auth0JwtPayload | null | string
  try {
    jwtDecoded = jwtDecode(token)
  } catch (error) {
    error.message = `Could not decode token, invalid or malformed? Message: ${error.message}`
    throw error
  }
  if (!jwtDecoded) {
    throw new Error('Could not decode token, result is null')
  }
  if (typeof jwtDecoded === 'string') {
    throw new TypeError(
      `Invalid or malformed token decoded, resulted in string: ${jwtDecoded}`
    )
  }
  return jwtDecoded
}

export function getAuth0TokenJwtPayload(
  authApiAccessToken: string
): JwtPayload & { email: string } {
  if (!authApiAccessToken) {
    throw new Error(`Invalid Authorization header, it's empty`)
  }

  try {
    const jwtDecoded = auth0JwtDecode(authApiAccessToken)

    // get email claim from JWT
    const { [AUTH0_JWT_EMAIL_CLAIM]: email } = jwtDecoded

    if (!email) {
      throw new Error('missing email claim in decoded JWT token')
    }

    return {
      ...jwtDecoded,
      email,
    }
  } catch (error) {
    error.message = `Invalid access token: ${error.message}`
    throw error
  }
}
