import { action } from 'typesafe-actions'

import type { Opportunity } from 'pluggy-sdk'

/// Fetch opportunities

export const FETCH_OPPORTUNITIES_REQUEST = '[Request] Fetch opportunities'
export const FETCH_OPPORTUNITIES_SUCCESS = '[Success] Fetch opportunities'
export const FETCH_OPPORTUNITIES_FAILURE = '[Failure] Fetch opportunities'

export const fetchOpportunitiesRequest = (itemId: string) =>
  action(FETCH_OPPORTUNITIES_REQUEST, { itemId })

export const fetchOpportunitiesSuccess = (
  opportunities: Opportunity[],
  itemId: string
) => action(FETCH_OPPORTUNITIES_SUCCESS, { opportunities, itemId })

export const fetchOpportunitiesFailure = (error: string, itemId: string) =>
  action(FETCH_OPPORTUNITIES_FAILURE, { error, itemId })

export type FetchOpportunitiesRequestAction = ReturnType<
  typeof fetchOpportunitiesRequest
>
export type FetchOpportunitiesSuccessAction = ReturnType<
  typeof fetchOpportunitiesSuccess
>
export type FetchOpportunitiesFailureAction = ReturnType<
  typeof fetchOpportunitiesFailure
>
