import React from 'react'

import { Grid } from 'semantic-ui-react'

import './InvestmentSkeleton.css'

const InvestmentSkeleton = ({ className }: { className?: string }) => (
  <div className={`InvestmentSkeleton ${className}`}>
    <div className={'skeleton title'} />
    <Grid columns={3} className={'skeleton-grid'}>
      <Grid.Row>
        <Grid.Column className={'investment-info-container'}>
          <div className={'skeleton image'} />
          <div className={'text-container'}>
            <div className={'skeleton text title'} />
            <div className={'skeleton text'} />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className={'skeleton text title'} />
          <div className={'skeleton text'} />
        </Grid.Column>
        <Grid.Column>{/* intentional empty */}</Grid.Column>
      </Grid.Row>
      <div className={'divider'} />
      <Grid.Row>
        <Grid.Column className={'investment-info-container'}>
          <div className={'skeleton image'} />
          <div className={'text-container'}>
            <div className={'skeleton text title'} />
            <div className={'skeleton text'} />
          </div>
        </Grid.Column>
        <Grid.Column>
          <div className={'skeleton text title'} />
          <div className={'skeleton text'} />
        </Grid.Column>
        <Grid.Column>{/* intentional empty */}</Grid.Column>
      </Grid.Row>
    </Grid>
  </div>
)

export default React.memo(InvestmentSkeleton)
