import type { Account, Item } from 'pluggy-sdk'

import { loadingReducer, LoadingState } from '../loading/reducer'
import {
  FETCH_ACCOUNTS_FAILURE,
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_SUCCESS,
  FetchAccountsFailureAction,
  FetchAccountsRequestAction,
  FetchAccountsSuccessAction,
} from './actions'

export type AccountState = {
  data: Record<
    Item['id'],
    Record<Account['id'], Account | undefined> | undefined
  >
  loading: Record<Item['id'], LoadingState | undefined>
  error: Record<Item['id'], string | null | undefined>
}

const INITIAL_STATE: AccountState = {
  data: {},
  loading: {},
  error: {},
}

type AccountReducer =
  | FetchAccountsRequestAction
  | FetchAccountsSuccessAction
  | FetchAccountsFailureAction

export function accountReducer(state = INITIAL_STATE, action: AccountReducer) {
  switch (action.type) {
    case FETCH_ACCOUNTS_REQUEST: {
      const {
        payload: { itemId },
      } = action

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
      }
    }
    case FETCH_ACCOUNTS_SUCCESS: {
      const {
        payload: { accounts, itemId },
      } = action

      const accountsById: Record<Account['id'], Account> = {}

      for (const account of accounts) {
        accountsById[account.id] = account
      }

      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: null,
        },
        data: {
          ...state.data,
          [itemId]: accountsById,
        },
      }
    }
    case FETCH_ACCOUNTS_FAILURE: {
      const {
        payload: { itemId, error },
      } = action
      return {
        ...state,
        loading: {
          ...state.loading,
          [itemId]: loadingReducer(state.loading[itemId], action),
        },
        error: {
          ...state.error,
          [itemId]: error,
        },
      }
    }
    default:
      return state
  }
}
