import { connect } from 'react-redux'
import { RootState } from '../../modules/reducer'
import { fetchItemsRequest } from '../../modules/item/actions'

import ItemConnecting from './ItemConnecting'
import {
  MapDispatch,
  MapDispatchProps,
  MapStateProps,
} from './ItemConnecting.types'
import { getIsItemsCreateLimitExceeded } from '../../modules/application/selectors'

const mapState = (state: RootState): MapStateProps => ({
  isItemsCreateLimitExceeded: getIsItemsCreateLimitExceeded(state),
})

const mapDispatch = (dispatch: MapDispatch): MapDispatchProps => ({
  onFetchItems: () => dispatch(fetchItemsRequest()),
})

export default connect(mapState, mapDispatch)(ItemConnecting)
