/**
 * Helper to extract the customUrlDomain from a url,
 *
 * @param url string - the url to extract the customUrlDomain from
 * @returns string | null - the customUrlDomain or null if not found
 */
export function extractCustomHostDomain(url: string): string | null {
  const urlObject = new URL(url)

  const { hostname, search } = urlObject

  const isVercelUrl = hostname.includes('.vercel.app')
  const isDeploymentAliasUrl = hostname.includes('demo.pluggy.')
  const isLocalhost = hostname.includes('localhost')

  const domainParts = hostname.replace(/^www\./, '').split('.')

  if (isDeploymentAliasUrl) {
    // ie. 'demo.pluggy.ai' or 'myorgname.demo.pluggy.ai'
    if (domainParts.length === 3) {
      // no custom domain part
      return null
    }

    return domainParts[0]
  }

  const customUrlDomainQueryParam = new URLSearchParams(search).get(
    'customUrlDomain'
  )

  if (isVercelUrl || isDeploymentAliasUrl) {
    // ie. 'demo-lv1v2bj7w-pluggy.vercel.app' or 'myorgname.demo-lv1v2bj7w-pluggy.vercel.app'
    // or 'demo-lv1v2bj7w-pluggy.vercel.app/?customUrlDomain=myorgname' (custom URL domains testing, for testing with Auth0/Vercel - Vercel doesn't allow this kind of dynamic subdomains in preview branches)
    if (customUrlDomainQueryParam) {
      return customUrlDomainQueryParam
    }

    if (domainParts.length === 3) {
      // no custom domain part
      return null
    }
  }

  if (isLocalhost) {
    // ie. 'localhost:3000'
    // or 'myorgname.localhost:3000' (custom url domains testing)
    // or 'localhost:3000/?customUrlDomain=myorgname' (custom URL domains testing, with Auth0 support - which doesn't allow dynamic subdomains in localhost)
    if (customUrlDomainQueryParam) {
      return customUrlDomainQueryParam
    }

    if (domainParts.length === 1) {
      // no custom domain part
      return null
    }
  }

  return domainParts[0]
}
