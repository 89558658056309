import { AnyAction } from 'redux'

import type { Transaction } from 'pluggy-sdk'

import { LoadingState } from '../loading/types'
import { RootState } from '../reducer'
import { TransactionState } from './reducer'

export const getState = (state: RootState): TransactionState =>
  state.transaction
export const getData = (state: RootState) => getState(state).data
export const getLoading = (state: RootState) => getState(state).loading
export const getError = (state: RootState) => getState(state).error

export const isLoadingType: (state: LoadingState, type: string) => boolean = (
  state,
  type
) => state.some((action: AnyAction) => action.type === type)

export const getTransactionsByAccountId = (
  state: RootState,
  accountId: string
): Transaction[] | null => getData(state)[accountId] || null

export const getTransactionsLoadingByAccountId = (
  state: RootState,
  accountId: string
): LoadingState => getLoading(state)[accountId] || []

export const getTransactionsErrorByAccountId = (
  state: RootState,
  accountId: string
): string | null => getError(state)[accountId] || null
